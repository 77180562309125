import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Header } from "semantic-ui-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Formik } from "formik";
import { fetchEvent, updateEvent } from "../../../features/events/eventsSlice";
import {
  fetchTypes,
  updateEventType,
} from "../../../features/eventTypes/eventTypesSlice";

const EditSchoolingForm = ({ event, type, handleClose }) => {
  const [combinedEvents, setCombinedEvents] = useState([
    ...event.combinedEvents,
  ]);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);

  const typeId = type.id;
  const eventId = event.id;
  const typeName = type.name;
  return (
    <section>
      <br />
      <Formik
        initialValues={{
          id: type.id,
          entryFee: type.entryFee,
          entryFeeNonCompetitive: type.entryFeeNonCompetitive,
          eventDate: "",
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);

          let abbrevDate = moment
            .utc(values.eventDate)
            .toString()
            .substring(0, 3);
          console.log(abbrevDate);
          dispatch(updateEventType({ user, typeId, values }))
            .then(
              () => (values.id = event.id),
              (values.name = event.name),
              (values.facilityName = event.facilityName),
              (values.description = event.description),
              (values.combinedEvents = [...combinedEvents])
              // (values.combinedEvents = [
              //   ...combinedEvents,
              //   {
              //     name: typeName + " " + "(" + abbrevDate + ")",
              //     name: typeName,
              //     date: values.eventDate,
              //   },
              // ]),

              // combinedEvents.some((el) => el.id && el.id === type.id) === true
              //   ? (values.combinedEvents = [
              //       ...combinedEvents.filter(
              //         (el) => el.id && el.id !== type.id
              //       ),
              //       {
              //         id: typeId,
              //         name: typeName,
              //         date: values.eventDate,
              //       },
              //     ])
              //   : (values.combinedEvents = [
              //       ...combinedEvents,
              //       {
              //         id: typeId,
              //         name: typeName,
              //         date: values.eventDate,
              //       },
              //     ])
              // (values.combinedEvents = [
              //   ...combinedEvents,
              //   {
              //     id: typeId,
              //     name: typeName,
              //     date: values.eventDate,
              //   },
              // ])
            )
            .then(() => dispatch(updateEvent({ user, event, values })))
            .then(() => dispatch(fetchEvent(eventId)))
            .then(() => dispatch(fetchTypes(eventId)));
          setSubmitting(false);
          handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Fee *With Competitive Discount
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="entryFee"
              type="entryFee"
              onChange={handleChange}
              placeholder="$100"
              id="entryFee"
              value={values.entryFee}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Fee *Without Competitive Discount
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="entryFeeNonCompetitive"
              type="entryFeeNonCompetitive"
              onChange={handleChange}
              placeholder="$100"
              id="entryFeeNonCompetitive"
              value={values.entryFeeNonCompetitive}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Date
            </Header>
            <DatePicker
              style={{ borderRadius: 10 }}
              selected={values.eventDate}
              dateFormat="MMMM d, yyyy"
              name="eventDate"
              onChange={(date) => {
                setFieldValue("eventDate", date);
                combinedEvents.some((el) => el.id && el.id === type.id) === true
                  ? setCombinedEvents([
                      ...combinedEvents.filter((el) => el.id !== type.id),
                      {
                        id: typeId,
                        name: typeName,
                        date: date,
                      },
                    ])
                  : setCombinedEvents([
                      ...combinedEvents,
                      {
                        id: typeId,
                        name: typeName,
                        date: date,
                      },
                    ]);
              }}
            />

            <br />
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Save & Close
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EditSchoolingForm;
