import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, } from "semantic-ui-react";
import { fetchTrainees } from "../../features/users/userSlice"


const Trainees = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.users.activeUser);
    const trainees = useSelector((state) => state.users.trainees);

    useEffect(() => {
        dispatch(fetchTrainees({user})).then((res)=>console.log(res));
      }, []);


    console.log(user)
    return (
        <div>
            <div className="event-subview-title-area">
                 <h2>My Riders</h2>
            </div>
            <Card key={user.id} fluid className="card">
                <Container fluid className="profile-container">
                <h1
                    style={{
                    textAlign: "left",
                    fontFamily: "Lato",
                    fontSize: "30px",
                    fontWeight: "900px",
                    }}
                >
                
                    <strong>First Name</strong>
                </h1>


                <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                </h3>
                {/* <Card.Content style={{ textAlign: "left" }}>
                    <p>{user.firstName}</p>
                </Card.Content>

                <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    <strong>Last Name</strong>
                </h3>
                <Card.Content style={{ textAlign: "left" }}>
                    <p>{user.lastName}</p>
                </Card.Content>

                <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    <strong>Phone</strong>
                </h3>
                <Card.Content style={{ textAlign: "left" }}>
                    <p>{user.phone}</p>
                </Card.Content>

                <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    <strong>Address</strong>
                </h3>
                <Card.Content style={{ textAlign: "left" }}>
                    <p>{user.address}</p>
                </Card.Content> */}
                </Container>
            </Card>
            
        </div>
    )
}

export default Trainees
