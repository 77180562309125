import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Grid, Image, Header } from "semantic-ui-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Redirect } from "react-router-dom";

import { Formik } from "formik";
import { fetchEventRVs } from "../../../features/events/eventsSlice";
import { createRV } from "../../../features/RVs/RVsSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Dimmer, Loader } from "semantic-ui-react";

const EditVIPs = ({ event, setVIPShow, setPriceShow }) => {
  const [isLoading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [numRVs, setNumRVs] = useState(0);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  return (
    <section>
      {isLoading && (
        <Dimmer active page>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <br />
      <Formik
        initialValues={{
          eventId: event.id,
          date: null,
          paid: null,
          vip: true,
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          var enumerateDaysBetweenDates = function (startDate, endDate) {
            var dates = [];
            dates.push(startDate);
            var currDate = moment(startDate).startOf("day");
            var lastDate = moment(endDate).startOf("day");

            while (currDate.add(1, "days").diff(lastDate) < 0) {
              console.log(currDate.toDate());
              dates.push(currDate.clone().toDate());
            }
            dates.push(endDate);

            return dates;
          };
          enumerateDaysBetweenDates(startDate, endDate).map((day) => {
            if (numRVs === 0) setLoading(false);
            if (startDate === null) setLoading(false);
            if (endDate === null) setLoading(false);
            for (let i = 0; i < numRVs; i++) {
              values.date = moment(day).format().substr(0, 10);

              dispatch(createRV({ user, values })).then(() =>
                dispatch(fetchEventRVs({ event }))
              );
              if (i === numRVs - 1) {
                setLoading(false);
              }
            }
          });
          setVIPShow(false);
          setPriceShow(true);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h3">Step 2: VIP Spots</Header>
            <br />
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Number VIP Spots per Day
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              onChange={(e) => setNumRVs(e.target.value)}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>{" "}
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Start Date
            </Header>
            <DatePicker
              selected={startDate}
              style={{ borderRadius: 10 }}
              dateFormat="MMMM d, yyyy"
              name="date"
              onChange={(date) => setStartDate(date)}
            />
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              End Date
            </Header>
            <DatePicker
              selected={endDate}
              style={{ borderRadius: 10 }}
              dateFormat="MMMM d, yyyy"
              name="date"
              onChange={(date) => setEndDate(date)}
            />
            <br />
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,
                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Create RVs spots or Skip to Step 3
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EditVIPs;
