import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "semantic-ui-react";
import moment from "moment";
import { fetchEvents } from "../../features/events/eventsSlice";
import "../../App.css";

const Events = () => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.events.events);
  // const fetchStatus = useSelector((state) => state.events.fetchStatus);

  useEffect(() => {
    dispatch(fetchEvents());
  }, []);

  const newEvtArray = [...events];

  return (
    <div className="main-content-cards">
      {/* {fetchStatus === "loading" && (
        <Dimmer active page>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )} */}
      <div className="event-title-area">
        <h1 className="heading-3">Current & Upcoming Events</h1>
      </div>
      <div className="row-view">
        {(!events || events.length === 0) && <h1>No Events</h1>}
        {events &&
          newEvtArray.reverse().map((event, index) => (
            <div key={index} className="event-card">
              <img className="card-event-image" src={event.imgUrl} alt="" />

              <div className="card-info-area">
                <div className="event-row-title-area">
                  <div className="text-block-2">{event.name}</div>
                  <h3 className="division-row-title">
                    {event.shortDescription}
                  </h3>
                </div>

                <div className="event-row-title-area-2">
                  <div className="detail-text">
                    {event.location || "No Location Added To Event"}
                  </div>
                  <div className="text-block-2">
                    {moment.utc(event.startDate).format("MMMM D, YYYY")}

                    <br />
                  </div>
                </div>
              </div>
              <Container className="card-button-container">
                {process.env.REACT_APP_ENV !== "production" ? (
                  <>
                    <Link
                      className="card-button"
                      to={{ pathname: "/event", state: event }}
                    >
                      Event Info
                    </Link>
                    <br />
                    {event.id === 2 ? (
                      <a
                        href="https://www.pearlsevent.com/trhp/event-page"
                        className="card-button"
                      >
                        Live Updates
                      </a>
                    ) : (
                      <a
                        href="https://www.pearlsevent.com/sspc/event-page"
                        className="card-button"
                      >
                        Live Updates
                      </a>
                    )}
                  </>
                ) : (
                  <>
                    <Link
                      className="card-button"
                      to={{ pathname: "/event", state: event }}
                    >
                      Event Info
                    </Link>
                    <br />

                    <a
                      href={event.liveUpdates ? event.liveUpdates : ""}
                      className="card-button"
                    >
                      Live Updates
                    </a>
                  </>
                )}
              </Container>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Events;
