import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Header } from "semantic-ui-react";
import { Formik } from "formik";
import { updateEvent } from "../../../features/events/eventsSlice";

const EditLocationForm = ({ event, handleClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);

  return (
    <section>
      <br />
      <Formik
        initialValues={{
          name: event.name,
          id: event.id,
          address: event.address,
          description: event.description,
          parkingDescription: event.parkingDescription
            ? event.parkingDescription
            : "",
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log("values========================>", values);
          dispatch(updateEvent({ user, event, values }));
          setSubmitting(false);
          handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Address
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="address"
              type="address"
              onChange={handleChange}
              placeholder="555 Main St. Anytown, TX 55555"
              id="address"
              value={values.address}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Parking
            </Header>
            <Form.TextArea
              style={{ height: 50, borderRadius: 10 }}
              placeholder="Parking directions"
              type="parkingDescription"
              name="parkingDescription"
              onChange={handleChange}
              id="parkingDescription"
              value={values.parkingDescription}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.TextArea>

            <br />
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Save & Close
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EditLocationForm;
