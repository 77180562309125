import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Icon } from "semantic-ui-react";
import moment from "moment";
import { fetchEvent } from "../../features/events/eventsSlice";
import { fetchStalls } from "../../features/stalls/stallsSlice";
import EditModal from "./EventEditForms/EditModal";

const MgrStalls = ({ eventId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const min = useSelector((state) => state.stalls.minDate);
  const max = useSelector((state) => state.stalls.maxDate);
  const stallMin = moment
    .utc(useSelector((state) => state.stalls.minDate))
    .format("MMMM D, YYYY");
  const stallMax = moment
    .utc(useSelector((state) => state.stalls.maxDate))
    .format("MMMM D, YYYY");

  const event = useSelector((state) => state.events.event);
  useEffect(() => {
    dispatch(fetchStalls(event));
  }, []);

  const stalls = useSelector((state) => state.stalls.stalls);

  const stallDayDates = new Set();
  const stallNightDates = new Set();
  stalls &&
    stalls.forEach((stall) => {
      return stall.isOvernight === false
        ? stallDayDates.add(stall.date)
        : stallNightDates.add(stall.date);
    });
  const stallDayDatesArray = [...stallDayDates];
  const stallNightDatesArray = [...stallNightDates];
  const sortedDayStallDates = stallDayDatesArray;
  const sortedNightStallDates = stallNightDatesArray
    .sort()
    .filter((date) => date != null);

  let card = "Stalls";
  return (
    <div>
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1
            style={{
              textAlign: "left",
              fontFamily: "Lato",
              fontSize: "30px",
              fontWeight: "900px",
            }}
          >
            <strong>
              Stall Information
              <EditModal fluid event={event} card={card} />
            </strong>
            {event.noStallFee === null ? (
              <strong style={{ color: "red", marginLeft: "1rem" }}>
                *Optional
              </strong>
            ) : (
              <Icon
                name="check circle"
                style={{ color: "green", marginLeft: "1rem" }}
              />
            )}
          </h1>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Stall Dates Available</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {min !== 0 || max !== 0 ? (
              <p>{`${stallMin}-${stallMax}`}</p>
            ) : (
              <p>No Dates Entered Yet</p>
            )}
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Overnight Cost</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.stallOvernightFee && event.stallOvernightFee > 0
                ? "$" + event.stallOvernightFee + " / night"
                : ""}
            </p>
          </Card.Content>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Daytime Cost</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.stallDaytimeFee && event.stallDaytimeFee > 0
                ? "$" + event.stallDaytimeFee + " / day"
                : ""}
            </p>
          </Card.Content>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Mandatory Grounds Fee</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.noStallFee && event.noStallFee > 0
                ? "$" + event.noStallFee
                : ""}
            </p>
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Additional Stall Information</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {event.stallAdditionalInfo ? (
              <p>{event.stallAdditionalInfo}</p>
            ) : (
              <p>No Stall Information Given</p>
            )}
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Cost of Additional Shavings</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>${event.stallShavingFees} per bag</p>
          </Card.Content>
        </Container>
      </Card>
    </div>
  );
};

export default MgrStalls;
