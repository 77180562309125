import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Grid, Image, Header } from "semantic-ui-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Redirect } from "react-router-dom";

import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { updateEvent, fetchEvent } from "../../../features/events/eventsSlice";
import moment from "moment";

const EditBasicInfoForm = ({ event, handleClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const eventId = event.id;
  return (
    <section>
      <br />
      <Formik
        initialValues={{
          name: event.name,
          id: event.id,
          facilityName: event.facilityName,
          shortDescription: event.shortDescription,
          description: event.description,
          startDate: null,
          endDate: null,
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log("values", values);
          dispatch(updateEvent({ user, event, values })).then(() =>
            dispatch(fetchEvent(eventId))
          );
          setSubmitting(false);
          handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Title
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="name"
              type="name"
              onChange={handleChange}
              placeholder="Title"
              id="name"
              value={values.name}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Start Date
            </Header>
            <DatePicker
              selected={values.startDate}
              style={{ borderRadius: 10 }}
              dateFormat="MMMM d, yyyy"
              name="startDate"
              onChange={(date) => setFieldValue("startDate", date)}
            />
            <br />
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              End Date
            </Header>
            <div>
              <DatePicker
                style={{ borderRadius: 10 }}
                selected={values.endDate}
                dateFormat="MMMM d, yyyy"
                name="endDate"
                onChange={(date) => setFieldValue("endDate", date)}
              />
            </div>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Facility Name
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="facilityName"
              type="facilityName"
              onChange={handleChange}
              placeholder="Facility Name"
              id="facilityName"
              value={values.facilityName}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Introductory Description
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="shortDescription"
              type="shortDescription"
              onChange={handleChange}
              placeholder="A brief description"
              id="shortDescription"
              value={values.shortDescription}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Long Description
            </Header>
            <Form.TextArea
              style={{ height: 50, borderRadius: 10 }}
              placeholder="A longer Description"
              type="description"
              name="description"
              onChange={handleChange}
              id="description"
              value={values.description}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.TextArea>

            <br />
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Save & Close
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EditBasicInfoForm;
