import React, { useState } from "react";
import "../../App.css";
import ProfileHeader from "./ProfileHeader";
import { Container, Menu } from "semantic-ui-react";
import AboutMe from "./AboutMe";
import ManagerEvents from "../EventManagerProfile/ManagerEvents";


const MgrProfile = () => {
  const [activeItems, setActiveItems] = useState([
    {
      key: "rider",
      active: true,
      name: "Profile",
      icon: "./Profile v1.svg",
    },
    {
      key: "events",
      active: false,
      name: "Events",
      icon: "./Events v1.svg",
    },
  ]);

  const handleClick = (index) => {
    setActiveItems((prevItems) =>
      prevItems.map((e, i) => ({ ...e, active: i === index }))
    );
  };

  const menu = activeItems.map((item, index) => {
    return (
      <>
        <a
          href
          key={index}
          onClick={() => handleClick(index)}
          className={
            item.active ? "event-nav-button-active" : "event-nav-button"
          }
        >
          <img key={index} className="event-nav-icon" src={item.icon} alt="" />
          <div style={{ height: "18px" }}>{item.name}</div>
        </a>
      </>
    );
  });

  return (
    <div className="main-content-cards">
      <ProfileHeader />
      <Container fluid>
        <div className="event-nav">{menu}</div>
        {activeItems.map((item, index) => {
          return item.key === "rider" && item.active === true ? (
            <AboutMe key={index} />
          ) : item.key === "events" && item.active === true ? (
            <ManagerEvents key={index} />
          ) : (
            ""
          );
        })}
      </Container>
    </div>
  );
};

export default MgrProfile;
