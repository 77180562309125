import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Icon } from "semantic-ui-react";
import { uploadEventImage } from "../../features/events/eventsSlice";
import { fetchEvent } from "../../features/events/eventsSlice";
import EditModal from "./EventEditForms/EditModal";
import moment from "moment";

const MgrEntryInfo = ({ eventId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const event = useSelector((state) => state.events.event);

  let card = "Entry";

  return (
    <div>
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1
            style={{
              textAlign: "left",
              fontFamily: "Lato",
              fontSize: "1.875rem",
              fontWeight: "900px",
            }}
          >
            <strong>
              Entry Information
              <EditModal fluid event={event} card={card} />
            </strong>
            {event.registrationOpen === null ||
            event.registrationOpen === "Invalid date" ||
            event.registrationClose === null ||
            event.registrationClose === "Invalid date" ? (
              <strong style={{ color: "red", marginLeft: "1rem" }}>
                *Required
              </strong>
            ) : (
              <Icon
                name="check circle"
                style={{ color: "green", marginLeft: "1rem" }}
              />
            )}
          </h1>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Entry Open Date</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {event.registrationOpen === null ||
            event.registrationOpen === "Invalid date" ? (
              <p>No Date Entered Yet</p>
            ) : (
              <p>
                {moment(event.registrationOpen, "YYYY-MM-DD HH:mm:ss A").format(
                  "MMMM D, YYYY"
                )}
              </p>
            )}
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Entry Closed Date</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {event.registrationClose === null ||
            event.registrationClose === "Invalid date" ? (
              <p>No Date Entered Yet</p>
            ) : (
              <p>
                {moment(
                  event.registrationClose,
                  "YYYY-MM-DD HH:mm:ss A"
                ).format("MMMM D, YYYY")}
              </p>
            )}
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Office Fee</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.fees &&
                event.fees.map((el) => {
                  return el.name === "Office" && el.price !== null
                    ? `$${el.price}`
                    : null;
                })}
            </p>
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Tech Fee</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.fees &&
                event.fees.map((el) => {
                  return el.name === "Tech" && el.price !== null
                    ? `$${el.price}`
                    : null;
                })}
            </p>
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Late Fee</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.fees &&
                event.fees.map((el) => {
                  return el.name === "Late" && el.price !== null
                    ? `$${el.price}`
                    : null;
                })}
            </p>
          </Card.Content>
        </Container>
      </Card>
    </div>
  );
};
export default MgrEntryInfo;
