import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../api/client";

const initialState = {
  status: "",
  fetchStatus: "",
  userHorses: [{}],
};

export const createHorse = createAsyncThunk(
  "horses/createHorse",
  async (data) => {
    const response = await client.post("horses", data.values, {
      headers: { authorization: data.user.accessToken },
    });
    return response;
  }
);
export const fetchHorses = createAsyncThunk(
  "horses/fetchHorses",
  async (data) => {
    const response = await client.get(`horses?userId=${data.id}`, {
      headers: { authorization: data.accessToken },
    });

    return response.horses;
  }
);
export const updateHorse = createAsyncThunk(
  "horses/updateHorse",
  async (data) => {
    console.log("updating horse", data);
    const response = await client.put(`horses/${data.horseId}`, data.values, {
      headers: { authorization: data.user.accessToken },
    });
    return response;
  }
);

export const uploadHorseDocument = (data) => async (dispatch) => {
  console.log("upload data: ", data.file);
  console.log("horseId: ", data.horseId);
  const body = {
    fileName: data.file.name,
    fileType: data.file.type,
  };
  const uploadResponse = await client.post(
    `/horses/${data.horseId}/documents`,
    body,
    {
      headers: { authorization: data.user.accessToken },
    }
  );
  const { upload } = uploadResponse;
  console.log("upload ", upload);

  if (!upload || !upload.uploadURL) {
    //dispatch error reducer here
  }

  const fileResponse = await fetch(upload.uploadURL, {
    method: "PUT",
    body: data.file,
    params: {
      Key: data.file.name,
      ContentType: data.file.type,
    },
    headers: {
      "Content-Type": data.file.type,
    },
  });

  const values = {};
  values[data.documentType] = upload.url;

  dispatch(
    updateHorse({
      user: data.user,
      horseId: data.horseId,
      values,
    })
  );
};

const horsesSlice = createSlice({
  name: "horses",
  initialState,
  reducers: {
    //reducers for email password?
    resetHorses: (state) => initialState,
    // resetHorses(state, action) {
    //   state = null;
    //   // state.userHorses = null;
    //   // state.fetchStatus = null;
    // },
  },
  extraReducers: {
    [createHorse.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [createHorse.fulfilled]: (state, action) => {
      console.log("action payload", action.payload);
      state.fetchStatus = "succeeded";
      // state.horse = action.payload.horse;
      state.userHorses.push(action.payload);
    },
    [createHorse.rejected]: (state, action) => {
      console.log("action payload", action.payload);
      state.fetchStatus = "failed";
      state.error = action.payload;
    },
    [fetchHorses.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchHorses.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";

      state.userHorses = [...action.payload];
    },
    [fetchHorses.rejected]: (state, action) => {
      console.log("action payload", action.payload);
      state.fetchStatus = "failed";
      state.error = action.payload;
    },
    [updateHorse.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [updateHorse.fulfilled]: (state, action) => {
      console.log("update action payload", action.payload);
      state.fetchStatus = "succeeded";
      state.userHorses = state.userHorses.map((horse) => {
        if (horse.id === action.payload.id) {
          return action.payload;
        }
        return horse;
      });
    },
    [updateHorse.rejected]: (state, action) => {
      console.log("update action payload", action.payload);
      state.fetchStatus = "failed";
      state.error = action.payload;
    },
    [uploadHorseDocument.pending]: (state, action) => {
      console.log("state", state);
      state.fetchStatus = "loading";
    },
  },
});
export const { resetHorses } = horsesSlice.actions;

export default horsesSlice.reducer;
