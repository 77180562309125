import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Dimmer, Loader, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import moment from "moment";
import { fetchMgrEvents, resetEvents } from "../../features/events/eventsSlice";
import MgrModal from "./MgrModal";
import "../../App.css";

const ManagerEvents = () => {
  const [currentTime, setCurrentTime] = useState();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const email = user.email;
  const events = useSelector((state) => state.events.events);
  const fetchStatus = useSelector((state) => state.events.fetchStatus);

  useEffect(() => {
    dispatch(resetEvents());
    dispatch(fetchMgrEvents(email));
    setCurrentTime(Date().toLocaleString());
  }, []);

  return (
    <div>
      {fetchStatus === "loading" && (
        <Dimmer active page>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <div className="event-subview-title-area">
        <h2>Current & Upcoming Events</h2> <MgrModal />
      </div>
      <div className="row-view">
        {events &&
          events.map((event, index) => {
            return moment(event.endDate).isBefore(currentTime) !== true ? (
              <div key={index} className="event-card">
                <img className="card-event-image" src={event.imgUrl} alt="" />

                <div className="card-info-area">
                  <div className="event-row-title-area">
                    <div className="text-block-2">{event.name}</div>
                    <h3 className="division-row-title">
                      {event.shortDescription}
                    </h3>
                  </div>

                  <div className="event-row-title-area-2">
                    <div className="detail-text">
                      {event.location || "No Location Added To Event"}
                    </div>
                    <div className="text-block-2">
                      {moment(event.startDate).format("MMMM D, YYYY")}

                      <br />
                    </div>
                  </div>
                </div>
                <Container className="card-button-container">
                  <Link
                    className="card-button"
                    to={{ pathname: "/manager-edit", state: event }}
                  >
                    Edit Event
                  </Link>
                  <br />
                  <Link
                    className="card-button"
                    to={{ pathname: "/event", state: event }}
                  >
                    Event Info
                  </Link>
                </Container>
              </div>
            ) : null;
          })}
        {/* <div
          className="event-card"
          style={{ maxheight: "323px", minHeight: "271px", display: "flex" }}
        >
          <div className="mgr-modal">
            <MgrModal />
          </div>
        </div> */}
      </div>
      <br />

      <div>
        <div className="event-subview-title-area">
          <h2>Past Events</h2>
        </div>
        <div className="row-view">
          {events &&
            events.map((event, index) => {
              return moment(event.endDate).isBefore(currentTime) === true ? (
                <div key={index} className="event-card">
                  <img src={event.imgUrl} alt="" />

                  <div className="card-info-area">
                    <div className="event-row-title-area">
                      <div className="text-block-2">{event.name}</div>
                      <h3 className="division-row-title">
                        {event.shortDescription}
                      </h3>
                    </div>

                    <div className="event-row-title-area-2">
                      <div className="detail-text">
                        {event.location || "No Location Added To Event"}
                      </div>
                      <div className="text-block-2">
                        {moment(event.startDate).format("MMMM D, YYYY")}

                        <br />
                      </div>
                    </div>
                  </div>
                  <Container className="card-button-container">
                    <Link
                      className="card-button"
                      to={{ pathname: "/event", state: event }}
                    >
                      Event Info
                    </Link>
                    <br />

                    <a
                      href="https://www.pearlsevent.com/trhp/event-page"
                      className="card-button"
                    >
                      Live Updates
                    </a>
                  </Container>
                </div>
              ) : (
                <div key={index}></div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ManagerEvents;
