import React from "react";
import { Link } from "react-router-dom";
import { Container, Header, Grid } from "semantic-ui-react";
import PearlErrorDialogue from "./PearlErrorDialogue";

export default class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: { message: "", stack: "" },
    info: { componentStack: "" },
  };

  static getDerivedStateFromError = (error) => {
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    this.setState({ error, info });
  };

  render() {
    const { hasError, error, info } = this.state;
    const { children } = this.props;

    return hasError ? (
      <div>
        <Container fluid>
          <br />
          <Grid
            textAlign="center"
            style={{ height: "100%" }}
            verticalAlign="middle"
          >
            <Grid.Column style={{ maxWidth: 375 }}>
              <img
                className="pearl-icon-large"
                src="./illustratorPEARLB.png"
                alt=""
              />
              <br />
              <PearlErrorDialogue />
              <br />

              <Header as="h2">
                <div onClick={() => window.location.reload()}>
                  <Link to="./">Back Home</Link>
                </div>
              </Header>
              <br />
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    ) : (
      children
    );
  }
}
