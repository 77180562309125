import React from "react";
import { Card, Container, Grid, Header } from "semantic-ui-react";
import { useSelector } from "react-redux";

const HorseTrialEntries = () => {
  const event = useSelector((state) => state.events.event);
  const paidRiders = event.EventRiders.filter((rider)=> rider.paymentStatus === "succeeded")
  const types = event.EventTypes;

  let intro = 0;
  let starter = 0;
  let begNovice = 0;
  let novice = 0;
  let training = 0;
  let preliminary = 0;

  console.log("HT...", paidRiders)
  paidRiders && paidRiders.forEach((rider) => {
    if (rider.events.competitive.name === "Horse Trial") {
      if (rider.height === "Intro (up to 18 inches)") {
        intro += 1;
      }
      if (rider.height === "Starter (2'3\")") {
        starter += 1;
      }
      if (rider.height === "Beginner Novice (2'7\")") {
        begNovice += 1;
      }
      if (rider.height === "2018 Novice (2'11\")") {
        novice += 1;
      }
      if (rider.height === "Training (3'3\")") {
        training += 1;
      }
      if (rider.height === "Preliminary (3'7\")") {
        preliminary += 1;
      }
    }
  });

  return (
    <>
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h3
            style={{
              textAlign: "left",
              fontFamily: "Lato",
              fontSize: "1.25rem",
              fontWeight: "900px",
            }}
          >
            <strong>Horse Trial Entries</strong>
          </h3>
          <Grid fluid columns={3}>
            <Grid.Row style={{ marginBottom: "-3rem" }}>
              <Grid.Column
                style={{ paddingLeft: ".2rem", paddingRight: ".2rem" }}
              >
                <Card fluid>
                  <Card.Content>
                    <Header as="h5" floated="left" style={{ marginBottom: 2 }}>
                      Intro
                    </Header>
                    <Header
                      as="h5"
                      floated="right"
                      style={{ marginBottom: 2, marginRight: 0 }}
                    >
                      {intro}
                    </Header>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column
                style={{ paddingLeft: ".2rem", paddingRight: ".2rem" }}
              >
                <Card fluid>
                  <Card.Content>
                    <Header as="h5" floated="left" style={{ marginBottom: 2 }}>
                      Starter
                    </Header>
                    <Header
                      as="h5"
                      floated="right"
                      style={{ marginBottom: 2, marginRight: 0 }}
                    >
                      {starter}
                    </Header>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column
                style={{ paddingLeft: ".2rem", paddingRight: ".2rem" }}
              >
                <Card fluid>
                  <Card.Content>
                    <Header
                      as="h5"
                      floated="left"
                      style={{ marginBottom: 2, marginRight: -2 }}
                    >
                      Beg Novice
                    </Header>
                    <Header
                      as="h5"
                      floated="right"
                      style={{ marginBottom: 2, marginRight: 0 }}
                    >
                      {begNovice}
                    </Header>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginBottom: "-1rem" }}>
              <Grid.Column
                style={{ paddingLeft: ".2rem", paddingRight: ".2rem" }}
              >
                <Card fluid>
                  <Card.Content>
                    <Header as="h5" floated="left" style={{ marginBottom: 2 }}>
                      Novice
                    </Header>
                    <Header
                      as="h5"
                      floated="right"
                      style={{ marginBottom: 2, marginRight: 0 }}
                    >
                      {novice}
                    </Header>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column
                style={{ paddingLeft: ".2rem", paddingRight: ".2rem" }}
              >
                <Card fluid>
                  <Card.Content>
                    <Header as="h5" floated="left" style={{ marginBottom: 2 }}>
                      Training
                    </Header>
                    <Header
                      as="h5"
                      floated="right"
                      style={{ marginBottom: 2, marginRight: 0 }}
                    >
                      {training}
                    </Header>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column
                style={{ paddingLeft: ".2rem", paddingRight: ".2rem" }}
              >
                <Card fluid>
                  <Card.Content>
                    <Header as="h5" floated="left" style={{ marginBottom: 2 }}>
                      Prelimin
                    </Header>
                    <Header
                      as="h5"
                      floated="right"
                      style={{ marginBottom: 2, marginRight: 0 }}
                    >
                      {preliminary}
                    </Header>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Card>
    </>
  );
};

export default HorseTrialEntries;
