import React, { useState } from "react";
import { Grid, Image, Form, Button, Icon, Header } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";
import UpdatePasswordDialogue from "./UpdatePasswordDialogue";
import MissingPassowrdResetTokenDialogue from "./MissingPassowrdResetTokenDialogue";
import { client } from "../api/client";

import { Formik } from "formik";

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const accessToken = window.location.href.split("?token=")[1];
  console.log("accessToken", accessToken);
  return (
    <div>
      {success ? <Redirect to="/login" /> : null}
      <br />
      <br />
      <br />
      <Grid textAlign="center" style={{ height: "120vh" }}>
        <Grid.Column style={{ maxWidth: 355 }}>
          <Image centered size="small" src="./illustratorPEARLB.png" />
          <br />
          {accessToken ? (
            <UpdatePasswordDialogue />
          ) : (
            <MissingPassowrdResetTokenDialogue />
          )}
          <br />

          {accessToken && (
            <Formik
              initialValues={{ email: "" }}
              validate={(values) => {
                const errors = {};
                if (values.password.length < 8) {
                  errors.password = "Password must contain 8 characters";
                } else if (values.password !== values.passwordConfirmation) {
                  errors.passwordConfirmation = "Passwords do not match";
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                const response = await client.put("authn/password", values, {
                  headers: { authorization: accessToken },
                });

                if (!response.error) {
                  setSuccess(true);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <br />
                  <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                    Password*
                  </Header>
                  {errors.password && touched.password && errors.password}
                  <Form.Input
                    style={{ height: 50, borderRadius: 10 }}
                    iconPosition="left"
                    placeholder="Password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    id="password"
                    value={values.password}
                  >
                    <Icon className="lock" />
                    <input style={{ borderRadius: 10 }} />
                  </Form.Input>
                  <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                    Re-Enter Password*
                  </Header>
                  {errors.passwordConfirmation &&
                    touched.passwordConfirmation &&
                    errors.passwordConfirmation}
                  <Form.Input
                    style={{ height: 50, borderRadius: 10 }}
                    iconPosition="left"
                    placeholder="Password (again)"
                    type="password"
                    name="passwordConfirmation"
                    onChange={handleChange}
                    id="passwordConfirmation"
                    value={values.passwordConfirmation}
                  >
                    <Icon className="lock" />
                    <input style={{ borderRadius: 10 }} />
                  </Form.Input>

                  <br />
                  <Button
                    style={{
                      backgroundColor: "#ffdea2",
                      height: 50,
                      borderRadius: 10,
                    }}
                    fluid
                    black
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          )}
          <Header as="h4">
            New to us?
            <div>
              <Link to="./create-account">Sign Up</Link>
            </div>
          </Header>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ForgotPassword;
