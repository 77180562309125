import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Grid, Image, Header } from "semantic-ui-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Redirect } from "react-router-dom";
import EditDayStalls from "./EditDayStalls";
import EditNightStalls from "./EditNightStalls";
import { Formik } from "formik";
import { updateEvent } from "../../../features/events/eventsSlice";
import { createStall, fetchStalls } from "../../../features/stalls/stallsSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const EditStallsForm = ({ event, handleClose }) => {
  const [nightShow, setNightShow] = useState(true);
  const [dayShow, setDayShow] = useState(false);
  const [priceShow, setPriceShow] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);

  return (
    <section>
      <br />
      {nightShow === true && (
        <EditNightStalls
          event={event}
          setDayShow={setDayShow}
          setNightShow={setNightShow}
        />
      )}
      {dayShow === true && (
        <EditDayStalls
          event={event}
          setDayShow={setDayShow}
          setPriceShow={setPriceShow}
        />
      )}

      {priceShow && (
        <>
          <br />
          <Formik
            initialValues={{
              name: event.name,
              id: event.id,
              description: event.description,
              stallOvernightFee: event.stallOvernightFee,
              stallDaytimeFee: event.stallDaytimeFee,
              stallAdditionalInfo: event.stallAdditionalInfo
                ? event.stallAdditionalInfo
                : "",
              noStallFee: event.noStallFee,
              stallShavingFees: event.stallShavingFees,
            }}
            validate={(values) => {
              const errors = {};

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              console.log("values", values);
              dispatch(updateEvent({ user, event, values })).then(()=> dispatch(fetchStalls(event)))

              setSubmitting(false);
              handleClose();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <Header as="h3">Step 3: Details</Header>
                  <br/>
                <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                  Overnight Stall Cost per Night
                </Header>
                <Form.Input
                  style={{ height: 50, borderRadius: 10 }}
                  name="stallOvernightFee"
                  type="stallOvernightFee"
                  onChange={handleChange}
                  placeholder="$25"
                  id="stallOvernightFee"
                  value={values.stallOvernightFee}
                >
                  <input style={{ borderRadius: 10 }} />
                </Form.Input>
                <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                  Day Stall Cost per Day
                </Header>
                <Form.Input
                  style={{ height: 50, borderRadius: 10 }}
                  name="stallDaytimeFee"
                  type="stallDaytimeFee"
                  onChange={handleChange}
                  placeholder="$25"
                  id="stallDaytimeFee"
                  value={values.stallDaytimeFee}
                >
                  <input style={{ borderRadius: 10 }} />
                </Form.Input>
                <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                  Cost per Additional Bag of Shavings
                </Header>
                <Form.Input
                  style={{ height: 50, borderRadius: 10 }}
                  name="stallShavingFees"
                  type="stallShavingFees"
                  onChange={handleChange}
                  placeholder="$10"
                  id="stallShavingFees"
                  value={values.stallShavingFees}
                >
                <input style={{ borderRadius: 10 }} />
                </Form.Input>
                <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                  If no stall, Mandatory Grounds Fee
                </Header>
                <Form.Input
                  style={{ height: 50, borderRadius: 10 }}
                  name="noStallFee"
                  type="noStallFee"
                  onChange={handleChange}
                  placeholder="$10"
                  id="noStallFee"
                  value={values.noStallFee}
                >
                  <input style={{ borderRadius: 10 }} />
                </Form.Input>
                <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                  Additional Stall Information
                </Header>
                <Form.TextArea
                  style={{ height: 50, borderRadius: 10 }}
                  name="stallAdditionalInfo"
                  type="stallAdditionalInfo"
                  onChange={handleChange}
                  placeholder="Add additional information about stalls here..."
                  id="stallAdditionalInfo"
                  value={values.stallAdditionalInfo}
                >
                  <input style={{ borderRadius: 10 }} />
                </Form.TextArea>
                <br />
                <Button
                  style={{
                    backgroundColor: "#ffdea2",
                    height: 50,

                    borderRadius: 10,
                  }}
                  fluid
                  type="submit"
                >
                  Save & Close
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </section>
  );
};

export default EditStallsForm;
