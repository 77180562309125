import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Loader, Dimmer, Icon, Grid } from "semantic-ui-react";
import { uploadEventImage } from "../../features/events/eventsSlice";
import { fetchEvent } from "../../features/events/eventsSlice";
import EditModal from "./EventEditForms/EditModal";
import EventCreateSuccesMessageCard from "./EventCreateSuccesMessageCard";
import moment from "moment";

const MgrEventInfo = ({ eventId }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  useEffect(() => {
    dispatch(fetchEvent(eventId));
  }, []);
  const event = useSelector((state) => state.events.event);

  const onFileChangeHandler = (e) => {
    setLoading(true);
    const file = e.target.files[0];
    dispatch(uploadEventImage({ user, event, file })).then(() =>
      setLoading(false)
    );
  };
  let card = "Info";
  const types = event.EventTypes;


  return (
    <div>
      {loading === true && (
        <Dimmer active page>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}

      {/* Alert if a minimal event has been created */}

      {types && <EventCreateSuccesMessageCard event={event} types={types} />}
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1
            style={{
              textAlign: "left",
              fontFamily: "Lato",
              fontSize: "30px",
              fontWeight: "900px",
            }}
          >
            <strong>
              Event Info
              <EditModal fluid event={event} card={card} />
            </strong>

            {event.facilityName === null ||
            event.shortDescription === null ||
            event.description ===
              "*Please enter this full-length description with Edit" ||
            event.startDate === null ||
            event.endDate === null ? (
              <strong style={{ color: "red", marginLeft: "1rem" }}>
                *Required
              </strong>
            ) : (
              <Icon
                name="check circle"
                style={{ color: "green", marginLeft: "1rem" }}
              />
            )}
          </h1>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Banner Image</strong>
          </h3>

          <label className="custom-file-upload" style={{ marginTop: 4 }}>
            <input
              type="file"
              name="file"
              accept="image/png,image/gif,image/jpeg"
              // style={{ float: "left" }}
              onChange={onFileChangeHandler}
            />
            Upload Banner Image
          </label>
          <br />
          <br />
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Title</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.name ? event.name : "*Please enter your title with Edit"}
            </p>
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Start Date</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {event.startDate !== null && event.startDate !== 0 ? (
              <p>{moment.utc(event.startDate).format("MMMM D, YYYY")}</p>
            ) : (
              <p>No Date Entered Yet</p>
            )}
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>End Date</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {event.endDate !== null && event.endDate !== 0 ? (
              <p>{moment.utc(event.endDate).format("MMMM D, YYYY")}</p>
            ) : (
              <p>No Date Entered Yet</p>
            )}
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Facility Name</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.facilityName
                ? event.facilityName
                : "*Please enter your facility name with Edit"}
            </p>
          </Card.Content>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Short Description</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.shortDescription
                ? event.shortDescription
                : "*Please enter your facility name with Edit"}
            </p>
          </Card.Content>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Description</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>{event.description}</p>
          </Card.Content>
        </Container>
      </Card>
    </div>
  );
};

export default MgrEventInfo;
