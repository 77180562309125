import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../api/client";

const initialState = {
  status: "",
  activeUser: {
    email: null,
    password: null,
    firstName: null,
    lastName: null,
    ageGroup: null,
    role: null,
    children: []
  },
  trainers: [{}],
  trainees: [{}],
  parent:{},
  error: "",
};

export const createUser = createAsyncThunk("users/createUser", async (data) => {
  const response = await client.post("authn/signup", data);
  return response;
});

export const loginUser = createAsyncThunk("users/loginUser", async (data) => {
  const response = await client.post("authn/login", data);
  return response;
});

export const logoutUser = createAsyncThunk(
  "users/logoutUser",
  async (accessToken) => {
    const response = await client.delete("authn/logout", null, {
    headers: { authorization: accessToken },
    });
  }
);

export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  const response = await client.get("users");
  return response.users;
});

export const fetchUser = createAsyncThunk(
  "users/fetchUser",
  async (data) => {
    console.log("Parent ID", data.user.parentId)
    const response = await client.get(`users/${data.user.parentId}`, {
      headers: { authorization: data.user.accessToken },
    });
    return response;
  }
);
export const fetchParent = createAsyncThunk(
  "users/fetchParent",
  async (data) => {
    console.log("Parent ID", data.parentId)
    const response = await client.get(`users/${data.parentId}`, {
      headers: { authorization: data.user.accessToken },
    });
    return response;
  }
);

export const fetchTrainers = createAsyncThunk(
  "users/fetchTrainers",
  async (data) => {
    const response = await client.get(`users?filter={"firstName":"${data.values.firstName}","lastName": "${data.values.lastName}", "role": "Trainer" }`, {
      headers: { authorization: data.user.accessToken },
    });
    return response.users;
  }
);
export const fetchTrainees = createAsyncThunk(
  "users/fetchTrainees",
  async (data) => {
    console.log(data.user.id)
    console.log(data.user.accessToken)
    const response = await client.get(`users?filter={"parentId":${data.user.id}}`, {
      headers: { authorization: data.user.accessToken },
    });
    console.log("response", response)
    return response;
  }
);

export const updateUser = createAsyncThunk("users/updateUser", async (data) => {
  console.log(data);
  const response = await client.put(`users/${data.user.id}`, data.values, {
    headers: { authorization: data.user.accessToken },
  });
  return response;
});

export const uploadProfileImage = (data) => async (dispatch) => {
  console.log("upload data: ", data.file);
  const body = {
    fileName: data.file.name,
    fileType: data.file.type,
  };
  const uploadResponse = await client.post(
    `users/${data.user.id}/profile-image`,
    body,
    {
      headers: { authorization: data.user.accessToken },
    }
  );
  const { upload } = uploadResponse;
  console.log("upload ", upload);

  if (!upload || !upload.uploadURL) {
    //dispatch error reducer here
  }

  const fileResponse = await fetch(upload.uploadURL, {
    method: "PUT",
    body: data.file,
    params: {
      Key: data.file.name,
      ContentType: data.file.type,
    },
    headers: {
      "Content-Type": data.file.type,
    },
  });

  dispatch(
    updateUser({
      user: data.user,
      values: {
        profileImgUrl: upload.url,
      },
    })
  );
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetUsers: (state) => initialState,
    resetErrors: (state) => initialState,
  },
  extraReducers: {
    [fetchUsers.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchUsers.fulfilled]: (state, action) => {
      return action.payload;
    },
    [fetchUsers.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
    },
    [createUser.pending]: (state, action) => {
      state.fetchStatus = "loading";
      state.error = action.payload;
    },
    [createUser.fulfilled]: (state, action) => {
      console.log("action payload", action.payload);
      state.fetchStatus = "succeeded";
      state.activeUser = action.payload.user;
    },
    [createUser.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.error.message;
    },
    [fetchTrainers.pending]: (state, action) => {
      state.fetchStatus = "loading";
      state.error = action.payload;
    },
    [fetchTrainers.fulfilled]: (state, action) => {
      console.log("action payload", action.payload);
      state.fetchStatus = "succeeded";
      state.trainers = action.payload;
    },
    [fetchTrainers.rejected]: (state, action) => {
      console.log("error")
      state.fetchStatus = "failed";
      state.error = action.error.message;
    },
    [fetchParent.pending]: (state, action) => {
      state.fetchStatus = "loading";
      state.error = action.payload;
    },
    [fetchParent.fulfilled]: (state, action) => {
      console.log("action payload", action.payload);
      state.fetchStatus = "succeeded";
      state.parent = action.payload;
    },
    [fetchParent.rejected]: (state, action) => {
      console.log("error")
      state.fetchStatus = "failed";
      state.error = action.error.message;
    },
    [fetchTrainees.pending]: (state, action) => {
      state.fetchStatus = "loading";
      state.error = action.payload;
    },
    [fetchTrainees.fulfilled]: (state, action) => {
      console.log("action payload", action.payload);
      state.fetchStatus = "succeeded";
      state.trainees = action.payload;
    },
    [fetchTrainees.rejected]: (state, action) => {
      console.log("error")
      state.fetchStatus = "failed";
      state.error = action.error.message;
    },

    [loginUser.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [loginUser.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.activeUser = action.payload.user;
    },
    [loginUser.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.error.message;
    },
    [updateUser.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [updateUser.fulfilled]: (state, action) => {
      console.log("Payload", action.payload)
      state.fetchStatus = "succeeded";
      state.activeUser = action.payload;
    },
    [updateUser.rejected]: (state, action) => {
      console.log("error", action.payload)
      state.fetchStatus = "failed";
      state.error = action.payload;
    },
    [logoutUser.fulfilled]: (state, action) => {
      state.activeUser = null;
    },

  },
});

export const { resetUsers, resetErrors } = usersSlice.actions;

export default usersSlice.reducer;
