import React, { useState } from "react";
import "../../App.css";
import ProfileHeader from "./ProfileHeader";
import { Container, Menu, Icon } from "semantic-ui-react";
import AboutMe from "./AboutMe";
import Horses from "../UserHorsesProfileContent/Horses"
import Trainees from "./Trainees"


const TrainerProfile = () => {
 
  const [activeItems, setActiveItems] = useState([
    {
      key: "rider",
      active: true,
      name: "Rider Profile",
      icon: "./Profile v1.svg",
    },
    {
      key: "horses",
      active: false,
      name: "My Horses",
      icon: "./Horse v1.svg",
    },
    // {
    //   key: "trainees",
    //   active: false,
    //   name: "Trainees",
    //   icon: "./Horse v1.svg",
    // },
  ]);
console.log("Active",activeItems);

  const handleClick = (index) => {
    setActiveItems((prevItems) =>
      prevItems.map((e, i) => ({ ...e, active: i === index }))
    );
  };

  const menu = activeItems.map((item, index) => {
    return (
      <>
        <a
          href
          key={index}
          onClick={() => handleClick(index)}
          className={
            item.active ? "event-nav-button-active" : "event-nav-button"
          }
        >
          {item.key === "trainees" ? (
            <Icon name="users" style={{ marginRight: "1rem" }} />
          ) : (
            <img key={index} className="event-nav-icon" src={item.icon} alt="" />
          )}
          <div style={{ height: "18px" }}>{item.name}</div>
        </a>
      </>
    );
  });

  return (
    <div className="main-content-cards">
      <ProfileHeader />
      <Container fluid>
        <div className="event-nav">{menu}</div>
        {activeItems.map((item, index) => {
          return item.key === "rider" && item.active === true ? (
            <AboutMe key={index} />
          ) : item.key === "horses" && item.active === true ? (
            <Horses key={index} />
            ) : item.key === "trainees" && item.active === true ? (
              <Trainees key={index} />
          ) : ("");
        })}
      </Container>
    </div>
  );


}

export default TrainerProfile

