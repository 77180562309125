import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Icon } from "semantic-ui-react";
import { uploadEventImage } from "../../features/events/eventsSlice";
import { fetchEvent } from "../../features/events/eventsSlice";
import { fetchTypes } from "../../features/eventTypes/eventTypesSlice";

import EditModal from "./EventEditForms/EditModal";
import MgrSingleType from "./MgrSingleType";

const MgrEventTypes = ({ eventId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTypes(eventId));
  }, []);
  
  const user = useSelector((state) => state.users.activeUser);
  const types2 = useSelector((state) => state.eventTypes.eventTypes.eventTypes);
  const event = useSelector((state) => state.events.event);
  const types = event.EventTypes;

  let card = "Types";
  return (
    <div>
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1
            style={{
              textAlign: "left",
              fontFamily: "Lato",
              fontSize: "30px",
              fontWeight: "900px",
            }}
          >
            <strong>
              Event Types
              <EditModal fluid event={event} card={card} />
            </strong>
            {!types || types.length === 0 ? (
              <strong style={{ color: "red", marginLeft: "1rem" }}>
                {"      "}*Required (1 or more)
              </strong>
            ) : (
              <Icon
                name="check circle"
                style={{ color: "green", marginLeft: "1rem" }}
              />
            )}
          </h1>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Competitive Events</strong>
          </h3>
          <br />

          {types2 &&
            types2.map((type, idx) =>
              type.isCompetitive === true ? (
                <MgrSingleType key={idx} type={type} event={event} />
              ) : null
            )}
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Add On & Schooling Events</strong>
          </h3>
          <br />

          {types2 &&
            types2.map((type, idx) =>
              type.isCompetitive === false ? (
                <MgrSingleType key={idx} type={type} event={event} />
              ) : null
            )}
          <br />
          <br />
        </Container>
      </Card>
    </div>
  );
};

export default MgrEventTypes;
