import React, { useState } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import EditBasicInfoForm from "./EditBasicInfoForm";
import EditManyTypesForm from "./EditManyTypesForm";
import EditHTorCTForm from "./EditHTorCTForm";
import EditDressageOnlyForm from "./EditDressageOnlyForm";
import EditDressageSchoolingForm from "./EditDressageSchoolingForm";
import EditSchoolingForm from "./EditSchoolingForm";
import EditLocationForm from "./EditLocationForm";
import EditEntryForm from "./EditEntryForm";
import EditOfficialsForm from "./EditOfficialsForm";
import EditCancellationForm from "./EditCancellationForm";
import EditDocsWaiversForm from "./EditDocsWaiversForm";
import EditOrganizerForm from "./EditOrganizerForm";
import EditStallsForm from "./EditStallsForm";
import EditCampingForm from "./EditCampingForm";
import EditScheduleForm from "./EditScheduleForm";

const EditModal = ({ event, card, type }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      size={card === "Location" || card === "Cancellation" ? "large" : "mini"}
      style={{ borderRadius: 10 }}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <Button
          floated="right"
          style={{ borderRadius: 25, color: "black", padding: 10 }}
        >
          <img
            style={{ marginRight: 5, marginBottom: 2 }}
            src="./Edit v1.svg"
            alt=""
          />
          Edit
        </Button>
      }
    >
      {card === "Info" && (
        <>
          <div style={{ margin: 25 }}>
            <h1 floated="left">
              Event Info
              <Button
                floated="right"
                style={{ borderRadius: 25, color: "black", padding: 10 }}
                onClick={handleClose}
              >
                <Icon name="cancel" />
                Cancel
              </Button>
            </h1>
            <EditBasicInfoForm handleClose={handleClose} event={event} />
          </div>
        </>
      )}
      {card === "Types" && (
        <>
          <div style={{ margin: 25 }}>
            <h1 floated="left">
              Event Types
              <Button
                floated="right"
                style={{ borderRadius: 25, color: "black", padding: 10 }}
                onClick={handleClose}
              >
                <Icon name="cancel" />
                Cancel
              </Button>
            </h1>
            <EditManyTypesForm handleClose={handleClose} event={event} />
          </div>
        </>
      )}
      {card === "Entry" && (
        <>
          <div style={{ margin: 25 }}>
            <h1 floated="left">
              Entry Info
              <Button
                floated="right"
                style={{ borderRadius: 25, color: "black", padding: 10 }}
                onClick={handleClose}
              >
                <Icon name="cancel" />
                Cancel
              </Button>
            </h1>
            <EditEntryForm handleClose={handleClose} event={event} />
          </div>
        </>
      )}
      {card === "SingleType" && (
        <>
          <div style={{ margin: 25 }}>
            <h2 floated="left" style={{ fontWeight: "bold" }}>
              <strong>
                {type.name === "Cross Country Schooling"
                  ? "XC Schooling"
                  : type.name}
              </strong>
              <Button
                floated="right"
                style={{ borderRadius: 25, color: "black", padding: 10 }}
                onClick={handleClose}
              >
                <Icon name="cancel" />
                Cancel
              </Button>
            </h2>
            {(type.name === "Horse Trial" ||
              type.name === "Combined Test" ||
              type.name === "Derby") && (
              <EditHTorCTForm
                handleClose={handleClose}
                type={type}
                event={event}
              />
            )}
            {type.name === "Dressage Only" && (
              <EditDressageOnlyForm
                handleClose={handleClose}
                type={type}
                event={event}
              />
            )}
            {type.name === "Dressage Tests" && (
              <EditDressageSchoolingForm
                handleClose={handleClose}
                type={type}
                event={event}
              />
            )}
            {type.isCompetitive === false && type.isDressage === false && (
              <EditSchoolingForm
                handleClose={handleClose}
                type={type}
                event={event}
              />
            )}
          </div>
        </>
      )}
      {card === "Schedule" && (
        <>
          <div style={{ margin: 25 }}>
            <h1 floated="left">
              Schedule
              <Button
                floated="right"
                style={{ borderRadius: 25, color: "black", padding: 10 }}
                onClick={handleClose}
              >
                <Icon name="cancel" />
                Cancel
              </Button>
            </h1>
            <EditScheduleForm handleClose={handleClose} event={event} />
          </div>
        </>
      )}
      {card === "Location" && (
        <>
          <div style={{ margin: 25 }}>
            <h1 floated="left">
              Location & Parking
              <Button
                floated="right"
                style={{ borderRadius: 25, color: "black", padding: 10 }}
                onClick={handleClose}
              >
                <Icon name="cancel" />
                Cancel
              </Button>
            </h1>
            <EditLocationForm handleClose={handleClose} event={event} />
          </div>
        </>
      )}
      {card === "Officials" && (
        <>
          <div style={{ margin: 25 }}>
            <h1 floated="left">
              Officials
              <Button
                floated="right"
                style={{ borderRadius: 25, color: "black", padding: 10 }}
                onClick={handleClose}
              >
                <Icon name="cancel" />
                Cancel
              </Button>
            </h1>
            <EditOfficialsForm handleClose={handleClose} event={event} />
          </div>
        </>
      )}
      {card === "Cancellation" && (
        <>
          <div style={{ margin: 25 }}>
            <h1 floated="left">
              Cancellation
              <Button
                floated="right"
                style={{ borderRadius: 25, color: "black", padding: 10 }}
                onClick={handleClose}
              >
                <Icon name="cancel" />
                Cancel
              </Button>
            </h1>
            <EditCancellationForm handleClose={handleClose} event={event} />
          </div>
        </>
      )}
      {card === "DocsWaivers" && (
        <>
          <div style={{ margin: 25 }}>
            <h1 floated="left">
              Documents
              <Button
                floated="right"
                style={{ borderRadius: 25, color: "black", padding: 10 }}
                onClick={handleClose}
              >
                <Icon name="cancel" />
                Cancel
              </Button>
            </h1>
            <EditDocsWaiversForm handleClose={handleClose} event={event} />
          </div>
        </>
      )}
      {card === "Stalls" && (
        <>
          <div style={{ margin: 25 }}>
            <h1 floated="left">
              Stalls
              <Button
                floated="right"
                style={{ borderRadius: 25, color: "black", padding: 10 }}
                onClick={handleClose}
              >
                <Icon name="cancel" />
                Cancel
              </Button>
            </h1>
            <EditStallsForm handleClose={handleClose} event={event} />
          </div>
        </>
      )}
      {card === "Camping" && (
        <>
          <div style={{ margin: 25 }}>
            <h1 floated="left">
              Camping
              <Button
                floated="right"
                style={{ borderRadius: 25, color: "black", padding: 10 }}
                onClick={handleClose}
              >
                <Icon name="cancel" />
                Cancel
              </Button>
            </h1>
            <EditCampingForm handleClose={handleClose} event={event} />
          </div>
        </>
      )}
      {card === "Organizer" && (
        <>
          <div style={{ margin: 25 }}>
            <h1 floated="left">
              Organizer
              <Button
                floated="right"
                style={{ borderRadius: 25, color: "black", padding: 10 }}
                onClick={handleClose}
              >
                <Icon name="cancel" />
                Cancel
              </Button>
            </h1>
            <EditOrganizerForm handleClose={handleClose} event={event} />
          </div>
        </>
      )}
    </Modal>
  );
};

export default EditModal;
