import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Dimmer, Loader } from "semantic-ui-react";
import EditAboutMeModal from "./EditAboutMeModal";
import EditTrainerModal from "./EditTrainerModal";
import EditSocialMediaModal from "./EditSocialMediaModal";
import EditAssociationsModal from "./EditAssociationsModal";
import EditAccountModal from "./EditAccountModal";
import { lastIndexOf } from "lodash";
import { uploadProfileImage } from "../../features/users/userSlice";
import { fetchUserRiders } from "../../features/eventRiders/eventRidersSlice";
import moment from "moment";
import "../../App.css";

const AboutMe = () => {
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const fetchStatus = useSelector((state) => state.users.fetchStatus);
  const riders = useSelector((state) => state.riders.riders);
  const events = useSelector((state) => state.events.events);
  console.log("riders====================>", riders);
  useEffect(() => {
    dispatch(fetchUserRiders({ user }));
  }, []);
  let riderEvent = {};

  const onFileChangeHandler = (event) => {
    const file = event.target.files[0];
    dispatch(uploadProfileImage({ user, file }));
  };

  return (
    <div>
      {fetchStatus === "loading" && (
        <Dimmer active page>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      {user && (
        <>
          <div className="event-subview-title-area">
            <h2>Rider Profile</h2>
          </div>
          <Card key={user.id} fluid className="card">
            <Container fluid className="profile-container">
              <h1
                style={{
                  textAlign: "left",
                  fontFamily: "Lato",
                  fontSize: "30px",
                  fontWeight: "900px",
                }}
              >
                <strong>
                  About Me
                  <EditAboutMeModal fluid />
                </strong>
              </h1>

              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Profile Image</strong>
              </h3>

              <label className="custom-file-upload" style={{ marginTop: 4 }}>
                <input
                  type="file"
                  name="file"
                  accept="image/png,image/gif,image/jpeg"
                  // style={{ float: "left" }}
                  onChange={onFileChangeHandler}
                />
                Upload Profile Image
              </label>
              <br />
              <br />
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>First Name</strong>
              </h3>
              <Card.Content style={{ textAlign: "left" }}>
                <p>{user.firstName}</p>
              </Card.Content>

              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Last Name</strong>
              </h3>
              <Card.Content style={{ textAlign: "left" }}>
                <p>{user.lastName}</p>
              </Card.Content>

              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Phone</strong>
              </h3>
              <Card.Content style={{ textAlign: "left" }}>
                <p>{user.phone}</p>
              </Card.Content>

              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Address</strong>
              </h3>
              <Card.Content style={{ textAlign: "left" }}>
                <p>{user.address}</p>
              </Card.Content>

              {/* <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                Profile Image
              </h3>
              <Card.Content style={{ textAlign: "left" }}>
                John1.jpg
              </Card.Content> */}

              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Age Group</strong>
              </h3>
              <Card.Content style={{ textAlign: "left" }}>
                <p>{user.ageGroup}</p>
              </Card.Content>
            </Container>
          </Card>

          <br />

          {user.trainer && (
            <>
              <Card key={user.id} fluid className="card">
                <Container fluid className="profile-container">
                  <h1 style={{ textAlign: "left" }}>
                    My Trainer
                    <EditTrainerModal fluid />
                  </h1>

                  <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    Trainer
                  </h3>
                  <Card.Content style={{ textAlign: "left" }}>
                    ‍Sarah Wilson (sarahwilson@gmail.com)
                  </Card.Content>
                </Container>
              </Card>

              <br />
            </>
          )}

          {riders &&
            riders.map((rider, idx) => {
              riderEvent = events.find((el) => el.id === rider.eventId);
              return (
                <>
                  <Card key={rider.id} fluid className="card">
                    <Container fluid className="profile-container">
                      <h1 style={{ textAlign: "left" }}>
                        {riderEvent ? (
                          <>
                            <strong>{riderEvent.name}</strong>
                            {riderEvent.endDate ? (
                              <>
                                {moment(riderEvent.endDate).isSameOrBefore(
                                  riderEvent.startDate
                                ) ? (
                                  <>
                                    <p>
                                      {moment(riderEvent.startDate).format(
                                        "MMMM D, YYYY"
                                      )}
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <p>
                                      {moment
                                        .utc(riderEvent.startDate)
                                        .format("MMMM D, YYYY")}
                                      -{" "}
                                      {moment
                                        .utc(riderEvent.endDate)
                                        .format("MMMM D, YYYY")}
                                    </p>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {moment(riderEvent.startDate).format(
                                  "MMMM D, YYYY"
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <strong>(Event not found)</strong>
                        )}
                      </h1>
                      <Card.Content style={{ textAlign: "left" }}>
                        {rider.events.competitive &&
                        rider.events.competitive.name !== null &&
                        rider.events.competitive.name !== "" ? (
                          <>
                            <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                              <strong>Competitive Event</strong>
                            </h3>
                            {rider.events.competitive.name !== "Dressage Only" ? (
                                  <>
                              <p>
                              {rider.events.competitive.name}: {rider.height}
                            </p>
                            </>
                              ): (
                                <>
                                  <p>
                              {rider.events.competitive.name}
                            </p>
                                </>
                              )}
                          </>
                        ) : (
                          <>
                            <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                              <strong>Not Competing</strong>
                            </h3>
                          </>
                        )}
                      </Card.Content>
                      <br />
                      <Card.Content style={{ textAlign: "left" }}>
                        <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                          <strong>Add-Ons & Schooling</strong>
                        </h3>
                        {rider.events.schooling &&
                        rider.events.schooling.length > 0 &&
                        rider.events.tests &&
                        rider.events.tests.length > 0 ? (
                          <p>
                            {rider.events.schooling.map((school, idx) => {
                              return <div key={idx}>{school}</div>;
                            })}
                            {rider.events.tests.map((test, idx) => {
                              return <div key={idx}>{test}</div>;
                            })}
                          </p>
                        ) : (
                          <p>No Additional Events.</p>
                        )}
                        <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                          <strong>Paid Receipt</strong>
                        </h3>
                        <div style={{ maxWidth: "450px" }}>
                          {rider.fees &&
                            rider.fees.map((fee, index) => {
                              return fee.name === "Total" ? (
                                <div
                                  key={index}
                                  className="line-item-container total"
                                >
                                  <strong>{fee.name}</strong>
                                  <strong>${fee.price}</strong>
                                </div>
                              ) : (
                                <div
                                  key={index}
                                  className="line-item-container"
                                >
                                  <div>{fee.name}</div>
                                  <div>${fee.price}</div>
                                </div>
                              );
                            })}
                        </div>
                      </Card.Content>
                    </Container>
                  </Card>

                  <br />
                </>
              );
            })}

          {/* <Card
            key={user.id}
            fluid
            className="card"
            style={{ borderRadius: 20 }}
          >
            <Container className="profile-container">
              <h1 style={{ textAlign: "left" }}>
                My Social Media
                <EditSocialMediaModal fluid />
              </h1>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>Instagram</h3>
              <Card.Content style={{ textAlign: "left" }}>
                {_.get(user, 'socialMedia["instagram"]', "N/A")}
              </Card.Content>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>Snapchat</h3>
              <Card.Content style={{ textAlign: "left" }}>
                {_.get(user, 'socialMedia["snapchat"]', "N/A")}
              </Card.Content>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>Facebook</h3>
              <Card.Content style={{ textAlign: "left" }}>
                {_.get(user, 'socialMedia["facebook"]', "N/A")}
              </Card.Content>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>Twitter</h3>
              <Card.Content style={{ textAlign: "left" }}>
                {_.get(user, 'socialMedia["twitter"]', "N/A")}
              </Card.Content>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>TikTok</h3>
              <Card.Content style={{ textAlign: "left" }}>
                {_.get(user, 'socialMedia["tiktok"]', "N/A")}
              </Card.Content>
            </Container>
          </Card>
          <br />
          <Card
            key={user.id}
            fluid
            className="card"
            style={{ borderRadius: 20 }}
          >
            <Container className="profile-container">
              <h1 style={{ textAlign: "left" }}>
                My Associations
                <EditAssociationsModal fluid />
              </h1>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                Associations & Member Number
              </h3>
              {user.UserAssociations && user.UserAssociations.length > 0 && (
                <Card.Content style={{ textAlign: "left" }}>
                  {user.UserAssociations.map((association) => {
                    return (
                      <p>
                        {association.Association.name} (
                        {association.Association.abbreviation})
                      </p>
                    );
                  })}
                </Card.Content>
              )}
              {(!user.UserAssociations ||
                user.UserAssociations.length === 0) && (
                <Card.Content style={{ textAlign: "left" }}>
                  No Associations
                </Card.Content>
              )}
            </Container>
          </Card>
          <br /> */}

          <Card key={user.id} fluid className="card">
            <Container fluid className="profile-container">
              <h1 style={{ textAlign: "left" }}>
                <strong>My Account</strong>
                <EditAccountModal fluid />
              </h1>

              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Email</strong>
              </h3>
              <Card.Content style={{ textAlign: "left" }}>
                <p>{user.email}</p>
              </Card.Content>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Password</strong>
              </h3>
              <Card.Content style={{ textAlign: "left" }}>
                ‍<p>**************</p>
              </Card.Content>
            </Container>
          </Card>

          <br />
        </>
      )}
    </div>
  );
};

export default AboutMe;