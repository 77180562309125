import React from "react";

const Scores = () => {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1>Scores</h1>
    </div>
  );
};

export default Scores;
