import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../api/client";

const initialState = {
  status: "",
  fetchStatus: "",
  error: "",
  rider: {},
};

//Uncertain on the post route for our eventRider

export const createRider = createAsyncThunk(
  "riders/createRider",
  async (data) => {
    const response = await client.post("event-riders", data.values, {
      headers: { authorization: data.user.accessToken },
    });
    return response;
  }
);
export const deleteRider = createAsyncThunk(
  "riders/deleteRider",
  async (data) => {
    const response = await client.delete(
      `event-riders/${data.riderId}`,
      data.rider,
      {
        headers: { authorization: data.user.accessToken },
      }
    );
    console.log(response.message);
    console.log(response);
    return response;
  }
);

export const updateRider = createAsyncThunk(
  "riders/updateRider",
  async (data) => {
    const response = await client.put(
      `event-riders/${data.riderId}`,
      data.values,
      {
        headers: { authorization: data.user.accessToken },
      }
    );
    console.log(response);
    return response;
  }
);
export const fetchRiders = createAsyncThunk(
  "riders/fetchRiders",
  async (data) => {
    const response = await client.get(
      `event-riders?filter={"eventId":${data.event.id},"paymentStatus":"succeeded"}&page=${data.page}&limit=10`
    );

    return response.eventRiders;
  }
);
export const fetchUserRiders = createAsyncThunk(
  "riders/fetchUserRiders",
  async (data) => {
    const response = await client.get(
      `event-riders?filter={"userId":${data.user.id},"paymentStatus":"succeeded"}&limit=30`
    );

    return response.eventRiders;
  }
);

const ridersSlice = createSlice({
  name: "riders",
  initialState,
  reducers: {
    resetRider: (state) => initialState,
  },
  extraReducers: {
    [createRider.pending]: (state, action) => {
      state.fetchStatus = "loading";
      state.error = action.payload;
    },
    [createRider.fulfilled]: (state, action) => {
      console.log("action payload", action.payload);
      state.fetchStatus = "succeeded";
      state.rider = action.payload;
    },
    [createRider.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.error.message;
    },
    [deleteRider.pending]: (state, action) => {
      state.fetchStatus = "loading";
      state.error = action.payload;
    },
    [deleteRider.fulfilled]: (state, action) => {
      console.log("action payload", action.message);
      state.fetchStatus = "succeeded";
      console.log(state.fetchStatus);
    },
    [deleteRider.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.error.message;
    },
    [updateRider.pending]: (state, action) => {
      state.fetchStatus = "loading";
      state.error = action.payload;
    },
    [updateRider.fulfilled]: (state, action) => {
      console.log("action payload", action.payload);
      state.fetchStatus = "succeeded";
      state.rider = action.payload;
    },
    [updateRider.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.error.message;
    },
    [fetchRiders.pending]: (state, action) => {
      state.fetchStatus = "loading";
      state.error = action.payload;
    },
    [fetchRiders.fulfilled]: (state, action) => {
      console.log("action payload", action.payload);
      state.fetchStatus = "succeeded";
      state.riders = [...action.payload];
    },
    [fetchRiders.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.error.message;
    },
    [fetchUserRiders.pending]: (state, action) => {
      state.fetchStatus = "loading";
      state.error = action.payload;
    },
    [fetchUserRiders.fulfilled]: (state, action) => {
      console.log("action payload", action.payload);
      state.fetchStatus = "succeeded";
      state.riders = [...action.payload];
    },
    [fetchUserRiders.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.error.message;
    },
  },
});

export const { resetRider } = ridersSlice.actions;

export default ridersSlice.reducer;
