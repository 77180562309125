import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Header, Dimmer, Loader } from "semantic-ui-react";
import { Formik } from "formik";
import {
  createEvent,
  resetEvents,
  fetchMgrEvents,
} from "../../features/events/eventsSlice";
import { Redirect } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CreateEventForm = ({ handleClose }) => {
  const dispatch = useDispatch();
  const createStatus = useSelector((state) => state.events.createStatus);
  const user = useSelector((state) => state.users.activeUser);
  const email = user.email;



  return (
    <section>
      {createStatus === "loading" && (
        <Dimmer active page>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      {createStatus === "succeeded" ? <Redirect to="/manager-edit" /> : null}
      <br />
      <Formik
        initialValues={{
          eventOrganizerEmail: user.email,
          eventOrganizerName: user.firstName + " " + user.lastName,
          name: null,
          startDate: null,
          endDate: null,
          description: "*Please enter this full-length description with Edit",
          location: null,
          combinedEvents: [],
          officials: [],
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {

          dispatch(createEvent({ user, values }))
            .then(() => dispatch(resetEvents()))
            .then(() => dispatch(fetchMgrEvents(email)));
          setSubmitting(createStatus === "loading");
          handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Event Name
            </Header>
            {values.name == null && (
              <Header
                as="h4"
                floated="right"
                style={{ marginBottom: 2, color: "red" }}
              >
                *Required
              </Header>
            )}

            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="name"
              type="name"
              onChange={handleChange}
              placeholder="Event Name"
              id="name"
              value={values.name}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Start Date
            </Header>
            {values.startDate == null && (
              <Header
                as="h4"
                floated="right"
                style={{ marginBottom: 2, color: "red" }}
              >
                *Required
              </Header>
            )}

            <DatePicker
              selected={values.startDate}
              style={{ borderRadius: 10 }}
              dateFormat="MMMM d, yyyy"
              name="startDate"
              onChange={(date) => setFieldValue("startDate", date)}
            />
            <br />
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              End Date
            </Header>
            {values.endDate == null && (
              <Header
                as="h4"
                floated="right"
                style={{ marginBottom: 2, color: "red" }}
              >
                *Required
              </Header>
            )}
            <div>
              <DatePicker
                style={{ borderRadius: 10 }}
                selected={values.endDate}
                dateFormat="MMMM d, yyyy"
                name="endDate"
                onChange={(date) => setFieldValue("endDate", date)}
              />
            </div>

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              City, State
            </Header>
            {values.location == null && (
              <Header
                as="h4"
                floated="right"
                style={{ marginBottom: 2, color: "red" }}
              >
                *Required
              </Header>
            )}

            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="location"
              type="location"
              onChange={handleChange}
              placeholder="City, State"
              id="location"
              value={values.location}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,
                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Save & Close
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default CreateEventForm;
