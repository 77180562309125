import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Button, Form, Header } from "semantic-ui-react";
// import { unwrapResult } from "@reduxjs/toolkit";

import { Formik } from "formik";

import { updateUser } from "../../features/users/userSlice";
import { fetchTrainers } from "../../features/users/userSlice";
import { fetchParent } from "../../features/users/userSlice";


const TrainerForm = (props) => {
  const [trainerShow, setTrainerShow] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const trainers = useSelector((state)=> state.users.trainers);
  const parent = useSelector(state => state.users.parent);


  const handleTrainer = (e, {user, trainer}) => {
    let parentId = trainer.id;
    let values = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      barnName: trainer.barnName,
      parentId: trainer.id,
    };
    console.log(trainer.id);
    dispatch(updateUser({user, values})).then((res)=> window.location.reload());
    console.log("parent???", parent);
    props.handleClose();
  }
  return (
    <section>
      <br />
      <Formik
        initialValues={{
          user: user.trainer,
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          dispatch(fetchTrainers({ user, values })).then(()=>setTrainerShow(true));
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              First Name
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="firstName"
              type="firstName"
              onChange={handleChange}
              placeholder="First Name"
              id="firstName"
              value={values.firstName}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Last Name
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="lastName"
              type="lastName"
              onChange={handleChange}
              placeholder="Last Name"
              id="lastName"
              value={values.lastName}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            {trainerShow === true ? 
              (<>
                {trainers.map((trainer, idx)=> 
                    <div key={idx}>
                      <Card fluid>
                      <Card.Content>

                       <Header as="h4" style={{ marginBottom: 2 }}>
                          Name: {trainer.firstName} {" "} {trainer.lastName}
                        </Header>
                
                        <Header as="h4" style={{ marginBottom: 2 }}>
                          Email: {trainer.email}
                        </Header>
                        <Header as="h4" style={{ marginBottom: 2 }}>
                         Barn Name: {trainer.barnName ? trainer.barnName : "No Barn Name"}
                        </Header>
                        <br/>
                        <Button
                          style={{
                            backgroundColor: "#ffdea2",
                            height: 50,

                            borderRadius: 10,
                          }}
                          fluid
                          type="button"
                          onClick={(e)=> handleTrainer(e, {user, trainer})}
                        >
                          Select
                        </Button>
                      </Card.Content>
                      </Card>
                    </div>
                  )}
              </>) : (
                  <>  
                    <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                        Please search for your Trainer above...
                     </Header>
                    <br/>
                  <br/>
                  <br/>
                  </>
              )}
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Look Up
            </Button>
            {/* {newTrainers && newTrainers.map((trainer, idx)=> {
              <div key={idx}>
              <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              {trainer.firstName} + {" "} + {trainer.lastName}
              </Header>
              <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              {trainer.email}
              </Header>
              <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              {trainer.barnName}
              </Header>

              </div>
            })}  */}


          </Form>
        )}
      </Formik>
    </section>
  );
};

export default TrainerForm;
