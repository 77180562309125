import React from "react";

const Times = () => {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1>Times</h1>
    </div>
  );
};

export default Times;
