import React, { useState } from "react";
import "../../../App.css";
import Button from "./Button";
import { Form, Card, Container } from "semantic-ui-react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createRider } from "../../../features/eventRiders/eventRidersSlice";
import _ from "lodash";
import { current } from "@reduxjs/toolkit";

const SelectForm = ({ nextStep, event, menu }) => {
  const [schoolingIds, setSchoolingIds] = useState([]);
  const [complete, setComplete] = useState(false);
  const [dressageBtn, setDressageBtn] = useState(false);
  const [competitiveDisc, setCompetitiveDisc] = useState(false);
  const [testPrice, setTestPrice] = useState(0);
  const [numOptions, setNumOptions] = useState(0);
  const [maxTests, setMaxTest] = useState([]);
  const [schoolingShow, setSchoolingShow] = useState(false);
  const [compShow, setCompShow] = useState(true);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [heightOpen, setHeightOpen] = useState(false);
  const [heightOptions, setHeightOptions] = useState([]);
  const [competitiveObject, setCompetitiveObject] = useState({});
  const [testsLength, setTestsLength] = useState();
  const [feesLength, setFeesLength] = useState();

  const user = useSelector((state) => state.users.activeUser);

  //-------------------------------------------pull association between height & dressageTest
  const [dressageTests, setDressageTests] = useState([]);
  const dispatch = useDispatch();

  const types = event.EventTypes;
  const handleSelectChange = (evt, data) => {
    setNumOptions(data.value);
  };
  const dropdownMaxTests = (num) => {
    setMaxTest(
      [...Array(num + 1)].map(
        (e, index) =>
          index !== 0 && {
            key: index,
            text: index,
            value: index,
          }
      )
    );
  };

  const handleCompChange = (e, { values }, type, { types }) => {
    if (!_.isEmpty(competitiveObject)) {
      values.events.competitive.name = type.name;
      values.events.competitive.combined = types.filter(
        (el) => el.parentId === type.id
      );
      let index;

      for (var i = 0; i < values.fees.length; i++) {
        let { name, price } = values.fees[i];
        if (
          name === competitiveObject.name &&
          price === competitiveObject.entryFee
        ) {
          index = i;
        }
      }

      const newSelection = { name: type.name, price: type.entryFee };
      values.fees.splice(index, 1, newSelection);
      setCompetitiveObject(type);
      setDressageBtn(true);
      setCompetitiveDisc(true);
      setComplete(false);
      setHeightOpen(true);
      setDropDownOpen(false);
      setSchoolingShow(false);

      setHeightOptions(
        type.height
          .filter((el) => el.test !== "Not Offered")
          .map((el) => ({
            key: el.height,
            text: el.height,
            value: el.height,
            test: el.test,
          }))
      );

      return;
    }

    values.events.competitive.name = type.name;
    setCompetitiveObject(type);
    values.events.competitive.combined = types.filter(
      (el) => el.parentId === type.id
    );
    values.fees = [...values.fees, { name: type.name, price: type.entryFee }];
    setDressageBtn(true);
    setCompetitiveDisc(true);
    setComplete(false);
    setHeightOpen(true);
    setDropDownOpen(false);
    setSchoolingShow(false);

    setHeightOptions(
      type.height
        .filter((el) => el.test !== "Not Offered")
        .map((el) => ({
          key: el.height,
          text: el.height,
          value: el.height,
          test: el.test,
        }))
    );
    setTestsLength(values.events.tests.length);
    setFeesLength(values.fees.length);
  };

  const handleDressageChange = (e, { values }, type, { types }) => {
    if (!_.isEmpty(competitiveObject)) {
      values.events.competitive.name = type.name;
      values.events.competitive.combined = types.filter(
        (el) => el.parentId === type.id
      );
      let index;
      let compIndex;
      for (var i = 0; i < values.fees.length; i++) {
        let { name, price } = values.fees[i];
        if (
          name === competitiveObject.name &&
          price === competitiveObject.entryFee
        ) {
          index = i;
        }
      }

      const newSelection = { name: type.name, price: type.entryFee };
      values.fees.splice(index, 1, newSelection);
      setCompetitiveObject(type);
      setDressageBtn(false);
      setCompetitiveDisc(true);
      setHeightOpen(false);
      setDropDownOpen(true);
      setSchoolingShow(true);

      if (type.tests !== null) {
        setDressageTests(
          type.tests.map((test, index) => ({
            key: test,
            text: test,
            value: test,
          }))
        );
      }
      dropdownMaxTests(type.maxTests);
      return;
    }

    values.events.competitive.name = type.name;
    setCompetitiveObject(type);
    values.events.competitive.combined = types.filter(
      (el) => el.parentId === type.id
    );

    setDressageBtn(false);
    setCompetitiveDisc(true);
    setHeightOpen(false);
    setDropDownOpen(true);
    setSchoolingShow(true);
    setCompShow(false);
    setComplete(true);
    if (type.tests !== null) {
      setDressageTests(
        type.tests.map((test, index) => ({
          key: test,
          text: test,
          value: test,
        }))
      );
    }
    setTestsLength(values.events.tests.length);
    setFeesLength(values.fees.length);

    dropdownMaxTests(type.maxTests);
  };

  const handleNoCompClick = ({ values }) => {
    setCompetitiveDisc(false);
    setCompShow(false);
    setHeightOpen(false);
    setDropDownOpen(true);
    setDropDownOpen(false);
    setSchoolingShow(true);
    setDressageBtn(true);
    setComplete(false);
    values.height = "";
    values.fees = [...event.fees.filter((el) => el.name !== "Late")];
    values.events.competitive.name = "";
    values.events.competitive.combined = [];
    setCompetitiveObject({});
    setTestsLength(values.events.tests.length);
    setFeesLength(values.fees.length);
  };

  const handleDressageAddBtn = (type) => {
    setDropDownOpen(true);
    setTestPrice(type.perTestFee);
    dropdownMaxTests(type.maxTests);
    setDressageTests(
      type.tests.map((test, index) => ({
        key: test,
        text: test,
        value: test,
      }))
    );
  };
  const handleSchoolingClick = (e, { values }, type) => {
    if (schoolingIds.some((el) => el === type.id)) {
      let idx = values.fees.findIndex((el) =>
        competitiveDisc === true
          ? el.name === type.name && el.price === type.entryFee
          : el.name === type.name && el.price === type.entryFeeNonCompetitive
      );
      let idx2 = values.events.schooling.findIndex((el) => el === type.name);
      values.fees.splice(idx, 1);
      values.events.schooling.splice(idx2, 1);
      let newArr = schoolingIds.filter((el) => el !== type.id);

      setSchoolingIds(newArr);
    } else {
      setComplete(true);
      setSchoolingIds([...schoolingIds, type.id]);
      values.events.schooling = [...values.events.schooling, type.name];
      competitiveDisc === true
        ? (values.fees = [
            ...values.fees,
            { name: type.name, price: type.entryFee },
          ])
        : (values.fees = [
            ...values.fees,
            { name: type.name, price: type.entryFeeNonCompetitive },
          ]);
    }
    setTestsLength(values.events.tests.length);
    setFeesLength(values.fees.length);
  };
  const handleTest = (val, { values }) => {
    setComplete(true);
    setSchoolingShow(true);
    setCompShow(false);
    values.height = val;
    heightOptions.filter((el) =>
      el.key === val
        ? (values.events.tests = [...values.events.tests, el.test])
        : ""
    );
    setTestsLength(values.events.tests.length);
    setFeesLength(values.fees.length);
  };
  const handleDropdownTest = (event, val, index, { values }) => {
    console.log();
    setComplete(true);
    let currentTest = testsLength + index;
    let currentFee = feesLength + index;
    values.events.tests[currentTest] = val;
    values.fees[currentFee] = { name: val, price: testPrice };
  };
  // const handleTestChange = (evt, data) => {};

  return (
    <Card fluid className="entry-card">
      <Container fluid className="profile-container">
        <div className="main-content-cards">
          <h1 className="pearl-text big regular">Event Registration</h1>
          <div className="event-nav">{menu}</div>
          <div className="registration-step-area">
            <div className="walkthrough-max-width">
              <Formik
                initialValues={{
                  height: "",
                  events: {
                    competitive: {
                      name: null,
                      combined: [],
                    },
                    schooling: [],
                    tests: [],
                  },

                  fees: [...event.fees.filter((el) => el.name !== "Late")],
                  barnName: null,
                  shavings: null,
                  waiverUrl: null,
                  paymentReciept: null,
                  paymentStatus: null,
                  horseId: null,
                  noRv: null,
                  userId: user.id,
                  eventId: event.id,
                }}
                onSubmit={(values) => {
                  dispatch(createRider({ user, values }))
                    .then((res) => {
                      nextStep(1);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="field-label big">
                      Select Your Competitive Event
                    </div>
                    {types &&
                      types.map((type, index) => {
                        return (
                          type.isCompetitive === true &&
                          type.isDressage !== true && (
                            <div key={index}>
                              <button
                                className={
                                  values.events.competitive.name === type.name
                                    ? "option-field-selected"
                                    : "option-field"
                                }
                                type="button"
                                name={type.name}
                                onClick={(e) =>
                                  handleCompChange(e, { values }, type, {
                                    types,
                                  })
                                }
                              >
                                <div>{type.name}</div>
                                <div>${type.entryFee}</div>
                              </button>
                            </div>
                          )
                        );
                      })}
                    {types &&
                      types.map((type, index) => {
                        return (
                          type.isCompetitive &&
                          type.isDressage === true && (
                            <div key={index}>
                              <button
                                className={
                                  values.events.competitive.name === type.name
                                    ? "option-field-selected"
                                    : "option-field"
                                }
                                type="button"
                                name={type.name}
                                onClick={(e) => {
                                  handleDressageAddBtn(type);
                                  handleDressageChange(e, { values }, type, {
                                    types,
                                  });
                                }}
                              >
                                <div>{type.name}</div>
                                <div>${type.perTestFee} / Test</div>
                              </button>
                            </div>
                          )
                        );
                      })}
                    <button
                      className={
                        values.events.competitive.name === ""
                          ? "option-field-selected"
                          : "option-field"
                      }
                      type="button"
                      name="event"
                      onClick={(e) => handleNoCompClick({ values })}
                    >
                      <div>Not Competing</div>
                    </button>
                    <br />
                    {/* --------------------------------height dropdown, assigns a dressage test */}
                    {heightOpen === true && (
                      <>
                        <div className="field-label big">
                          Select Your Height
                        </div>
                        <Form.Select
                          fluid
                          style={{
                            height: 50,
                            borderRadius: 10,
                            lineHeight: "22px",
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                          placeholder="Select Height"
                          id="height"
                          name="height"
                          onChange={(event, field) => {
                            setComplete(true);
                            handleTest(field.value, { values });
                          }}
                          options={heightOptions}
                        />
                        <br />
                      </>
                    )}

                    {schoolingShow === true && (
                      <>
                        <div className="field-label big">
                          Add Schooling Events
                        </div>

                        {types &&
                          types.map((type, index) => {
                            return (
                              type.isCompetitive === false &&
                              (type.isDressage === true ? (
                                dressageBtn === true && (
                                  <div key={index}>
                                    <Button
                                      onClick={(e) =>
                                        handleDressageAddBtn(type)
                                      }
                                    >
                                      <div>Add {type.name}</div>
                                      <div>+ ${type.perTestFee}</div>
                                    </Button>
                                  </div>
                                )
                              ) : (
                                <div key={index}>
                                  <Button
                                    name={type.name}
                                    onClick={(e) =>
                                      handleSchoolingClick(e, { values }, type)
                                    }
                                  >
                                    <div>Add {type.name}</div>

                                    {competitiveDisc === true ? (
                                      <div>+ ${type.entryFee}</div>
                                    ) : (
                                      <div>
                                        + ${type.entryFeeNonCompetitive}
                                      </div>
                                    )}
                                  </Button>
                                </div>
                              ))
                            );
                          })}
                        <br />
                      </>
                    )}
                    {dropDownOpen === true && (
                      <>
                        <div className="field-label big">
                          Number of Dressage Tests
                        </div>
                        <Form.Select
                          fluid
                          style={{
                            height: 50,
                            borderRadius: 10,
                            lineHeight: "22px",
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                          placeholder="Choose Number of Tests"
                          text={numOptions}
                          id="numTests"
                          name="numTests"
                          onChange={handleSelectChange}
                          options={maxTests}
                        />
                        {numOptions !== 0 &&
                          [...Array(numOptions)].map((e, index) => (
                            <div key={index}>
                              <br />
                              <div className="field-label big">
                                Select Dressage Test {index + 1}
                              </div>
                              <Form.Select
                                fluid
                                style={{
                                  height: 50,
                                  borderRadius: 10,
                                  lineHeight: "22px",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                                placeholder="Select Dressage Test"
                                id="numTests"
                                name="numTests"
                                onChange={(event, field) => {
                                  handleDropdownTest(
                                    event,
                                    field.value,
                                    index,
                                    {
                                      values,
                                    }
                                  );
                                }}
                                options={dressageTests}
                              />
                            </div>
                          ))}
                      </>
                    )}
                    {complete ? (
                      <button
                        className="submit-button"
                        // type="button"
                        // onClick={() => nextStep(1)}
                        type="submit"
                      >
                        Continue
                      </button>
                    ) : (
                      <button
                        className="submit-button"
                        type="button"
                        style={{ backgroundColor: "#D3D3D3" }}
                        disabled
                      >
                        Please Complete Form
                      </button>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </Card>
  );
};

export default SelectForm;
