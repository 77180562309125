import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import "./ProfileContent.scss";
import { Image, Container, Header } from "semantic-ui-react";
import { Redirect } from "react-router-dom";

//profileSlice for avatar and name?

const ProfileHeader = () => {
  const user = useSelector((state) => state.users.activeUser);
  const userLoggedIn = user && user.accessToken;
  const profileImgUrl = _.get(user, "profileImgUrl", "Profile Placeholder.svg");
  return (
    <>
      {userLoggedIn && (
        <div>
          <Container fluid>
            <br />
            <br />
            {profileImgUrl ? (
              <Image
                src={profileImgUrl}
                centered
                className="profile-image"
                alt=""
              />
            ) : (
              <Image
                src={"/Profile Placeholder.svg"}
                centered
                className="profile-image"
                alt="User Profile Image"
              />
            )}
            <div className="profile-type">{user.role}</div>
            <h1
              style={{
                fontSize: "44px",
                fontWeight: 900,
                margin: "10px 0 20px 0",
              }}
            >
              {user.firstName} {user.lastName}
            </h1>
            <br />
          </Container>
        </div>
      )}
      {!userLoggedIn && (
        <>
          <Redirect to="/login" />
        </>
      )}
    </>
  );
};

export default ProfileHeader;
