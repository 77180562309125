import React from "react";
import MgrEventInfo from "../EventEdit/MgrEventInfo";
import MgrEventTypes from "../EventEdit/MgrEventTypes";
import MgrSchedule from "../EventEdit/MgrSchedule";
import MgrLocation from "../EventEdit/MgrLocation";
import MgrEntryInfo from "../EventEdit/MgrEntryInfo";
import MgrOfficials from "../EventEdit/MgrOfficials";
import MgrCancellation from "../EventEdit/MgrCancellation";
import MgrDocsWaivers from "../EventEdit/MgrDocsWaivers";
import MgrStalls from "../EventEdit/MgrStalls";
import MgrCampingRVs from "../EventEdit/MgrCampingRVs";
import MgrOrganizer from "../EventEdit/MgrOrganizer";

const EventSetup = ({ eventId }) => {
  return (
    <>
      <div className="event-subview-title-area">
        <h2>Event Information</h2>
      </div>
      <MgrEventInfo eventId={eventId} />
      <MgrEventTypes eventId={eventId} />
      <MgrSchedule eventId={eventId} />
      <MgrLocation eventId={eventId} />
      <MgrEntryInfo eventId={eventId} />
      <MgrOfficials eventId={eventId} />
      <MgrCancellation eventId={eventId} />
      <MgrDocsWaivers eventId={eventId} />
      <MgrStalls eventId={eventId} />
      <MgrCampingRVs eventId={eventId} />
      <MgrOrganizer eventId={eventId} />
    </>
  );
};

export default EventSetup;
