import React, { useState } from "react";
import { Grid, Image, Form, Button, Icon, Header } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";
import SentEmailDialogue from "./SentEmailDialogue";
import ForgotPasswordDialogue from "./ForgotPasswordDialogue";
import { client } from "../api/client";

import { Formik } from "formik";

const ForgotPassword = () => {
  const [sentEmail, setSentEmail] = useState(false);
  return (
    <div>
      <br />
      <br />

      {!sentEmail && (
        <Grid textAlign="center">
          <Grid.Column style={{ maxWidth: 355 }}>
            <img
              className="pearl-icon-large"
              src="./illustratorPEARLB.png"
              alt=""
            />
            <br />
            <ForgotPasswordDialogue />

            <br />

            <Formik
              initialValues={{ email: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                const clientUrl = window.location.href.replace(
                  "forgot-password",
                  "update-password"
                );
                console.log("Client URL: ", clientUrl);
                const response = await client.post("authn/password/reset", {
                  email: values.email,
                  clientUrl,
                });

                setSentEmail(true);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <br />
                  <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                    Email Address*
                  </Header>
                  <Form.Input
                    style={{ height: 50 }}
                    iconPosition="left"
                    placeholder="E-mail address"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  >
                    <Icon className="user" />
                    <input style={{ borderRadius: 10 }} />
                  </Form.Input>
                  {errors.email && touched.email && errors.email}

                  <br />
                  <Button
                    style={{
                      backgroundColor: "#ffdea2",
                      height: 50,
                      borderRadius: 10,
                    }}
                    fluid
                    black
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </Grid.Column>
        </Grid>
      )}
      {sentEmail && (
        <Grid textAlign="center" style={{ height: "120vh" }}>
          <Grid.Column style={{ maxWidth: 355 }}>
            <img
              className="pearl-icon-large"
              src="./illustratorPEARLB.png"
              alt=""
            />
            <br />
            <SentEmailDialogue />
            <br />
          </Grid.Column>
        </Grid>
      )}
    </div>
  );
};

export default ForgotPassword;
