import React from "react";
import { Container, Grid, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import PearlErrorDialogue from "../ErrorBoundary/PearlErrorDialogue";
import "../App.css";

const Error = () => {
  return (
    <div>
      <Container fluid>
        <br />
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 375 }}>
            <img
              className="pearl-icon-large"
              src="./illustratorPEARLB.png"
              alt=""
            />
            <br />
            <PearlErrorDialogue />
            <br />

            <Header as="h4">
              <div>
                <Link to="./">Back Home</Link>
              </div>
            </Header>
            <br />
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
};

export default Error;
