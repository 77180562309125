import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { client } from "../../api/client";

const initialState = {
  status: "",
  fetchStatus: "",
  createStatus: "",
  error: "",
  eventTypes: [{}],
};

//uncertain about query eventId

export const fetchTypes = createAsyncThunk(
  "types/fetchTypes",
  async (eventId) => {
    const response = await client.get(
      `event-types?filter={"eventId":${eventId}}`
    );
    return response;
  }
);

export const createEventType = createAsyncThunk(
  "types/createEventType",
  async (data) => {
    console.log("data", data);
    const response = await client.post(`event-types`, data.values, {
      headers: { authorization: data.user.accessToken },
    });
    console.log(response);
    return response;
  }
);
export const updateEventType = createAsyncThunk(
  "types/updateEventType",
  async (data) => {
    console.log("data", data);
    const response = await client.put(
      `event-types/${data.typeId}`,
      data.values,
      {
        headers: { authorization: data.user.accessToken },
      }
    );
    console.log(response);
    return response;
  }
);
export const deleteEventType = createAsyncThunk(
  "types/updateEventType",
  async (data) => {
    console.log("data", data);
    const response = await client.delete(
      `event-types/${data.typeId}`,
      {
        headers: { authorization: data.user.accessToken },
      }
    );
    console.log(response);
    return response;
  }
);
const eventTypesSlice = createSlice({
  name: "types",
  initialState,
  reducers: {},
  extraReducers: {
    [createEventType.pending]: (state, action) => {
      state.createStatus = "loading";
      state.error = action.payload;
    },
    [createEventType.fulfilled]: (state, action) => {
      console.log("action payload", action.payload);
      state.createStatus = "succeeded";
      state.eventTypes = action.payload;
    },
    [createEventType.rejected]: (state, action) => {
      console.log(action.error.message);
      state.createStatus = "failed";
      state.error = action.error.message;
    },
    [updateEventType.pending]: (state, action) => {
      state.createStatus = "loading";
      state.error = action.payload;
    },
    [updateEventType.fulfilled]: (state, action) => {
      console.log("action payload", action.payload);
      state.createStatus = "succeeded";
      state.eventTypes = action.payload;
    },
    [updateEventType.rejected]: (state, action) => {
      console.log(action.error.message);
      state.createStatus = "failed";
      state.error = action.error.message;
    },
    [deleteEventType.pending]: (state, action) => {
      state.error = action.payload;
    },
    [deleteEventType.fulfilled]: (state, action) => {
      console.log("action payload", action.payload);
      state.eventTypes = action.payload;
    },
    [deleteEventType.rejected]: (state, action) => {
      console.log(action.error.message);
      state.error = action.error.message;
    },
    [fetchTypes.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchTypes.fulfilled]: (state, action) => {
      console.log("fulfilled payload", action.payload);
      state.fetchStatus = "succeeded";
      state.eventTypes = action.payload;
    },
    [fetchTypes.rejected]: (state, action) => {
      console.log("action payload", action.payload);
      state.fetchStatus = "failed";
      state.error = action.payload;
    },
  },
});

export default eventTypesSlice.reducer;
