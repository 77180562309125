import React, { useState, useEffect } from "react";
import EventRegMenu from "./EventRegMenu";
import CompetitorsTable from "./CompetitorsTable";
import { Card, Container } from "semantic-ui-react";
import "../../../App.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchHorses } from "../../../features/horses/horsesSlice";
import { fetchRiderStalls } from "../../../features/stalls/stallsSlice";
import { fetchRiderRVs } from "../../../features/RVs/RVsSlice";

import moment from "moment";

const EntryComplete = ({ event, nextStep, menu }) => {
  const dispatch = useDispatch();
  const [signUpAgain, setSignUpAgain] = useState(false);
  const user = useSelector((state) => state.users.activeUser);
  const rider = useSelector((state) => state.riders.rider);
  const horses = useSelector((state) => state.horses.userHorses);
  const campings = useSelector((state) => state.RVs.RVs);
  const stalls = useSelector((state) => state.stalls.stalls);

  console.log("campings=======>", campings);
  console.log("stalls=======>", stalls);

  // useEffect(() => {
  //   dispatch(fetchRiderStalls({ event, rider }));
  //   dispatch(fetchRiderRVs({ event, rider }));
  //   dispatch(fetchHorses(user));
  // }, []);

  if (!rider) {
    return (
      <>
        <p>Redirecting you to Payment Form</p>
        {console.log("rider info:", rider)}
        {nextStep(5)}
      </>
    );
  }
  const handleClick = () => {
    setSignUpAgain(true);
  };
  return (
    <>
      {signUpAgain && <EventRegMenu event={event} />}
      {!signUpAgain && (
        <div>
          <Card fluid className="entry-card">
            <Container fluid className="profile-container">
              <h1 style={{ textAlign: "left" }}>
                <strong>Entry Complete: {event.name}</strong>
              </h1>
              <Card.Content style={{ textAlign: "left" }}>
                {rider.events.competitive.name !== null &&
                rider.events.competitive.name !== "" ? (
                  <>
                    <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                      <strong>Competitive Event</strong>
                    </h3>
                    {rider.events.competitive.name !== "Dressage Only" ? (
                      <>
                        <p>
                          {rider.events.competitive.name}: {rider.height}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>{rider.events.competitive.name}</p>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                      <strong>Not Competing</strong>
                    </h3>
                  </>
                )}
              </Card.Content>
              <br />
              <Card.Content style={{ textAlign: "left" }}>
                <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                  <strong>Details</strong>
                </h3>
                {rider.events.schooling.length > 0 ||
                rider.events.tests.length > 0 ? (
                  <p>
                    {rider.events.schooling.map((school, idx) => {
                      return <div key={idx}>{school}</div>;
                    })}
                    {rider.events.tests.map((test, idx) => {
                      return <div key={idx}>{test}</div>;
                    })}
                  </p>
                ) : (
                  <p>No Additional Events.</p>
                )}
                {/* </Card.Content>
              <br />
              <Card.Content style={{ textAlign: "left" }}> */}
                {/* <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                  <strong>Stall Booking</strong>
                </h3> */}
                <p>
                  {stalls &&
                    stalls.map((stall, idx) => {
                      return (
                        <div key={idx}>
                          {stall.isOvernight === true
                            ? "Overnight Stall: "
                            : "Day Stall: "}
                          {moment.utc(stall.date).format("MMMM D, YYYY")}
                          {/* <br /> */}
                        </div>
                      );
                    })}
                  {rider.shavings !== null &&
                    "Extra Shavings: " + rider.shavings + " Bags"}
                  {/* <br /> */}
                  {/* Stalled Near: {rider.barnName || "No Barn Name Provided"} */}
                </p>
                {/* </Card.Content> */}
                {/* <br />

              <Card.Content style={{ textAlign: "left" }}> */}
                {/* <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                  <strong>RV Booking</strong>
                </h3> */}
                <p>
                  {campings ? (
                    campings.map((rv, idx) => {
                      return (
                        <div key={idx}>
                          {rv.vip === true ? "VIP: " : "RV: "}
                          {moment.utc(rv.date).format("MMMM D, YYYY")}
                          {/* <br /> */}
                        </div>
                      );
                    })
                  ) : (
                    <p>No RV parking booked</p>
                  )}
                </p>
              </Card.Content>
              <br />

              {/* <Card.Content style={{ textAlign: "left" }}>
                <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                  <strong>Horse</strong>
                </h3>
                <p>
                  {horses.find(horse => horse)}
                </p>
              </Card.Content>
              <br /> */}

              {/* <Card.Content style={{ textAlign: "left" }}>
                <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                  <strong>Waiver</strong>
                </h3>
                <p>{rider.WaiverSigned ? <>Signed</> : <>Unsigned</>}</p>
              </Card.Content> */}
            </Container>
          </Card>
          <div className="event-subview-title-area">
            <h2>Enter Again</h2>
          </div>

          <Card fluid className="entry-card">
            <Container fluid className="profile-container">
              <Card.Content style={{ textAlign: "center" }}>
                <br />
                <br />
                <div className="peal-walkthough-image">
                  <img
                    className="pearl-icon-large"
                    src="./illustratorPEARLB.png"
                    alt=""
                  />
                </div>
                <div className="entry-button-text-large">Enter Again</div>
                <p>Sign Up For {event.name} now!</p>

                <button
                  className="event-signup-btn"
                  style={{ width: "192px", position: "centered" }}
                  onClick={handleClick}
                >
                  Sign Up For Event
                </button>

                <br />
                <br />
                <br />
              </Card.Content>
            </Container>
          </Card>
          {/* <CompetitorsTable event={event} /> */}
        </div>
      )}
    </>
  );
};

export default EntryComplete;
