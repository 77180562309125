import React, { useState } from "react";

const MultiSelectButton = (props) => {
  const [selected, setSelected] = useState(false);
  const { children } = props;
  return (
    <button
      key={props.key}
      className={`option-field${selected ? "-selected" : "-deselected"}`}
      type="button"
      name={props.name}
      onClick={() => {
        setSelected(!selected);
        props.onClick();
      }}
    >
      {children}
    </button>
  );
};

export default MultiSelectButton;
