import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container } from "semantic-ui-react";
import { fetchEvent } from "../../features/events/eventsSlice";
import HorseTrialEntries from "./OverviewCards/HorseTrialEntries";
import CombinedTestEntries from "./OverviewCards/CombinedTestEntries";
import DressageTestEntries from "./OverviewCards/DressageTestEntries";
import SchoolingEntries from "./OverviewCards/SchoolingEntries";

const Overview = ({ eventId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  useEffect(() => {
    dispatch(fetchEvent(eventId));
  }, []);
  const event = useSelector((state) => state.events.event);
  console.log("event in overview", event);
  const types = event.EventTypes;
  console.log(types);

  return (
    <div>
      <Card fluid className="card">
        <Container fluid className="profile-container">
        <h2
            style={{
              textAlign: "left",
              fontFamily: "Lato",
              marginLeft: "auto"
            }}
          >
            <strong>Competitive</strong>
        </h2>
          {types.some((el) => el.name === "Horse Trial") && <HorseTrialEntries />}
          {types.some((el) => el.name === "Combined Test" ) && <CombinedTestEntries />}
          <DressageTestEntries />
        </Container>
      </Card>
      <Card fluid className="card">
        <Container fluid className="profile-container">
        <h2
            style={{
              textAlign: "left",
              fontFamily: "Lato",
            }}
          >
            <strong>Schooling</strong>
        </h2>
            {types.some((type)=> type.isCompetitive === false) && <SchoolingEntries/> }
        </Container>
      </Card>
    </div>
  );
};
export default Overview;
