import React, { useState } from "react";
import { Button, Modal, Icon } from "semantic-ui-react";

import AssociationsForm from "../UserForms/associationsForm";

function EditAssociationsModal() {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      size="mini"
      style={{ borderRadius: 10 }}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <Button
          floated="right"
          style={{ borderRadius: 25, color: "black", padding: 10 }}
        >
          <img
            style={{ marginRight: 5, marginBottom: 2 }}
            src="./Edit v1.svg"
            alt=""
          />
          Edit
        </Button>
      }
    >
      <div style={{ margin: 25 }}>
        <h1 floated="left">
          My Associations{" "}
          <Button
            floated="right"
            style={{ borderRadius: 25, color: "black", padding: 10 }}
            onClick={handleClose}
          >
            <Icon name="cancel" />
            Cancel
          </Button>
        </h1>
        <AssociationsForm handleClose={handleClose} />
      </div>
    </Modal>
  );
}

export default EditAssociationsModal;
