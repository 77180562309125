import React from "react";
import { Card, Container, Grid } from "semantic-ui-react";
import eventsSlice from "../../features/events/eventsSlice";

const EventCreateSuccesMessageCard = ({ types, event }) => {
  const completeTypes = types.some(
    (el) => el.entryFee !== null || el.perTestFee !== null
  );


  return (
    <div>
      {types &&
      types.length !== 0 &&
      event.startDate !== null &&
      event.endDate !== null &&
      event.registrationOpen !== null &&
      event.registrationClose !== null &&
      event.registrationOpen !== "Invalid date" &&
      event.registrationClose !== "Invalid date" &&
      event.startDate !== 0 &&
      event.endDate !== 0 &&
      event.address !== null &&
      event.officials.length !== 0 &&
      completeTypes === true ? (
        <>
          <Card fluid className="card">
            <Container fluid className="profile-container">
              <Grid textAlign="center">
                <Grid.Column style={{ maxWidth: 355 }}>
                  <br />
                  <img
                    className="pearl-icon-large"
                    src="./illustratorPEARLB.png"
                    alt=""
                  />
                  <br />
                  <h3>
                    Congratulations! You've entered enough detail to host an
                    event.
                  </h3>
                  <p>
                    Continue editing or contact us at{" "}
                    <a href="mailto: {pearl@pearlsoffice.com}">
                      {" "}
                      pearl@pearlsoffice.com
                    </a>{" "}
                    to publish.
                  </p>
                  <br />
                </Grid.Column>
              </Grid>
            </Container>
          </Card>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EventCreateSuccesMessageCard;
