import React, { useState, useEffect } from "react";
import "../../../App.css";
import Button from "./Button";
import { Form, Card, Container } from "semantic-ui-react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { updateRider } from "../../../features/eventRiders/eventRidersSlice";
import { fetchEventStalls } from "../../../features/events/eventsSlice";
import { updateStall } from "../../../features/stalls/stallsSlice";
import moment from "moment";

const StallForm = ({ nextStep, event, menu }) => {
  const [stallIds, setStallIds] = useState([]);
  const [complete, setComplete] = useState(false);
  const [shavings, setShavings] = useState(0);
  const [selectNoStallFee, setSelectNoStallFee] = useState(false);
  const [shavingsShow, setShavingsShow] = useState(false);
  const dispatch = useDispatch();
  const stalls = useSelector((state) => state.events.stalls);
  const user = useSelector((state) => state.users.activeUser);
  const rider = useSelector((state) => state.riders.rider);

  // const returningRider = event.EventRiders.find((eventRider) => {
  //   return eventRider.userId === user.id;
  // });
  useEffect(() => {
    dispatch(fetchEventStalls({ event }));
  }, []);

  if (!rider) {
    return (
      <>
        <p>Redirecting you to start of form</p>

        {nextStep(0)}
      </>
    );
  }

  const riderId = rider.id;

  // const barnNames = [
  //   {
  //     key: "River City Training Stable",
  //     text: "River City Training Stable",
  //     value: "River City Training Stable",
  //   },
  //   {
  //     key: "Spot On Sporthorses",
  //     text: "Spot On Sporthorses",
  //     value: "Spot On Sporthorses",
  //   },
  //   {
  //     key: "High Standard Stables",
  //     text: "High Standard Stables",
  //     value: "High Standard Stables",
  //   },
  //   {
  //     key: "Avanti Sporthorses",
  //     text: "Avanti Sporthorses",
  //     value: "Avanti Sporthorses",
  //   },
  //   {
  //     key: "Tempus Eventing",
  //     text: "Tempus Eventing",
  //     value: "Tempus Eventing",
  //   },
  //   {
  //     key: "AMP Eventing",
  //     text: "AMP Eventing",
  //     value: "AMP Eventing",
  //   },
  //   {
  //     key: "Landmark Eventing",
  //     text: "Landmark Eventing",
  //     value: "Landmark Eventing",
  //   },
  //   {
  //     key: "Fierce Eventing",
  //     text: "Fierce Eventing",
  //     value: "Fierce Eventing",
  //   },
  //   {
  //     key: "RB Riding",
  //     text: "RB Riding",
  //     value: "RB Riding",
  //   },
  //   {
  //     key: "Rockgate Equestrian",
  //     text: "Rockgate Equestrian",
  //     value: "Rockgate Equestrian",
  //   },
  //   {
  //     key: "Rockwall Equestrian",
  //     text: "Rockwall Equestrian",
  //     value: "Rockwall Equestrian",
  //   },
  //   {
  //     key: "KEH Sporthorses",
  //     text: "KEH Sporthorses",
  //     value: "KEH Sporthorses",
  //   },
  //   {
  //     key: "Whispering Farm",
  //     text: "Whispering Farm",
  //     value: "Whispering Farm",
  //   },
  //   {
  //     key: "Brunson Equestrian Center",
  //     text: "Brunson Equestrian Center",
  //     value: "Brunson Equestrian Center",
  //   },
  //   {
  //     key: "BarIdylwild Farmsn2",
  //     text: "BarIdylwild Farmsn2",
  //     value: "BarIdylwild Farmsn2",
  //   },
  //   {
  //     key: "No Faults Farm",
  //     text: "No Faults Farm",
  //     value: "No Faults Farm",
  //   },
  //   {
  //     key: "Gold Chip Stable",
  //     text: "Gold Chip Stable",
  //     value: "Gold Chip Stable",
  //   },
  //   {
  //     key: "Roland Eventing",
  //     text: "Roland Eventing",
  //     value: "Roland Eventing",
  //   },
  //   {
  //     key: "Mary D'Arcy Eventing",
  //     text: "Mary D'Arcy Eventing",
  //     value: "Mary D'Arcy Eventing",
  //   },
  //   {
  //     key: "Elliott Eventing",
  //     text: "Elliott Eventing",
  //     value: "Elliott Eventing",
  //   },
  //   {
  //     key: "Curragh Equestrian",
  //     text: "Curragh Equestrian",
  //     value: "Curragh Equestrian",
  //   },
  //   {
  //     key: "Peggy Friauf",
  //     text: "Peggy Friauf",
  //     value: "Peggy Friauf",
  //   },
  //   {
  //     key: "Star T Ranch",
  //     text: "Star T Ranch",
  //     value: "Star T Ranch",
  //   },
  //   {
  //     key: "Horselife Farm",
  //     text: "Horselife Farm",
  //     value: "Horselife Farm",
  //   },
  //   {
  //     key: "Tex-Over Farm",
  //     text: "Tex-Over Farm",
  //     value: "Tex-Over Farm",
  //   },
  //   {
  //     key: "Broken Spoke Farm",
  //     text: "Broken Spoke Farm",
  //     value: "Broken Spoke Farm",
  //   },
  //   {
  //     key: "Blue Sky Ranch Aubrey",
  //     text: "Blue Sky Ranch Aubrey",
  //     value: "Blue Sky Ranch Aubrey",
  //   },
  //   {
  //     key: "Quail Run Farm",
  //     text: "Quail Run Farm",
  //     value: "Quail Run Farm",
  //   },
  //   {
  //     key: "Holly Hill Farm",
  //     text: "Holly Hill Farm",
  //     value: "Holly Hill Farm",
  //   },
  //   {
  //     key: "Southern Cross Equestrian",
  //     text: "Southern Cross Equestrian",
  //     value: "Southern Cross Equestrian",
  //   },
  //   {
  //     key: "Kelty O’Donahue Eventing",
  //     text: "Kelty O’Donahue Eventing",
  //     value: "Kelty O’Donahue Eventing",
  //   },
  //   {
  //     key: "Aspire Eventing",
  //     text: "Aspire Eventing",
  //     value: "Aspire Eventing",
  //   },
  //   {
  //     key: "Darkside Eventing",
  //     text: "Darkside Eventing",
  //     value: "Darkside Eventing",
  //   },
  //   {
  //     key: "ISE Equine Endeavors",
  //     text: "ISE Equine Endeavors",
  //     value: "ISE Equine Endeavors",
  //   },
  //   {
  //     key: "Chamberlain Dressage",
  //     text: "Chamberlain Dressage",
  //     value: "Chamberlain Dressage",
  //   },
  //   {
  //     key: "Pear Tree Farm",
  //     text: "Pear Tree Farm",
  //     value: "Pear Tree Farm",
  //   },
  //   {
  //     key: "A&M Equestrian Team",
  //     text: "A&M Equestrian Team",
  //     value: "A&M Equestrian Team",
  //   },
  // ];

  const handleOvernightStall = (e, { values }, stall) => {
    if (stallIds.some((el) => el === stall.id)) {
      let idx = values.fees.findIndex(
        (el) =>
          el.name === (stall.name || "Overnight Stall") &&
          el.price === event.stallOvernightFee
      );

      values.fees.splice(idx, 1);

      let newArr = stallIds.filter((el) => el !== stall.id);

      setStallIds(newArr);
    } else {
      values.fees = [
        ...values.fees.filter((el) => el.name !== "No Stall"),
        {
          name: stall.name || "Overnight Stall",
          price: event.stallOvernightFee,
        },
      ];
      setStallIds([...stallIds, stall.id]);
      setShavingsShow(true);
    }
    console.log("values.fees", values.fees);
  };

  const handleDayStall = (e, { values }, stall) => {
    if (stallIds.some((el) => el === stall.id)) {
      let idx = values.fees.findIndex(
        (el) =>
          el.name === (stall.name || "Day Stall") &&
          el.price === event.stallDaytimeFee
      );

      values.fees.splice(idx, 1);
      let newArr = stallIds.filter((el) => el !== stall.id);

      setStallIds(newArr);
    } else {
      values.fees = [
        ...values.fees.filter((el) => el.name !== "No Stall"),
        {
          name: stall.name || "Day Stall",
          price: event.stallDaytimeFee,
        },
      ];
      setStallIds([...stallIds, stall.id]);
      setShavingsShow(true);
      console.log("It has been selected 1st", stallIds);
    }

    console.log("stallIds", stallIds);
    console.log("values.fees", values.fees);
  };

  const handleShavings = (e, { values }) => {
    console.log("values", values);
    let tempPrice = shavings * event.stallShavingFees;
    values.fees = [
      ...values.fees,
      {
        name: `Shavings: ${shavings} Bag`,
        price: tempPrice,
      },
    ];
    values.shavings = shavings;
  };

  return (
    <Card fluid className="entry-card">
      <Container fluid className="profile-container">
        <div className="main-content-cards">
          <h1 className="pearl-text big regular">Event Registration</h1>
          <div className="event-nav">{menu}</div>
          <div className="registration-step-area">
            <div className="walkthrough-max-width">
              <Formik
                initialValues={{
                  barnName: user.barnName,
                  userId: user.id,
                  eventId: event.id,
                  eventRiderId: rider.id,
                  fees: [...rider.fees],
                  shavings: null,
                }}
                onSubmit={(values) => {
                  let stallVals = {
                    eventRiderId: user.id,
                  };

                  if (shavings > 0) {
                    let tempPrice = shavings * event.stallShavingFees;
                    values.fees = [
                      ...values.fees,
                      {
                        name: `Shavings: ${shavings} Bag`,
                        price: tempPrice,
                      },
                    ];
                    values.shavings = shavings;
                  }

                  dispatch(updateRider({ user, riderId, values }))
                    .then((res) => {
                      stallIds.length &&
                        stallIds.forEach((id) => {
                          dispatch(
                            updateStall({ user, riderId, id, event, values })
                          );
                        });
                    })
                    .then((res) => {
                      nextStep(2);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {!stalls && (
                      <button className="option-field-disabled">
                        None Available
                      </button>
                    )}
                    <div className="field-label big">Overnight Stalls</div>
                    {stalls &&
                      stalls.map((stall, index) => {
                        return stall.is_overnight || stall.isOvernight ? (
                          <>
                            <div key={index}>
                              <Button
                                key={stall.id}
                                className="option-field"
                                name={stall.name}
                                value={stall.name}
                                type="button"
                                onClick={(e) => {
                                  handleOvernightStall(e, { values }, stall);
                                  setSelectNoStallFee(false);
                                }}
                              >
                                <div>
                                  {moment
                                    .utc(stall.date)
                                    .format("dddd, MMMM DD")}
                                </div>
                                <div>${event.stallOvernightFee}</div>
                              </Button>
                            </div>
                          </>
                        ) : (
                          ""
                        );
                      })}
                    {!stalls && (
                      <button className="option-field-disabled" disabled>
                        None Available
                      </button>
                    )}

                    <div className="field-label big">Day Stalls</div>
                    {stalls &&
                      stalls.map((stall, index) => {
                        return stall.isOvernight !== true ? (
                          <>
                            <div key={index}>
                              <Button
                                key={stall.id}
                                className="option-field"
                                name={stall.name}
                                value={stall.name}
                                type="button"
                                onClick={(e) => {
                                  handleDayStall(e, { values }, stall);
                                  setSelectNoStallFee(false);
                                }}
                              >
                                <div>
                                  {moment
                                    .utc(stall.date)
                                    .format("dddd, MMMM DD")}
                                </div>
                                <div>${event.stallDaytimeFee}</div>
                              </Button>
                            </div>
                          </>
                        ) : (
                          ""
                        );
                      })}
                    {!stalls && (
                      <button className="option-field-disabled" disabled>
                        None Available
                      </button>
                    )}

                    <br />

                    <br />

                    <div className="field-label big">No Stall </div>
                    <button
                      className={`option-field${
                        selectNoStallFee ? "-selected" : "-deselected"
                      }`}
                      type="button"
                      onClick={(e) => {
                        values.fees = [
                          ...rider.fees,
                          { name: "No Stall", price: event.noStallFee },
                        ];
                        console.log(values.fees);
                        setStallIds([]);
                        setShavingsShow(true);
                        setSelectNoStallFee(true);
                      }}
                    >
                      <div>No Stall (Mandatory Grounds Fee)</div>
                      <div>${event.noStallFee}</div>
                    </button>
                    <br />
                    {shavingsShow === true && (
                      <>
                        <div className="field-label big">
                          Add Extra Shavings{" "}
                        </div>
                        <button
                          name="event"
                          value=""
                          type="button"
                          className="option-field-2"
                          onClick={(e) => {
                            setShavings(0);
                            setComplete(true);
                          }}
                        >
                          <div>None</div>
                        </button>
                        <button
                          name="shaving1"
                          value="shaving"
                          type="button"
                          className="option-field-2"
                          // onClick={(e) => {
                          //   values.fees = [
                          //     ...values.fees,
                          //     {
                          //       name: "Shavings: 1 Bag",
                          //       price: event.stallShavingFees,
                          //     },
                          //   ];
                          //   values.shavings = 1;
                          //   setComplete(true);
                          // }}
                          onClick={(e) => {
                            setShavings(1);
                            setComplete(true);
                          }}
                        >
                          <div>1 Bag</div>
                          <div>${event.stallShavingFees}</div>
                        </button>
                        <button
                          name="shaving2"
                          value="shaving"
                          type="button"
                          className="option-field-2"
                          // onClick={(e) => {
                          //   values.fees = [
                          //     ...values.fees,
                          //     {
                          //       name: "Shavings: 2 Bags",
                          //       price: event.stallShavingFees * 2,
                          //     },
                          //   ];
                          //   values.shavings = 2;
                          //   setComplete(true);
                          // }}
                          onClick={(e) => {
                            setShavings(2);
                            setComplete(true);
                          }}
                        >
                          <div>2 Bags</div>
                          <div>${event.stallShavingFees * 2}</div>
                        </button>
                        <button
                          name="shaving3"
                          value="shaving"
                          type="button"
                          className="option-field-2"
                          // onClick={(e) => {
                          //   values.fees = [
                          //     ...values.fees,
                          //     {
                          //       name: "Shavings: 3 Bags",
                          //       price: event.stallShavingFees * 3,
                          //     },
                          //   ];
                          //   values.shavings = 3;
                          //   setComplete(true);
                          // }}
                          onClick={(e) => {
                            setShavings(3);
                            setComplete(true);
                          }}
                        >
                          <div>3 Bags</div>
                          <div>${event.stallShavingFees * 3}</div>
                        </button>
                        <br />
                      </>
                    )}

                    {/* <div className="field-label big">
                      Please stall me with:{" "}
                    </div>
                    {barnNames.length ? (
                      <>
                        <Form.Select
                          fluid
                          style={{
                            height: 50,
                            borderRadius: 10,
                            lineHeight: "22px",
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                          placeholder="Select Barn Name"
                          id="barnName"
                          name="barnName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          options={barnNames}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <br /> 
                     {barnOpen === true ? (
                      <>
                        <div className="add-a-barn-all">
                          <Header
                            as="h4"
                            floated="left"
                            style={{ marginBottom: 2 }}
                          >
                            Add A Barn
                          </Header>{" "}
                          <Form.Input
                            style={{
                              height: 50,
                              borderRadius: 10,
                              fontSize: "16px",
                            }}
                            placeholder="New Barn Name"
                            type="newBarn"
                            onChange={handleChange}
                            id="newBarn"
                            value={values.passwordConfirmation}
                          >
                            <input
                              style={{
                                height: 50,
                                borderRadius: 10,
                                fontSize: "16px",
                                marginRight: 5,
                              }}
                            />
                            <button
                              className="add-association-button"
                              type="button"
                              onClick={handleAddBarn}
                            >
                              <Icon style={{ margin: 3 }} name="add" />
                            </button>
                          </Form.Input>
                          <br />
                        </div>
                      </>
                    ) : (
                      <a href onClick={handleBarnField}>
                        <p>Add A Barn</p>
                      </a>
                    )} */}
                    {/* <div className="field-label big">Barn Name</div>
                    <div>
                      <Form.Input
                        style={{
                          height: 50,
                          borderRadius: 10,
                          marginBottom: 8,
                        }}
                        name="barnName"
                        type="barnName"
                        onChange={handleChange}
                        placeholder="Barn Name"
                        id="barnName"
                        value={values.barnName}
                      >
                        <input style={{ borderRadius: 10 }} />
                      </Form.Input>
                    </div>
                    <br /> */}
                    {/* <>
                      <a
                        href
                        style={{ borderRadius: "none", marginBottom: 8 }}
                        onClick={() => setField(true)}
                      >
                        <Header
                          as="h4"
                          floated="left"
                          style={{ marginBottom: 8 }}
                        >
                          <Icon name="plus" />
                          Enter A Barn Name
                        </Header>
                      </a>
                    </> */}

                    {complete ? (
                      <button
                        style={{ marginTop: 2 }}
                        className="submit-button"
                        type="submit"
                      >
                        Continue
                      </button>
                    ) : (
                      <button
                        className="submit-button"
                        type="button"
                        style={{ backgroundColor: "#D3D3D3" }}
                        disabled
                      >
                        Please Complete Form
                      </button>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </Card>
  );
};

export default StallForm;
