import React from "react";
import { Container } from "semantic-ui-react";
import CreateUserForm from "../Components/UserForms/createUserForm";

const CreateAccount = () => {
  return (
    <div>
      <Container fluid>
        <CreateUserForm />
      </Container>
    </div>
  );
};

export default CreateAccount;
