// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

// const BASE_API_URL =
//   "http://localhost:3000/api/v1/";
const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
// const BASE_API_URL = "https://pearl-server-stage.herokuapp.com/api/v1/";
// const BASE_API_URL = "https://pearl-server-test.herokuapp.com/api/v1/";-------------------------for testing PROD

export async function client(endpoint, method, { body, ...customConfig } = {}) {
  const headers = {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY,
  };

  const config = {
    method,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  let data;

  try {
    const response = await window.fetch(`${BASE_API_URL}${endpoint}`, config);
    data = await response.json();
    if (response.ok) {
      return data;
    } else if (data.error === true) {
      throw new Error(data.message);
    } else {
      throw new Error(response.statusText);
    }
  } catch (err) {
    return Promise.reject(err ? err.message : data);
  }
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, "GET", { ...customConfig });
};

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, "POST", { ...customConfig, body });
};

client.put = function (endpoint, body, customConfig = {}) {
  return client(endpoint, "PUT", { ...customConfig, body });
};

client.delete = function (endpoint, body, customConfig = {}) {
  return client(endpoint, "DELETE", { ...customConfig, body });
};
