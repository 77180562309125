import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Header } from "semantic-ui-react";

// import { unwrapResult } from "@reduxjs/toolkit";

import { Formik } from "formik";

import { updateUser } from "../../features/users/userSlice";

const SocialMediaForm = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);

  return (
    <section>
      <br />
      <Formik
        initialValues={{
          socialMedia: {
            instagram: "",
            snapchat: "",
            facebook: "",
            twitter: "",
            tiktok: "",
          },
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(updateUser({ user, values }));
          setSubmitting(false);
          props.handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Instagram
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="instagram"
              type="instagram"
              onChange={handleChange}
              placeholder="Username"
              id="instagram"
              value={values.socialMedia.instagram}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Snapchat
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="snapchat"
              type="snapchat"
              onChange={handleChange}
              placeholder="Username"
              id="snapchat"
              value={values.socialMedia.snapchat}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Facebook
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              placeholder="Username"
              type="facebook"
              name="facebook"
              onChange={handleChange}
              id="facebook"
              value={values.socialMedia.facebook}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Twitter
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              placeholder="Username"
              type="twitter"
              name="twitter"
              onChange={handleChange}
              id="twitter"
              value={values.socialMedia.twitter}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              TikTok
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              placeholder="Username"
              type="tiktok"
              name="tiktok"
              onChange={handleChange}
              id="tiktok"
              value={values.socialMedia.tiktok}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <br />
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Save & Close
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default SocialMediaForm;
