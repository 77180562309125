import React from "react";
import { Link } from "react-router-dom";
import { Icon, Button } from "semantic-ui-react";
import "./NavBar.scss";
import { useSelector, useDispatch } from "react-redux";

import { logoutUser, resetUsers } from "../../features/users/userSlice";
import { resetHorses } from "../../features/horses/horsesSlice";

const SideNavBar = ({ close, open }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const userLoggedIn = user && user.accessToken;

  let classes = "side-nav-bar";
  if (open) {
    classes = "side-nav-bar open";
  }

  const handleLogout = () => {
    dispatch(logoutUser(user.accessToken));
    dispatch(resetUsers());
    dispatch(resetHorses());
  };
  return (
    <nav className={classes}>
      <ul>
        <li className="close-icon">
          <Icon size="large" name="close" onClick={close} />
        </li>
        <li>
          <Link className="side-nav-link" to="./">
            <div>
              <Icon name="calendar alternate outline" />
              Events
            </div>
          </Link>
        </li>
        <li>
          {userLoggedIn ? (
            <>
              <Link className="side-nav-link" to="./profile">
                <div>
                  <Icon name="user circle outline" />
                  My Profile
                </div>
              </Link>
            </>
          ) : (
            <Link className="side-nav-link" to="./login">
              <div>
                <Icon name="user circle outline" />
                My Profile
              </div>
            </Link>
          )}
        </li>
        <>
          {userLoggedIn ? (
            <li>
              <Link className="link-style" to="/">
                <Button
                  circular
                  fluid
                  style={{ backgroundColor: "#ffdea2" }}
                  onClick={handleLogout}
                >
                  <strong style={{ fontSize: 15, fontWeight: 900 }}>
                    Log Out
                  </strong>
                </Button>
              </Link>
            </li>
          ) : (
            <div>
              <li>
                <Link to="/create-account">
                  <Button circular fluid style={{ backgroundColor: "#ffdea2" }}>
                    <strong style={{ fontSize: 15, fontWeight: 900 }}>
                      Create Account
                    </strong>
                  </Button>
                </Link>
              </li>
              <li>
                <Link className="link-style" to="/login">
                  <Button circular fluid style={{ backgroundColor: "#ffdea2" }}>
                    <strong style={{ fontSize: 15, fontWeight: 900 }}>
                      Log <br /> In
                    </strong>
                  </Button>
                </Link>
              </li>
            </div>
          )}
        </>
      </ul>
    </nav>
  );
};

export default SideNavBar;
