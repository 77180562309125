import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Header } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import { updateEvent } from "../../../features/events/eventsSlice";
import {
  fetchTypes,
  updateEventType,
} from "../../../features/eventTypes/eventTypesSlice";

const heightTests = [
  {
    key: "Not Offered",
    text: "Not Offered",
    value: "Not Offered",
  },
  {
    key: "Intermediate Test A 2022",
    text: "Intermediate Test A 2022",
    value: "Intermediate Test A 2022",
  },
  {
    key: "Intermediate Test B 2022",
    text: "Intermediate Test B 2022",
    value: "Intermediate Test B 2022",
  },
  {
    key: "Advanced Test A 2022",
    text: "Advanced Test A 2022",
    value: "Advanced Test A 2022",
  },
  {
    key: "Advanced Test B 2022",
    text: "Advanced Test B 2022",
    value: "Advanced Test B 2022",
  },
  {
    key: "Preliminary Test A 2022",
    text: "Preliminary Test A 2022",
    value: "Preliminary Test A 2022",
  },
  {
    key: "Preliminary Test B 2022",
    text: "Preliminary Test B 2022",
    value: "Preliminary Test B 2022",
  },
  {
    key: "Training Test A 2022",
    text: "Training Test A 2022",
    value: "Training Test A 2022",
  },
  {
    key: "Training Test B 2022",
    text: "Training Test B 2022",
    value: "Training Test B 2022",
  },
  {
    key: "Novice Test A 2022",
    text: "Novice Test A 2022",
    value: "Novice Test A 2022",
  },
  {
    key: "Novice Test B 2022",
    text: "Novice Test B 2022",
    value: "Novice Test B 2022",
  },
  {
    key: "Beginner Novice Test A 2022",
    text: "Beginner Novice Test A 2022",
    value: "Beginner Novice Test A 2022",
  },
  {
    key: "Beginner Novice Test B 2022",
    text: "Beginner Novice Test B 2022",
    value: "Beginner Novice Test B 2022",
  },
  {
    key: "Starter Test A 2022",
    text: "Starter Test A 2022",
    value: "Starter Test A 2022",
  },  
  {
    key: "Starter Test B 2022",
    text: "Starter Test B 2022",
    value: "Starter Test B 2022",
  },
  {
    key: "Preliminary Test C 2022",
    text: "Preliminary Test C 2022",
    value: "Preliminary Test C 2022",
  },
  {
    key: "Modified Test A 2022",
    text: "Modified Test A 2022",
    value: "Modified Test A 2022",
  },  
  {
    key: "Modified Test B 2022",
    text: "Modified Test B 2022",
    value: "Modified Test B 2022",
  },
  {
    key: "Modified Test C 2022",
    text: "Modified Test C 2022",
    value: "Modified Test C 2022",
  },
  {
    key: "Novice Three Day 2022",
    text: "Novice Three Day 2022",
    value: "Novice Three Day 2022",
  },
  {
    key: "Training Three Day 2022",
    text: "Training Three Day 2022",
    value: "Training Three Day 2022",
  },
  {
    key: "Modified Three Day 2022",
    text: "Modified Three Day 2022",
    value: "Modified Three Day 2022",
  },
  {
    key: "Preliminary Three Day 2022",
    text: "Preliminary Three Day 2022",
    value: "Preliminary Three Day 2022",
  },
  {
    key: "Intro Test A 2019",
    text: "Intro Test A 2019",
    value: "Intro Test A 2019",
  },
  {
    key: "Intro Test B 2019",
    text: "Intro Test B 2019",
    value: "Intro Test B 2019",
  },
  {
    key: "Intro Test C 2019",
    text: "Intro Test C 2019",
    value: "Intro Test C 2019",
  },
  {
    key: "Training Level Test 1 2019",
    text: "Training Level Test 1 2019",
    value: "Training Level Test 1 2019",
  },
  {
    key: "Training Level Test 2 2019",
    text: "Training Level Test 2 2019",
    value: "Training Level Test 2 2019",
  },
  {
    key: "Training Level Test 3 2019",
    text: "Training Level Test 3 2019",
    value: "Training Level Test 3 2019",
  },
  {
    key: "First Level Test 1 2019",
    text: "First Level Test 1 2019",
    value: "First Level Test 1 2019",
  },
  {
    key: "First Level Test 2 2019",
    text: "First Level Test 2 2019",
    value: "First Level Test 2 2019",
  },
  {
    key: "First Level Test 3 2019",
    text: "First Level Test 3 2019",
    value: "First Level Test 3 2019",
  },
  {
    key: "Second Level Test 1 2019",
    text: "Second Level Test 1 2019",
    value: "Second Level Test 1 2019",
  },
  {
    key: "Second Level Test 2 2019",
    text: "Second Level Test 2 2019",
    value: "Second Level Test 2 2019",
  },
  {
    key: "Second Level Test 3 2019",
    text: "Second Level Test 3 2019",
    value: "Second Level Test 3 2019",
  },
  {
    key: "Third Level Test 1 2019",
    text: "Third Level Test 1 2019",
    value: "Third Level Test 1 2019",
  },
  {
    key: "Third Level Test 2 2019",
    text: "Third Level Test 2 2019",
    value: "Third Level Test 2 2019",
  },
  {
    key: "Third Level Test 3 2019",
    text: "Third Level Test 3 2019",
    value: "Third Level Test 3 2019",
  },
  {
    key: "Fourth Level Test 1 2019",
    text: "Fourth Level Test 1 2019",
    value: "Fourth Level Test 1 2019",
  },
  {
    key: "Fourth Level Test 2 2019",
    text: "Fourth Level Test 2 2019",
    value: "Fourth Level Test 2 2019",
  },
  {
    key: "Fourth Level Test 3 2019",
    text: "Fourth Level Test 3 2019",
    value: "Fourth Level Test 3 2019",
  },
  {
    key: "WDAA Intro Level 1",
    text: "WDAA Intro Level 1",
    value: "WDAA Intro Level 1",
  },
  {
    key: "WDAA Intro Level 2",
    text: "WDAA Intro Level 2",
    value: "WDAA Intro Level 2",
  },
  {
    key: "WDAA Intro Level 3",
    text: "WDAA Intro Level 3",
    value: "WDAA Intro Level 3",
  },
  {
    key: "WDAA Intro Level 4",
    text: "WDAA Intro Level 4",
    value: "WDAA Intro Level 4",
  },
  //------------------------------------------------------------Half?
  {
    key: "WDAA Basic Level 1",
    text: "WDAA Basic Level 1",
    value: "WDAA Basic Level 1",
  },
  {
    key: "WDAA Basic Level 2",
    text: "WDAA Basic Level 2",
    value: "WDAA Basic Level 2",
  },
  {
    key: "WDAA Basic Level 3",
    text: "WDAA Basic Level 3",
    value: "WDAA Basic Level 3",
  },
  {
    key: "WDAA Basic Level 4",
    text: "WDAA Basic Level 4",
    value: "WDAA Basic Level 4",
  },
  {
    key: "WDAA Level 1 Test 1",
    text: "WDAA Level 1 Test 1",
    value: "WDAA Level 1 Test 1",
  },
  {
    key: "WDAA Level 1 Test 2",
    text: "WDAA Level 1 Test 2",
    value: "WDAA Level 1 Test 2",
  },
  {
    key: "WDAA Level 1 Test 3",
    text: "WDAA Level 1 Test 3",
    value: "WDAA Level 1 Test 3",
  },
  {
    key: "WDAA Level 1 Test 4",
    text: "WDAA Level 1 Test 4",
    value: "WDAA Level 1 Test 4",
  },
  {
    key: "WDAA Level 2 Test 1",
    text: "WDAA Level 2 Test 1",
    value: "WDAA Level 2 Test 1",
  },
  {
    key: "WDAA Level 2 Test 2",
    text: "WDAA Level 2 Test 2",
    value: "WDAA Level 2 Test 2",
  },
  {
    key: "WDAA Level 2 Test 3",
    text: "WDAA Level 2 Test 3",
    value: "WDAA Level 2 Test 3",
  },
  {
    key: "WDAA Level 2 Test 4",
    text: "WDAA Level 2 Test 4",
    value: "WDAA Level 2 Test 4",
  },
  {
    key: "WDAA Level 3 Test 1",
    text: "WDAA Level 3 Test 1",
    value: "WDAA Level 3 Test 1",
  },
  {
    key: "WDAA Level 3 Test 2",
    text: "WDAA Level 3 Test 2",
    value: "WDAA Level 3 Test 2",
  },
  {
    key: "WDAA Level 3 Test 3",
    text: "WDAA Level 3 Test 3",
    value: "WDAA Level 3 Test 3",
  },
  {
    key: "WDAA Level 3 Test 4",
    text: "WDAA Level 3 Test 4",
    value: "WDAA Level 3 Test 4",
  },
  {
    key: "WDAA Level 4 Test 1",
    text: "WDAA Level 4 Test 1",
    value: "WDAA Level 4 Test 1",
  },
  {
    key: "WDAA Level 4 Test 2",
    text: "WDAA Level 4 Test 2",
    value: "WDAA Level 4 Test 2",
  },
  {
    key: "WDAA Level 4 Test 3",
    text: "WDAA Level 4 Test 3",
    value: "WDAA Level 4 Test 3",
  },
  {
    key: "WDAA Level 4 Test 4",
    text: "WDAA Level 4 Test 4",
    value: "WDAA Level 4 Test 4",
  },
  {
    key: "WDAA Basic Level Musical Freestyle",
    text: "WDAA Basic Level Musical Freestyle",
    value: "WDAA Basic Level Musical Freestyle",
  },
  {
    key: "WDAA Intro Level Musical Freestyle",
    text: "WDAA Intro Level Musical Freestyle",
    value: "WDAA Intro Level Musical Freestyle",
  },
  {
    key: "WDAA Level 1 Musical Freestyle",
    text: "WDAA Level 1 Musical Freestyle",
    value: "WDAA Level 1 Musical Freestyle",
  },
  {
    key: "WDAA Level 2 Musical Freestyle",
    text: "WDAA Level 2 Musical Freestyle",
    value: "WDAA Level 2 Musical Freestyle",
  },
  {
    key: "WDAA Level 3 Musical Freestyle",
    text: "WDAA Level 3 Musical Freestyle",
    value: "WDAA Level 3 Musical Freestyle",
  },
  {
    key: "WDAA Level 4 Musical Freestyle",
    text: "WDAA Level 4 Musical Freestyle",
    value: "WDAA Level 4 Musical Freestyle",
  },

];

const EditHTorCTForm = ({ event, type, handleClose }) => {
  const [combinedEvents, setCombinedEvents] = useState([
    ...event.combinedEvents,
  ]);
  event.combinedEvents ? console.log("yep") : console.log("nope");
  console.log("event in form", event);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  console.log(user.email, user.firstName);
  console.log("type in typeForm", type);
  const typeId = type.id;
  const eventId = event.id;

  console.log("type ID in typeForm", typeId);

  return (
    <section>
      <br />
      <Formik
        initialValues={{
          id: type.id,
          entryFee: type.entryFee,
          showJumping: null,
          crossCountry: null,
          dressage: null,
          height: [
            {
              height: "Intro (up to 18 inches)",
              test:
                type.height && (type.height[0].test ? type.height[0].test : ""),
            },
            {
              height: `Starter (2'3")`,
              test:
                type.height && (type.height[1].test ? type.height[1].test : ""),
            },
            {
              height: `Beginner Novice (2'7")`,
              test:
                type.height && (type.height[2].test ? type.height[2].test : ""),
            },
            {
              height: `Novice (2'11")`,
              test:
                type.height && (type.height[3].test ? type.height[3].test : ""),
            },
            {
              height: `Training (3'3")`,
              test:
                type.height && (type.height[4].test ? type.height[4].test : ""),
            },
            {
              height: `Preliminary (3'7")`,

              test:
                type.height && (type.height[5].test ? type.height[5].test : ""),
            },
          ],
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(updateEventType({ user, typeId, values }))
            .then(
              () => (values.id = event.id),
              (values.name = event.name),
              (values.facilityName = event.facilityName),
              (values.description = event.description),
              (values.combinedEvents = [...combinedEvents]),
              dispatch(updateEvent({ user, event, values }))
            )
            .then(() => dispatch(fetchTypes(eventId)));
          setSubmitting(false);
          handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Cost For Entry
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="entryFee"
              type="entryFee"
              onChange={handleChange}
              placeholder="$100"
              id="entryFee"
              value={values.entryFee}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            {type.name === "Horse Trial" && (
              <>
                <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                  Date For Cross Country
                </Header>
                <DatePicker
                  style={{ borderRadius: 10 }}
                  selected={values.crossCountry}
                  dateFormat="MMMM d, yyyy"
                  name="crossCountry"
                  onChange={(date) => {
                    setFieldValue("crossCountry", date);
                    combinedEvents.some((el) => el.name === "Cross Country") ===
                    true
                      ? setCombinedEvents([
                          ...combinedEvents.filter(
                            (el) => el.name !== "Cross Country"
                          ),
                          { name: "Cross Country", date: date },
                        ])
                      : setCombinedEvents([
                          ...combinedEvents,
                          { name: "Cross Country", date: date },
                        ]);
                  }}
                />
              </>
            )}
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Date For Show Jumping
            </Header>
            <DatePicker
              style={{ borderRadius: 10 }}
              selected={values.showJumping}
              dateFormat="MMMM d, yyyy"
              name="showJumping"
              onChange={(date) => {
                setFieldValue("showJumping", date);
                combinedEvents.some((el) => el.name === "Show Jumping") === true
                  ? setCombinedEvents([
                      ...combinedEvents.filter(
                        (el) => el.name !== "Show Jumping"
                      ),
                      { name: "Show Jumping", date: date },
                    ])
                  : setCombinedEvents([
                      ...combinedEvents,
                      { name: "Show Jumping", date: date },
                    ]);
              }}
            />
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Date For Dressage
            </Header>
            <DatePicker
              style={{ borderRadius: 10 }}
              selected={values.dressage}
              dateFormat="MMMM d, yyyy"
              name="dressage"
              onChange={(date) => {
                setFieldValue("dressage", date);
                combinedEvents.some((el) => el.name === "Dressage") === true
                  ? setCombinedEvents([
                      ...combinedEvents.filter((el) => el.name !== "Dressage"),
                      { name: "Dressage", date: date },
                    ])
                  : setCombinedEvents([
                      ...combinedEvents,
                      { name: "Dressage", date: date },
                    ]);
              }}
            />

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Intro (up to 18 inches)
            </Header>
            <Form.Select
              fluid
              style={{
                height: 50,
                borderRadius: 10,
                lineHeight: "22px",
                fontSize: "16px",
                fontWeight: 500,
              }}
              placeholder="Dressage Test"
              id="height"
              name="height"
              onChange={(event, field) => {
                values.height[0].test = field.value;
              }}
              options={heightTests}
            />
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Starter (2'3")
            </Header>
            <Form.Select
              fluid
              style={{
                height: 50,
                borderRadius: 10,
                lineHeight: "22px",
                fontSize: "16px",
                fontWeight: 500,
              }}
              placeholder="Dressage Test"
              id="height"
              name="height"
              onChange={(event, field) => {
                values.height[1].test = field.value;
              }}
              options={heightTests}
            />
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Beginner Novice (2'7")
            </Header>
            <Form.Select
              fluid
              style={{
                height: 50,
                borderRadius: 10,
                lineHeight: "22px",
                fontSize: "16px",
                fontWeight: 500,
              }}
              placeholder="Dressage Test"
              id="height"
              name="height"
              onChange={(event, field) => {
                values.height[2].test = field.value;
              }}
              options={heightTests}
            />
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Novice (2'11")
            </Header>
            <Form.Select
              fluid
              style={{
                height: 50,
                borderRadius: 10,
                lineHeight: "22px",
                fontSize: "16px",
                fontWeight: 500,
              }}
              placeholder="Dressage Test"
              id="height"
              name="height"
              onChange={(event, field) => {
                values.height[3].test = field.value;
              }}
              options={heightTests}
            />
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Training (3'3")
            </Header>
            <Form.Select
              fluid
              style={{
                height: 50,
                borderRadius: 10,
                lineHeight: "22px",
                fontSize: "16px",
                fontWeight: 500,
              }}
              placeholder="Dressage Test"
              id="height"
              name="height"
              onChange={(event, field) => {
                values.height[4].test = field.value;
              }}
              options={heightTests}
            />
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Preliminary (3'7")
            </Header>
            <Form.Select
              fluid
              style={{
                height: 50,
                borderRadius: 10,
                lineHeight: "22px",
                fontSize: "16px",
                fontWeight: 500,
              }}
              placeholder="Dressage Test"
              id="height"
              name="height"
              onChange={(event, field) => {
                values.height[5].test = field.value;
              }}
              options={heightTests}
            />

            <br />
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Save & Close
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EditHTorCTForm;
