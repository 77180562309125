import React from "react";

const Footer = () => {
  return (
    <div>
      <p>
        For support, please email us at:
        <a href="mailto: {pearl@pearlsoffice.com}"> pearl@pearlsoffice.com</a>.
      </p>
      <br />
    </div>
  );
};

export default Footer;
