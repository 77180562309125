import React, { useState } from "react";
import "../App.css";

import { useLocation } from "react-router-dom";
import Info from "../Components/EventDetails/Info";
import Entry from "../Components/EventDetails/EntryComponents/Entry";
import Times from "../Components/EventDetails/Times";
import Scores from "../Components/EventDetails/Scores";
import moment from "moment";
import { Container } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import { fetchEvents } from "../features/events/eventsSlice";

const Child = () => {
  let data = useLocation();
  return data.state; //state would be in data.state//
};

const Event = () => {
  const event = Child();
  // console.log("event data", event);
  const [activeItems, setActiveItems] = useState([
    { key: "info", active: true, name: "Info", icon: "/Info v1.svg" },
    { key: "entry", active: false, name: "Entry", icon: "/Event Entry v1.svg" },
    // { key: "times", active: false, name: "Times", icon: "/Clock v1.svg" },
    // { key: "scores", active: false, name: "Scores", icon: "/Trophy v1.svg" },
  ]);

  const handleClick = (index) => {
    setActiveItems((prevItems) =>
      prevItems.map((e, i) => ({ ...e, active: i === index }))
    );
  };

  const menu = activeItems.map((item, index) => {
    return (
      <>
        <a
          href
          key={index}
          onClick={() => handleClick(index)}
          className={
            item.active ? "event-nav-button-active" : "event-nav-button"
          }
        >
          <img key={index} className="event-nav-icon" src={item.icon} alt="" />
          <div style={{ height: "18px" }}>{item.name}</div>
        </a>
      </>
    );
  });

  return (
    <>
      <div className="main-content-cards">
        <Container fluid>
          <div className="event-hero-image"></div>
          <div className="event-title-area">
            <p>{event.name}</p>
            <h1 className="event-view-event-name">{event.shortDescription}</h1>
            <div className="dates">
              {event.endDate ? (
                <>
                  {moment.utc(event.endDate).isSameOrBefore(event.startDate) ? (
                    <>{moment.utc(event.startDate).format("MMMM D, YYYY")}</>
                  ) : (
                    <>
                      {moment.utc(event.startDate).format("MMMM D, YYYY")}-{" "}
                      {moment.utc(event.endDate).format("MMMM D, YYYY")}
                    </>
                  )}
                </>
              ) : (
                <>{moment(event.startDate).format("MMMM D, YYYY")}</>
              )}
            </div>
          </div>
          <div className="event-nav">{menu}</div>
          {activeItems.map((item, index) => {
            return item.key === "info" && item.active === true ? (
              <Info key={index} event={event} />
            ) : item.key === "entry" && item.active === true ? (
              <Entry key={index} event={event} />
            ) : (
              // : item.key === "times" && item.active === true ? (
              //   <Times key={index} />
              // ) : item.key === "scores" && item.active === true ? (
              //   <Scores key={index} />
              // )

              ""
            );
          })}
        </Container>
      </div>
      {/* ) : (
        //DELETE process check ONCE ENTRY IS WORKING
        <div className="main-content-cards">
          <Container fluid>
            <div className="event-hero-image"></div>
            <div className="event-title-area">
              <p>{event.name}</p>
              <h1 className="event-view-event-name">
                {event.shortDescription}
              </h1>
              <div className="dates">
                {moment(event.startDate).format("MMMM D, YYYY")} -{" "}
                {moment(event.endDate).format("MMMM D, YYYY")}
              </div>
            </div>
            <Info event={event} />
          </Container>
        </div>
      )} */}
    </>
  );
};

export default Event;
