import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Icon } from "semantic-ui-react";
import { uploadEventImage } from "../../features/events/eventsSlice";
import { fetchEvent } from "../../features/events/eventsSlice";
import EditModal from "./EventEditForms/EditModal";

const MgrDocsWaivers = ({ eventId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);

  const event = useSelector((state) => state.events.event);

  let card = "DocsWaivers";

  return (
    <div>
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1
            style={{
              textAlign: "left",
              fontFamily: "Lato",
              fontSize: "1.90rem",
              fontWeight: "900px",
            }}
          >
            <strong>
              Documents & Waivers
              <EditModal fluid event={event} card={card} />
            </strong>
            {event.waiverUrl === null ||
            event.waiverUrl === "" ||
            event.requiredRiderDocuments === null ? (
              <strong style={{ color: "red", marginLeft: "1rem" }}>
                *Required
              </strong>
            ) : (
              <Icon
                name="check circle"
                style={{ color: "green", marginLeft: "1rem" }}
              />
            )}
          </h1>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Required Rider Documents</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.waiverUrl
                ? event.waiverUrl
                : "*Please enter your required documents with Edit"}
            </p>
          </Card.Content>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Required Horse Documents</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {event.requiredRiderDocuments ? (
              event.requiredRiderDocuments.map((el, idx) => {
                return (
                  <p style={{ textAlign: "left", marginBottom: 1 }} key={idx}>
                    {el}
                  </p>
                );
              })
            ) : (
              <p>*Please enter your waivers with Edit</p>
            )}
          </Card.Content>
        </Container>
      </Card>
    </div>
  );
};

export default MgrDocsWaivers;
