import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Dimmer,
  Loader,
} from "semantic-ui-react";

import { Redirect, Link } from "react-router-dom";
import PearlDialogueCreate from "./PearlDialogueCreate";
import { Formik } from "formik";

import {
  createUser,
  updateUser,
  resetErrors,
} from "../../features/users/userSlice";

const roleOptions = [
  {
    key: "Rider/Participant",
    text: "Rider/Participant",
    value: "Rider/Participant",
  },
  {
    key: "Trainer",
    text: "Trainer",
    value: "Trainer",
  },
  // {
  //   key: "Event Manager",
  //   text: "Event Manager",
  //   value: "Event Manager",
  // }
];
const ageOptions = [
  {
    key: "Junior/Young Rider",
    text: "Junior/Young Rider",
    value: "Junior/Young Rider",
  },
  {
    key: "Adult Amateur",
    text: "Adult Amateur",
    value: "Adult Amateur",
  },
  {
    key: "Open/Professional",
    text: "Open/Professional",
    value: "Open/Professional",
  },
];

const CreateUserForm = () => {
  const dispatch = useDispatch();
  const fetchStatus = useSelector((state) => state.users.fetchStatus);
  const emailError = useSelector((state) => state.users.error);

  useEffect(() => {
    dispatch(resetErrors());
  }, []);

  return (
    <section>
      <br />
      <br />
      {fetchStatus === "succeeded" ? <Redirect to="/profile" /> : null}
      {fetchStatus === "loading" && (
        <Dimmer active page>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <Formik
        initialValues={{
          email: "",
          password: "",
          passwordConfirmation: "",
          firstName: null,
          lastName: null,
          role: null,
          ageGroup: null,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.firstName) {
            errors.firstName = "Required";
          }
          if (!values.lastName) {
            errors.lastName = "Required";
          }
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          } else if (values.password.length < 8) {
            errors.password = "Password must contain 8 characters";
          } else if (values.password !== values.passwordConfirmation) {
            errors.passwordConfirmation = "Passwords do not match";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          let newVals = {
            firstName: values.firstName,
            lastName: values.lastName,
            ageGroup: values.ageGroup,
          };
          let user = {};
          dispatch(createUser(values))
            .then(
              (res) => (
                (user = res.payload.user),
                (values = newVals),
                dispatch(updateUser({ user, values }))
              )
            )
            .catch((err) => err);
          setSubmitting(fetchStatus === "loading");
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Grid
            textAlign="center"
            style={{ height: "100%" }}
            verticalAlign="middle"
          >
            <Grid.Column style={{ maxWidth: 375 }}>
              <img
                className="pearl-icon-large"
                src="./illustratorPEARLB.png"
                alt=""
              />
              <br />
              <PearlDialogueCreate />
              <br />

              {emailError && (
                <Header color="red" as="h4">
                  Pearl's Office account already exists with this email. Each
                  Rider account must have their own unique email address.
                  <br />
                  <Link to="/login">Go to Login?</Link>
                </Header>
              )}
              <Form onSubmit={handleSubmit}>
                {values.firstName === null ||
                values.lastName === null ||
                values.role === null ||
                values.ageGroup === null ? (
                  <>
                    <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                      First Name*
                    </Header>

                    {errors.firstName && touched.firstName && errors.firstName}

                    <Form.Input
                      style={{
                        height: 50,
                        borderRadius: 10,
                        fontSize: "16px",
                        boxShadow: "none",
                      }}
                      iconPosition="left"
                      name="firstName"
                      type="firstName"
                      onChange={handleChange}
                      placeholder="First Name"
                      id="firstName"
                      value={values.firstName}
                    >
                      <Icon className="user" />
                      <input style={{ height: 50, borderRadius: 10 }} />
                    </Form.Input>
                    <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                      Last Name*
                    </Header>

                    {errors.lastName && touched.lastName && errors.lastName}

                    <Form.Input
                      style={{
                        height: 50,
                        borderRadius: 10,
                        fontSize: "16px",
                        boxShadow: "none",
                      }}
                      iconPosition="left"
                      name="lastName"
                      type="lastName"
                      onChange={handleChange}
                      placeholder="Last Name"
                      id="lastName"
                      value={values.lastName}
                    >
                      <Icon className="user" />
                      <input style={{ borderRadius: 10 }} />
                    </Form.Input>

                    {errors.lastName && touched.lastName && errors.lastName}

                    <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                      What best describes you?*
                    </Header>

                    <Form.Select
                      fluid
                      style={{
                        height: 50,
                        borderRadius: 10,
                        lineHeight: "22px",
                        fontSize: "16px",
                        boxShadow: "none",
                      }}
                      iconPosition="left"
                      placeholder="Role"
                      id="role"
                      name="role"
                      onChange={(event, field) => {
                        console.log("SELECT VALUE", field.value);
                        setFieldValue("role", field.value);
                      }}
                      options={roleOptions}
                    />
                    <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                      Age Group*
                    </Header>
                    {values.ageGroup ? (
                      <Form.Select
                        fluid
                        style={{
                          height: 50,
                          borderRadius: 10,
                          lineHeight: "22px",
                          fontSize: "16px",
                          boxShadow: "none",
                        }}
                        text={values.ageGroup}
                        id="ageGroup"
                        name="ageGroup"
                        onChange={(event, field) => {
                          console.log("SELECT VALUE", field.value);
                          setFieldValue("ageGroup", field.value);
                        }}
                        options={ageOptions}
                      />
                    ) : (
                      <Form.Select
                        fluid
                        style={{
                          height: 50,
                          borderRadius: 10,
                          lineHeight: "22px",
                          fontSize: "16px",
                          boxShadow: "none",
                        }}
                        placeholder="Age Group"
                        id="ageGroup"
                        name="ageGroup"
                        onChange={(event, field) => {
                          console.log("SELECT VALUE", field.value);
                          setFieldValue("ageGroup", field.value);
                        }}
                        options={ageOptions}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                      Email Address*
                    </Header>

                    {errors.email && touched.email && errors.email}

                    <Form.Input
                      style={{
                        height: 50,
                        borderRadius: 10,
                        fontSize: "16px",
                        boxShadow: "none",
                      }}
                      iconPosition="left"
                      name="email"
                      type="email"
                      onChange={handleChange}
                      placeholder="sample@test.com"
                      id="email"
                      value={values.email}
                    >
                      <Icon className="user" />
                      <input style={{ borderRadius: 10 }} />
                    </Form.Input>
                    <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                      Password*
                    </Header>
                    {errors.password && touched.password && errors.password}
                    <Form.Input
                      style={{
                        height: 50,
                        borderRadius: 10,
                        boxShadow: "none",
                      }}
                      iconPosition="left"
                      placeholder="Password"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      id="password"
                      value={values.password}
                    >
                      <Icon className="lock" />
                      <input style={{ borderRadius: 10 }} />
                    </Form.Input>
                    <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                      Re-Enter Password*
                    </Header>
                    {errors.passwordConfirmation &&
                      touched.passwordConfirmation &&
                      errors.passwordConfirmation}
                    <Form.Input
                      style={{
                        height: 50,
                        borderRadius: 10,
                        fontSize: "16px",
                        boxShadow: "none",
                      }}
                      iconPosition="left"
                      placeholder="Password (again)"
                      type="password"
                      name="passwordConfirmation"
                      onChange={handleChange}
                      id="passwordConfirmation"
                      value={values.passwordConfirmation}
                    >
                      <Icon className="lock" />
                      <input
                        style={{
                          height: 50,
                          borderRadius: 10,
                          fontSize: "16px",
                        }}
                      />
                    </Form.Input>
                    <br />

                    <Button
                      style={{
                        backgroundColor: "#ffdea2",
                        height: 50,

                        borderRadius: 10,
                      }}
                      fluid
                      black
                      type="submit"
                    >
                      Submit
                    </Button>
                  </>
                )}
              </Form>
            </Grid.Column>
          </Grid>
        )}
      </Formik>
      <br />
    </section>
  );
};

export default CreateUserForm;
