import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Header } from "semantic-ui-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Redirect, Link } from "react-router-dom";

import { Formik } from "formik";

import { updateUser } from "../../features/users/userSlice";

const UpdateAccountForm = (props) => {
  const [confirm, setConfirm] = useState(false);

  const handleConfirm = () => {
    setConfirm(false);
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);

  return (
    <section>
      <br />
      <Formik
        initialValues={{
          email: "",
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(updateUser({ user, values }));
          setSubmitting(false);
          props.handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Email
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="email"
              type="email"
              onChange={handleChange}
              placeholder="Email"
              id="email"
              value={values.email}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Header as="h4">
              Forgot Password?{" "}
              <div>
                <Link to="./forgot-password">Click here</Link>
              </div>
            </Header>
            <br />
            <section>
              {confirm ? (
                <>
                  <Header as="h4">
                    This will change your Username, do you wish to continue?
                  </Header>
                  <Button
                    style={{
                      backgroundColor: "#ffdea2",
                      height: 50,
                      borderRadius: 10,
                    }}
                    fluid
                    type="submit"
                  >
                    Yes
                  </Button>
                  <br />
                </>
              ) : (
                <Button
                  style={{
                    backgroundColor: "#ffdea2",
                    height: 50,

                    borderRadius: 10,
                  }}
                  fluid
                  onClick={(event) => setConfirm(!confirm)}
                >
                  Save & Close
                </Button>
              )}
            </section>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default UpdateAccountForm;
