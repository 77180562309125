import React, { useEffect } from "react";
import { Card, Container, Button, Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import EditModal from "./EventEditForms/EditModal";
import { fetchEvent } from "../../features/events/eventsSlice";
import {
  fetchTypes,
  deleteEventType,
} from "../../features/eventTypes/eventTypesSlice";
import moment from "moment";

const MgrSingleType = ({ type }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const event = useSelector((state) => state.events.event);
  const eventId = event.id;
  const typeId = type.id;
  
  let card = "SingleType";

  return (
    <div>
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1
            style={{
              textAlign: "left",
              fontFamily: "Lato",
              fontSize: "30px",
              fontWeight: "900px",
            }}
          >
            <strong>
              {type.name}
              {(type.name === "Horse Trial" ||
                type.name === "Combined Test" ||
                type.name === "Derby" ||
                type.name === "XC Schooling" ||
                type.name === "Hacking" ||
                type.name === "Stadium Schooling") &&
                (type.entryFee === null ? (
                  <strong style={{ color: "red", marginLeft: "1rem" }}>
                    *Info Required
                  </strong>
                ) : (
                  <Icon
                    name="check circle"
                    style={{ color: "green", marginLeft: "1rem" }}
                  />
                ))}
              {(type.name === "Dressage Only" ||
                type.name === "Dressage Tests") &&
                (type.perTestFee === null ||
                type.maxTests === null ||
                type.tests === null ? (
                  <strong style={{ color: "red", marginLeft: "1rem" }}>
                    *Info Required
                  </strong>
                ) : (
                  <Icon
                    name="check circle"
                    style={{ color: "green", marginLeft: "1rem" }}
                  />
                ))}

              <EditModal fluid event={event} type={type} card={card} />
              <Button
                floated="right"
                style={{
                  borderRadius: 25,
                  backgroundColor: "#ff8080",
                  color: "black",
                  padding: 10,
                }}
                onClick={() =>
                  dispatch(deleteEventType({ user, typeId }))
                    .then(() => dispatch(fetchEvent(eventId)))
                    .then(() => dispatch(fetchTypes(eventId)))
                }
              >
                <Icon name="trash alternate outline" />
                Delete
              </Button>
            </strong>
          </h1>
          {type && type.isCompetitive === true ? (
            <>
              {type.isDressage === false ? (
                <>
                  <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    <strong>Entry Cost</strong>
                    <p>${type.entryFee}</p>
                  </h3>
                  <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    <strong>Height & Tests Offered</strong>
                    {type.height &&
                      type.height.map((el, idx) => {
                        return (
                          <p key={idx} style={{ marginBottom: 0 }}>
                            {el.height} - {el.test}
                          </p>
                        );
                      })}
                  </h3>
                </>
              ) : (
                <>
                  <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    <strong>Cost Per Test</strong>
                    <p>${type.perTestFee}</p>
                  </h3>
                  <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    <strong>Max Tests</strong>
                    <p>{type.maxTests}</p>
                  </h3>
                  <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    <strong>Tests Offered</strong>
                    {type.tests &&
                      type.tests.map((el, idx) => {
                        return (
                          <p key={idx} style={{ marginBottom: 0 }}>
                            {el}
                          </p>
                        );
                      })}
                  </h3>
                </>
              )}
            </>
          ) : type.isDressage === false ? (
            <>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Entry Cost (Competitive Discount)</strong>
                <p>${type.entryFee}</p>
              </h3>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Entry Cost (Not Competing)</strong>
                <p>${type.entryFeeNonCompetitive}</p>
              </h3>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Day Offered</strong>

                {type.eventDate !== null ? (
                  <p style={{ marginBottom: 0 }}>
                    {moment(type.eventDate, "YYYY-MM-DD HH:mm:ss A").format(
                      "MMMM D, YYYY"
                    )}
                  </p>
                ) : (
                  <p>Date Not Offered Yet</p>
                )}
              </h3>
            </>
          ) : (
            <>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Cost Per Test</strong>
                <p>${type.perTestFee}</p>
              </h3>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Max Tests</strong>
                <p>{type.maxTests}</p>
              </h3>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Tests Offered</strong>
                {type.tests &&
                  type.tests.map((el, idx) => {
                    return (
                      <p key={idx} style={{ marginBottom: 0 }}>
                        {el}
                      </p>
                    );
                  })}
              </h3>
            </>
          )}
        </Container>
      </Card>
    </div>
  );
};

export default MgrSingleType;
