import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Icon } from "semantic-ui-react";

import { fetchEvent } from "../../features/events/eventsSlice";
import EditModal from "./EventEditForms/EditModal";

const MgrOfficials = ({ eventId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const event = useSelector((state) => state.events.event);

  let card = "Officials";
  return (
    <div>
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1
            style={{
              textAlign: "left",
              fontFamily: "Lato",
              fontSize: "30px",
              fontWeight: "900px",
            }}
          >
            <strong>
              Officials
              <EditModal fluid event={event} card={card} />
            </strong>
            {!event.officials ||
            event.officials.length === 0 ||
            (event.officials[0].name === "" &&
              event.officials[1].name === "" &&
              event.officials[2].name === "") ? (
              <strong style={{ color: "red", marginLeft: "1rem" }}>
                *Required (1 or more)
              </strong>
            ) : (
              <Icon
                name="check circle"
                style={{ color: "green", marginLeft: "1rem" }}
              />
            )}
          </h1>
          {event.officials &&
            event.officials.map((official, idx) => (
              <>
                {official.name !== "" && (
                  <>
                    <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                      <strong>{official.title}</strong>
                    </h3>
                    <Card.Content style={{ textAlign: "left" }}>
                      <p>{official.name}</p>
                    </Card.Content>
                  </>
                )}
              </>
            ))}
        </Container>
      </Card>
    </div>
  );
};

export default MgrOfficials;
