import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStalls,
  fetchPMStalls,
  fetchAMStalls,
} from "../../features/stalls/stallsSlice";
import { fetchRVs } from "../../features/RVs/RVsSlice";
import { Card, Container } from "semantic-ui-react";
import "../../App.css";
import moment from "moment";
import _ from "lodash";

const Info = (props) => {
  const dispatch = useDispatch();
  const { event } = props;
  const AMstalls = useSelector((state) => state.stalls.AMstalls);
  const AMstallMin = moment
    .utc(useSelector((state) => state.stalls.AMminDate))
    .format("MMMM D, YYYY");
  const AMstallMax = moment
    .utc(useSelector((state) => state.stalls.AMmaxDate))
    .format("MMMM D, YYYY");

  const PMstalls = useSelector((state) => state.stalls.PMstalls);
  const PMstallMin = moment
    .utc(useSelector((state) => state.stalls.PMminDate))
    .format("MMMM D, YYYY");
  const PMstallMax = moment
    .utc(useSelector((state) => state.stalls.PMmaxDate))
    .format("MMMM D, YYYY");

  const campings = useSelector((state) => state.RVs.RVs);
  const minRVDate = useSelector((state) => state.RVs.minDate);
  const maxRVDate = useSelector((state) => state.RVs.maxDate);

  const rvMin = moment.utc(minRVDate).format("MMMM D, YYYY");
  const rvMax = moment.utc(maxRVDate).format("MMMM D, YYYY");

  const types = event.combinedEvents;
  const compEvents = event.EventTypes;
  const typeDates = new Set();
  types &&
    types.map((type) => {
      typeDates.add(type.date);
    });

  const datesArray = [...typeDates];
  const sortedDates = datesArray.sort();

  const competitives = [];

  useEffect(() => {
    dispatch(fetchStalls(event))
      .then(() => dispatch(fetchAMStalls(event)))
      .then(() => dispatch(fetchPMStalls(event)));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(fetchRVs(event));
  }, []);

  // const stallDayDates = new Set();
  // const stallNightDates = new Set();
  // stalls &&
  //   stalls.forEach((stall) => {
  //     return stall.isOvernight === false
  //       ? stallDayDates.add(stall.date)
  //       : stallNightDates.add(stall.date);
  //   });
  // const stallDayDatesArray = [...stallDayDates];
  // const stallNightDatesArray = [...stallNightDates];
  // const sortedDayStallDates = [...stallDayDatesArray]
  //   .sort()
  //   .filter((date) => date != null);
  // const sortedNightStallDates = [...stallNightDatesArray]
  //   .sort()
  //   .filter((date) => date != null);

  //   console.log(sortedDayStallDates)
  //   console.log(sortedNightStallDates)
  return (
    <div>
      {/* ---------------------------------------------------------------------EVENT INFO----------------------------------------------------------------- */}
      <div className="event-subview-title-area">
        <h2>Event Information</h2>
      </div>
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1 style={{ textAlign: "left" }}>
            <strong>Event Info</strong>
          </h1>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Description</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {event.description ? (
              <p>{event.description}</p>
            ) : (
              <p>No Description Given</p>
            )}
            {event.id === 1 && (
              <em style={{ textAlign: "left" }}>
                EVENT FULL. Email Pearl@pearlsoffice.com for waitlist
              </em>
            )}
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Competition Types</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {compEvents &&
                compEvents.map((event) => {
                  if (event.isCompetitive) {
                    competitives.push(event);
                    return (
                      <span style={{ display: "block" }}>{event.name}</span>
                    );
                  }
                  return <></>;
                })}
              {competitives.length ? (
                <></>
              ) : (
                <>There are no Competitive Events</>
              )}
            </p>
          </Card.Content>
        </Container>
      </Card>
      {/* ---------------------------------------------------------------------LOCATION INFO----------------------------------------------------------------- */}
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1 style={{ textAlign: "left" }}>
            <strong>Location, Parking & Maps</strong>
          </h1>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Address</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {event.address ? <p>{event.address}</p> : <p>No Address Given</p>}
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Parking</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {event.parkingDescription ? (
              <p>{event.parkingDescription}</p>
            ) : (
              <p>No Parking Information Given</p>
            )}
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Maps</strong>
          </h3>
          {event.propertyMapUrl !== null &&
          event.crossCountryMapUrl !== null &&
          event.showJumpingMapUrl !== null ? (
            <>
              <Card.Content style={{ textAlign: "left" }}>
                <p>
                  {event.propertyMapUrl && (
                    <>
                      <a
                        href={`${event.propertyMapUrl}`}
                        style={{ color: "black", textDecoration: "underline" }}
                      >
                        View Property Map
                      </a>
                      <br />
                    </>
                  )}
                  {event.crossCountryMapUrl && (
                    <>
                      <a
                        href={`${event.crossCountryMapUrl}`}
                        style={{ color: "black", textDecoration: "underline" }}
                      >
                        View Cross Country Map
                      </a>
                      <br />
                    </>
                  )}
                  {event.showJumpingMapUrl && (
                    <>
                      <a
                        href={`${event.showJumpingMapUrl}`}
                        style={{ color: "black", textDecoration: "underline" }}
                      >
                        View Show Jumping Map
                      </a>
                      <br />
                    </>
                  )}
                </p>
              </Card.Content>
            </>
          ) : (
            <>
              <Card.Content style={{ textAlign: "left" }}>
                <p>No Maps Given</p>
              </Card.Content>
            </>
          )}
        </Container>
      </Card>
      {/* ---------------------------------------------------------------------REGISTRATION INFO----------------------------------------------------------------- */}
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1 style={{ textAlign: "left" }}>
            <strong>Registration Info</strong>
          </h1>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Registration Open</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {moment(event.registrationOpen, "YYYY-MM-DD HH:mm:ss A").format(
                "MMMM D, YYYY"
              )}
            </p>
          </Card.Content>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Registration Closed</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {moment(event.registrationClose, "YYYY-MM-DD HH:mm:ss A").format(
                "MMMM D, YYYY"
              )}
            </p>
          </Card.Content>
          {event.fees ? (
            <>
              {event.fees.map((fee, idx) => {
                if (!fee.name && !fee.price) {
                  return (
                    <Card.Content key={idx}style={{ textAlign: "left" }}>
                      <p>Incorrect Fee Information</p>
                    </Card.Content>
                  );
                }
                return (
                  <>
                    <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                      <strong>{_.startCase(fee.name)} Fee</strong>
                    </h3>
                    <Card.Content key={idx} style={{ textAlign: "left" }}>
                      <p>${fee.price}</p>
                    </Card.Content>
                  </>
                );
              })}
            </>
          ) : (
            <Card.Content style={{ textAlign: "left" }}>
              <br />
              <p>
                <strong>No Fees Listed</strong>
              </p>
            </Card.Content>
          )}
        </Container>
      </Card>
      {/* ---------------------------------------------------------------------OFFICIAL INFO----------------------------------------------------------------- */}
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1 style={{ textAlign: "left" }}>
            <strong>Officials</strong>
          </h1>

          {event.officials ? (
            <>
              {event.officials.map((official, idx) => {
                if (!official.title && !official.name) {
                  return (
                    <Card.Content key={idx} style={{ textAlign: "left" }}>
                      <p>Incorrect Official Information</p>
                    </Card.Content>
                  );
                }
                return (
                  <>
                    <h3  key={idx} style={{ textAlign: "left", marginBottom: 2 }}>
                      <strong>{_.startCase(official.title)}</strong>
                    </h3>
                    <Card.Content style={{ textAlign: "left" }}>
                      <p>{official.name}</p>
                    </Card.Content>
                  </>
                );
              })}
            </>
          ) : (
            <Card.Content style={{ textAlign: "left" }}>
              <p>No Officials Listed</p>
            </Card.Content>
          )}
        </Container>
      </Card>
      {/* ---------------------------------------------------------------------SCHEDULE INFO----------------------------------------------------------------- */}
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1 style={{ textAlign: "left" }}>
            <strong>Tentative Schedule of Events</strong>
          </h1>
          {sortedDates.length ? (
            <>
              {sortedDates.map((sortedDate) => {
                const date = moment(sortedDate).format("MMMM D, YYYY");
                return (
                  <>
                    <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                      <strong>
                        {date === "Invalid date" ? (
                          <>No Date Assigned To This Event</>
                        ) : (
                          <>{date}</>
                        )}
                      </strong>
                    </h3>
                    {types &&
                      types.map((type) => {
                        const thisDate = moment(type.date).format(
                          "MMMM D, YYYY"
                        );
                        if (thisDate === date) {
                          return (
                            <Card.Content style={{ textAlign: "left" }}>
                              <p>{type.name}</p>
                            </Card.Content>
                          );
                        }
                        return <></>;
                      })}
                  </>
                );
              })}
            </>
          ) : (
            <Card.Content style={{ textAlign: "left" }}>
              <p>No Events Listed</p>
            </Card.Content>
          )}
        </Container>
      </Card>
      {/* ---------------------------------------------------------------------CANCEL INFO----------------------------------------------------------------- */}
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1 style={{ textAlign: "left" }}>
            <strong>Cancellation Policies</strong>
          </h1>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Rider Cancellation Policy</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {event.riderCancellationPolicy ? (
              <p>{event.riderCancellationPolicy}</p>
            ) : (
              <p>No Rider Cancellation Policy Given</p>
            )}
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Event Cancellation Policy</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {event.eventCancellationPolicy ? (
              <p>{event.eventCancellationPolicy}</p>
            ) : (
              <p>No Event Cancellation Policy Given</p>
            )}
          </Card.Content>
        </Container>
      </Card>

      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1 style={{ textAlign: "left" }}>
            <strong>Required Documents</strong>
          </h1>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Required Documents</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.requiredRiderDocuments ? (
                <>
                  {event.requiredRiderDocuments.map((doc) => {
                    return <span style={{ display: "block" }}>{doc}</span>;
                  })}
                </>
              ) : (
                <>No Required Documents Listed</>
              )}
            </p>
          </Card.Content>
        </Container>
      </Card>
      {/* ---------------------------------------------------------------------STALL INFO----------------------------------------------------------------- */}
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1 style={{ textAlign: "left" }}>
            <strong>Stall Information</strong>
          </h1>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Overnight Dates Available</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {PMstalls ? (
              <p>{`${PMstallMin}-${PMstallMax}`}</p>
            ) : (
              <p>No Day Stalls Available</p>
            )}
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Overnight Cost</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.stallOvernightFee && event.stallOvernightFee > 0
                ? "$" + event.stallOvernightFee + " / night"
                : ""}
            </p>
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Daytime Dates Available</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {AMstalls ? (
              <p>{`${AMstallMin}-${AMstallMax}`}</p>
            ) : (
              <p>No Day Stalls Available</p>
            )}
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Daytime Cost</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.stallDaytimeFee && event.stallDaytimeFee > 0
                ? "$" + event.stallDaytimeFee + " / day"
                : ""}
            </p>
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Additional Stall Information</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {event.stallAdditionalInfo ? (
              <p>{event.stallAdditionalInfo}</p>
            ) : (
              <p>No Stall Information Given</p>
            )}
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Cost of Additional Shavings</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>${event.stallShavingFees} per bag</p>
          </Card.Content>
        </Container>
      </Card>
      {/* ---------------------------------------------------------------------CAMPING INFO----------------------------------------------------------------- */}
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1 style={{ textAlign: "left" }}>
            <strong>Camping & RV Information</strong>
          </h1>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Dates Available</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {minRVDate !== 0 && maxRVDate !== 0 ? (
              <p>{`${rvMin}-${rvMax}`}</p>
            ) : (
              <p>No Dates Given</p>
            )}
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Cost</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              VIP RV Hookup: ${event.rvVipOvernightFee} / night
              <br />
              RV Hookup: ${event.rvOvernightFee} / night
            </p>
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Additional Camping Information</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {event.rvAdditionalInfo ? (
              <p>{event.rvAdditionalInfo}</p>
            ) : (
              <p>No Camping Information Given</p>
            )}
          </Card.Content>
        </Container>
      </Card>

      {/* ---------------------------------------------------------------------ORGANIZER INFO----------------------------------------------------------------- */}
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1 style={{ textAlign: "left" }}>
            <strong>Organizer</strong>
          </h1>

          {event.eventOrganizerName && event.eventOrganizerEmail && (
            <>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Name</strong>
              </h3>
              <Card.Content style={{ textAlign: "left" }}>
                <p>{event.eventOrganizerName}</p>
              </Card.Content>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Email</strong>
              </h3>
              <Card.Content style={{ textAlign: "left" }}>
                <p>{event.eventOrganizerEmail}</p>
              </Card.Content>
              <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                <strong>Phone</strong>
              </h3>
              <Card.Content style={{ textAlign: "left" }}>
                <p>
                  {event.eventOrganizerPhone
                    ? event.eventOrganizerPhone
                    : "No phone number provided"}
                </p>
              </Card.Content>
            </>
          )}
        </Container>
      </Card>
    </div>
  );
};

export default Info;
