import React from "react";

import "../../../App.css";
import { useSelector } from "react-redux";
import LogInCard from "./LogInCard";
import EntryStartCard from "./EntryStartCard";

const Entry = ({ event }) => {
  const user = useSelector((state) => state.users.activeUser);
  const userLoggedIn = user && user.accessToken;
  const eventId = event.id;
  return (
    <div>
      <div className="event-subview-title-area">
        <h2>Entry & Status</h2>
      </div>
      {userLoggedIn ? (
        <>
          <EntryStartCard event={event} />
        </>
      ) : (
        <>
          <LogInCard event={event} />
        </>
      )}
    </div>
  );
};

export default Entry;
