import React, { useState } from "react";
import { Button, Modal, Icon } from "semantic-ui-react";

import AddHorseForm from "../UserForms/addHorseForm";

function EditHorseModal(props) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const horse = props.data;
  return (
    <Modal
      size="mini"
      style={{ borderRadius: 10 }}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <Button
          floated="right"
          center
          style={{ borderRadius: 20, color: "black", padding: 10 }}
        >
          <img
            style={{ marginRight: 5, marginBottom: 2 }}
            src="./Edit v1.svg"
            alt=""
          />
          Edit
        </Button>
      }
    >
      <div style={{ margin: 25 }}>
        <h1 floated="left" bold>
          Horse Details{" "}
          <Button
            floated="right"
            style={{ borderRadius: 25, color: "black", padding: 10 }}
            onClick={handleClose}
          >
            <Icon name="cancel" />
            Cancel
          </Button>
        </h1>
        <AddHorseForm handleClose={handleClose} horse={horse} />
      </div>
    </Modal>
  );
}

export default EditHorseModal;
