import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Container, Icon } from "semantic-ui-react";
import { uploadEventImage } from "../../features/events/eventsSlice";
import { fetchEvent, updateEvent } from "../../features/events/eventsSlice";
import EditModal from "./EventEditForms/EditModal";
import moment from "moment";
// useEffect(() => {
//   dispatch(fetchEvent(eventId));
// }, []);

const MgrSchedule = ({ eventId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const event = useSelector((state) => state.events.event);

  const types = event.combinedEvents;
  const compEvents = event.EventTypes;
  const typeDates = new Set();
  types &&
    types.map((type) => {
      typeDates.add(type.date);
    });

  const datesArray = [...typeDates];
  const sortedDates = datesArray.sort();
  let card = "Schedule";
  return (
    <div>
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1 style={{ textAlign: "left", marginBottom: 0 }}>
            <strong>Schedule of Events</strong>
            {Array.isArray(event.combinedEvents) &&
            event.combinedEvents.length < 1 ? (
              <strong style={{ color: "red", marginLeft: "1rem" }}>
                *Required
              </strong>
            ) : (
              <Icon
                name="check circle"
                style={{ color: "green", marginLeft: "1rem" }}
              />
            )}
            <EditModal fluid event={event} card={card} />
          </h1>
          {sortedDates.length ? (
            <>
              {sortedDates.map((sortedDate) => {
                const date = moment(sortedDate).format("MMMM D, YYYY");
                return (
                  <>
                    <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                      <strong>
                        {date === "Invalid date" ? (
                          <>No Date Assigned To This Event</>
                        ) : (
                          <>{date}</>
                        )}
                      </strong>
                    </h3>
                    {types &&
                      types.map((type, idx) => {
                        const thisDate = moment(type.date).format(
                          "MMMM D, YYYY"
                        );
                        if (thisDate === date) {
                          return (
                            <Card.Content
                              key={idx}
                              style={{ textAlign: "left" }}
                            >
                              <p>
                                {type.name}
                                <Button
                                  floated="right"
                                  size="mini"
                                  style={{
                                    borderRadius: 25,
                                    backgroundColor: "#ff8080",
                                    color: "black",
                                    padding: 5,
                                  }}
                                  onClick={() => {
                                    let values = {
                                      id: event.id,
                                      name: event.name,
                                      facilityName: event.facilityName,
                                      description: event.description,
                                      combinedEvents: [
                                        ...types.filter((el) => el !== type),
                                      ],
                                    };
                                    dispatch(
                                      updateEvent({ user, event, values })
                                    ).then(() => dispatch(fetchEvent(eventId)));
                                  }}
                                >
                                  <Icon name="trash alternate outline" />
                                  Delete
                                </Button>
                              </p>
                            </Card.Content>
                          );
                        }
                        return <></>;
                      })}
                  </>
                );
              })}
            </>
          ) : (
            <Card.Content style={{ textAlign: "left" }}>
              <p>No Events Listed</p>
            </Card.Content>
          )}
        </Container>
      </Card>
    </div>
  );
};

export default MgrSchedule;
