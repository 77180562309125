import React from "react";
import { Container } from "semantic-ui-react";

import "../App.css";
import LogInCard from "../Components/LogInCard/LogInCard";

const LogIn = () => {
  return (
    <div>
      <Container fluid>
        <LogInCard />
      </Container>
    </div>
  );
};

export default LogIn;
