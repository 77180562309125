import React, { useEffect } from "react";
import { Button, Form, Header, Icon, Dimmer, Loader } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Redirect, Link } from "react-router-dom";
import { loginUser, resetErrors } from "../../features/users/userSlice";

const LogInForm = () => {
  const dispatch = useDispatch();
  const fetchStatus = useSelector((state) => state.users.fetchStatus);
  const user = useSelector((state) => state.users.activeUser);
  const userLoggedIn = user && user.accessToken;
  const error = useSelector((state) => state.users.error);

  useEffect(() => {
    dispatch(resetErrors());
  }, []);

  return (
    <div>
      {userLoggedIn ? <Redirect to="/profile" /> : null}
      {fetchStatus === "loading" && (
        <Dimmer active page>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <Formik
        initialValues={{ email: "", password: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(loginUser(values));
          setSubmitting(fetchStatus === "loading");
    
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <>
            {error && (
              <Header color="red" as="h4">
                {error + "."}
                <br />

                <div>
                  <Link to="./forgot-password">
                    Forgot Password? Click here
                  </Link>
                </div>
              </Header>
            )}
            <Form onSubmit={handleSubmit}>
              <Header floated="left" style={{ marginBottom: 2 }}>
                Email Address
              </Header>
              <Form.Input
                style={{ height: 50, borderRadius: 10, fontSize: "16px" }}
                iconPosition="left"
                placeholder="E-mail address"
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              >
                <Icon className="user" />
                <input style={{ borderRadius: 10 }} />
              </Form.Input>
              {errors.email && touched.email && errors.email}

              <Header floated="left" style={{ marginBottom: 2 }}>
                Password
              </Header>
              <Form.Input
                style={{ height: 50, borderRadius: 1, fontSize: "16px" }}
                iconPosition="left"
                placeholder="Password"
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              >
                <Icon className="lock" />
                <input style={{ borderRadius: 10 }} />
              </Form.Input>
              {errors.password && touched.password && errors.password}
              <br />
              <Button
                style={{
                  backgroundColor: "#ffdea2",
                  height: 50,
                  borderRadius: 10,
                }}
                fluid
                black
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default LogInForm;
