import React, { useState } from "react";
import "../../../App.css";
import Button from "./Button";
import { useDispatch, useSelector } from "react-redux";
import { Form, Icon, Card, Container } from "semantic-ui-react";
import { Formik } from "formik";
import { updateRider } from "../../../features/eventRiders/eventRidersSlice";

const WaiverForm = ({ nextStep, event, menu }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const rider = useSelector((state) => state.riders.rider);
  // const returningRider = event.EventRiders.find(eventRider => {
  //   return eventRider.userId === user.id;
  // });
  let riderId = rider.id;
  const [click, setClick] = useState(false);
  if (!rider) {
    return <>{nextStep(4)}</>;
  }
  const handleWaiver = (e, { values }) => {
    setClick(!click);
    values.waiverSigned = true;
    dispatch(updateRider({ user, riderId, values }));
    return values;
  };

  return (
    <Card fluid className="entry-card">
      <Container fluid className="profile-container">
        <div className="main-content-cards">
          <h1 className="pearl-text big regular">Event Registration</h1>
          <div className="event-nav">{menu}</div>

          <div className="registration-step-area">
            <div className="walkthrough-max-width">
              <Formik
                initialValues={{
                  waiverSigned: "",
                }}
                onSubmit={(values) => {
                  dispatch(updateRider({ user, riderId, values }));
                  nextStep(6);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="field-label big">Sign Your Waiver</div>
                    <p style={{ textAlign: "left", marginBottom: "10px" }}>
                      To finalize your entry,{" "}
                      <strong>
                        you must sign the Event Waiver using the link below.
                      </strong>{" "}
                      Failure to complete all steps may result in not being
                      registered for the event.
                    </p>

                    <a
                      href={`${event.waiverUrl}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <Button
                        name=""
                        value=""
                        type="submit"
                        onClick={(e) => handleWaiver(e, { values })}
                      >
                        <div>Click to Sign Event Waiver</div>
                        <Icon name="chevron right" />
                      </Button>
                    </a>

                    {click ? (
                      <button className="submit-button" type="submit">
                        Continue
                      </button>
                    ) : (
                      <button
                        className="submit-button"
                        type="button"
                        style={{ backgroundColor: "#D3D3D3" }}
                        disabled
                      >
                        Please Sign Waiver
                      </button>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </Card>
  );
};

export default WaiverForm;
