import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Header } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../App.css";

import { Formik, Field } from "formik";
import { updateEvent } from "../../../features/events/eventsSlice";
import {
  fetchTypes,
  updateEventType,
} from "../../../features/eventTypes/eventTypesSlice";

const tests = [
  "Intermediate Test A 2022",
  "Intermediate Test B 2022",
  "Advanced Test A 2022",
  "Advanced Test B 2022",
  "Preliminary Test A 2022",
  "Preliminary Test B 2022",
  "Training Test A 2022",
  "Training Test B 2022",
  "Novice Test A 2022",
  "Novice Test B 2022",
  "Beginner Novice Test A 2022",
  "Beginner Novice Test B 2022",
  "Intro Test A 2019",
  "Intro Test B 2019",
  "Intro Test C 2019",
  "Training Level Test 1 2019",
  "Training Level Test 2 2019",
  "Training Level Test 3 2019",
  "First Level Test 1 2019",
  "First Level Test 2 2019",
  "First Level Test 3 2019",
  "Starter Test A 2022",
  "Starter Test B 2022",
  "Preliminary Test C 2022",
  "Modified Test A 2022",
  "Modified Test B 2022",
  "Modified Test C 2022",
  "Novice Three Day 2022",
  "Training Three Day 2022",
  "Modified Three Day 2022",
  "Preliminary Three Day 2022",
];

const tests2 = [
  "Second Level Test 1 2019",
  "Second Level Test 2 2019",
  "Second Level Test 3 2019",
  "Third Level Test 1 2019",
  "Third Level Test 2 2019",
  "Third Level Test 3 2019",
  "Fourth Level Test 1 2019",
  "Fourth Level Test 2 2019",
  "Fourth Level Test 3 2019",
  "WDAA Intro Level 1",
  "WDAA Intro Level 2",
  "WDAA Intro Level 3",
  "WDAA Intro Level 4",
  "WDAA Basic Level 1",
  "WDAA Basic Level 2",
  "WDAA Basic Level 3",
  "WDAA Basic Level 4",
  "WDAA Level 1 Test 1",
  "WDAA Level 1 Test 2",
  "WDAA Level 1 Test 3",
  "WDAA Level 1 Test 4",
  "WDAA Level 2 Test 1",
  "WDAA Level 2 Test 2",
  "WDAA Level 2 Test 3",
  "WDAA Level 2 Test 4",
  "WDAA Level 3 Test 1",
  "WDAA Level 3 Test 2",
  "WDAA Level 3 Test 3",
  "WDAA Level 3 Test 4",
  "WDAA Level 4 Test 1",
  "WDAA Level 4 Test 2",
  "WDAA Level 4 Test 3",
  "WDAA Level 4 Test 4",
  "WDAA Basic Level Musical Freestyle",
  "WDAA Intro Level Musical Freestyle",
  "WDAA Level 1 Musical Freestyle",
  "WDAA Level 2 Musical Freestyle",
  "WDAA Level 3 Musical Freestyle",
  "WDAA Level 4 Musical Freestyle",
];

const EditDressageOnlyForm = ({ event, type, handleClose }) => {
  const [selectedTests, setSelectedTests] = useState([]);
  const [combinedEvents, setCombinedEvents] = useState([
    ...event.combinedEvents,
  ]);
  event.combinedEvents ? console.log("yep") : console.log("nope");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  console.log(user.email, user.firstName);

  const typeId = type.id;
  const eventId = event.id;

  const handleTest = (e, { values }, test) => {
    if (values.tests.some((el) => el === test)) {
      let idx = values.tests.findIndex((el) => el === test);

      values.test.splice(idx, 1);
    } else {
      values.tests = [...values.tests, test];
      //   setSelectedTests([...selectedTests, tests]);

      console.log("It has been selected 1st", selectedTests);
    }

    // console.log("selectedTests", selectedTests);
    console.log("values.tests", values.tests);
  };
  return (
    <section>
      <br />
      <Formik
        initialValues={{
          id: type.id,
          perTestFee: type.perTestFee,
          maxTests: type.maxTests,
          tests: [],
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log("values", values);

          dispatch(updateEventType({ user, typeId, values }))
            .then(
              () => (values.id = event.id),
              (values.name = event.name),
              (values.facilityName = event.facilityName),
              (values.description = event.description),
              (values.combinedEvents = [...combinedEvents]),
              dispatch(updateEvent({ user, event, values }))
            )
            .then(() => dispatch(fetchTypes(eventId)));
          setSubmitting(false);
          handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Per Test Fee
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="perTestFee"
              type="perTestFee"
              onChange={handleChange}
              placeholder="$100"
              id="perTestFee"
              value={values.perTestFee}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Date For Dressage
            </Header>
            <DatePicker
              style={{ borderRadius: 10 }}
              selected={values.dressage}
              dateFormat="MMMM d, yyyy"
              name="dressage"
              onChange={(date) => {
                setFieldValue("dressage", date);
                combinedEvents.some((el) => el.name === "Dressage") === true
                  ? setCombinedEvents([
                      ...combinedEvents.filter((el) => el.name !== "Dressage"),
                      { name: "Dressage", date: date },
                    ])
                  : setCombinedEvents([
                      ...combinedEvents,
                      { name: "Dressage", date: date },
                    ]);
              }}
            />
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Maximum Tests Allowed Per Competitor
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="maxTests"
              type="maxTests"
              onChange={handleChange}
              placeholder="#"
              id="maxTests"
              value={values.maxTests}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Header as="h4" floated="left" style={{ marginBottom: 0 }}>
              Tests Offered
            </Header>
            <br />
            <br />
            <br />
            <div>
              <Form.Field>
                {tests.map((test, index) => {
                  return (
                    <>
                      <div key={index}>
                        <label>
                          <Field type="checkbox" name="tests" value={test} />
                          {"    "}
                          {test}
                        </label>
                      </div>
                      <br />
                    </>
                  );
                })}

                {tests2.map((test, index) => {
                  return (
                    <>
                      <div key={index}>
                        <label>
                          <Field type="checkbox" name="tests" value={test} />
                          {"    "}
                          {test}
                        </label>
                      </div>
                      <br />
                    </>
                  );
                })}
              </Form.Field>
            </div>
            <br />
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Save & Close
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EditDressageOnlyForm;
