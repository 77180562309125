import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../api/client";

const initialState = {
  status: "",
  fetchStatus: "",
  createStatus: "",
  events: [{}],
  stalls: [{}],
  rvs: [{}],
  event: [{}],
};

export const fetchEvents = createAsyncThunk("events/fetchEvents", async () => {
  const response = await client.get(`events?filter={"isPublished":"true"}&limit=30`);
  console.log(response);
  return response.events;
});
export const fetchEvent = createAsyncThunk(
  "events/fetchEvent",
  async (data) => {
    const response = await client.get(`events/${data}`);

    return response;
  }
);
export const createEvent = createAsyncThunk(
  "events/createEvent",
  async (data) => {
    const response = await client.post("events", data.values, {
      headers: { authorization: data.user.accessToken },
    });
    return response;
  }
);
export const fetchMgrEvents = createAsyncThunk(
  "events/fetchMgrEvents",
  async (data) => {
    const response = await client.get(
      `events?filter={"eventOrganizerEmail":"${data}"}`
    );
    console.log(response.events);
    return response.events;
  }
);
export const fetchEventStalls = createAsyncThunk(
  "events/fetchEventStalls",
  async (data) => {
    const response = await client.get(`events/${data.event.id}/stalls`);
    return response.stalls;
  }
);
export const fetchEventRVs = createAsyncThunk(
  "events/fetchEventRVs",
  async (data) => {
    const response = await client.get(`events/${data.event.id}/rv-camping`);
    return response.rvCamping;
  }
);
export const updateEvent = createAsyncThunk(
  "events/updateEvent",
  async (data) => {
    const response = await client.put(`events/${data.event.id}`, data.values, {
      headers: { authorization: data.user.accessToken },
    });

    return response;
  }
);

export const uploadEventImage = (data) => async (dispatch) => {
  console.log("upload data: ", data.file);
  console.log("eventId: ", data.event.id);
  const body = {
    fileName: data.file.name,
    fileType: data.file.type,
  };
  const uploadResponse = await client.post(
    `events/${data.event.id}/banner-image`,
    body,
    {
      headers: { authorization: data.user.accessToken },
    }
  );
  const { upload } = uploadResponse;

  if (!upload || !upload.uploadURL) {
    //dispatch error reducer here
  }

  const fileResponse = await fetch(upload.uploadURL, {
    method: "PUT",
    body: data.file,
    params: {
      Key: data.file.name,
      ContentType: data.file.type,
    },
    headers: {
      "Content-Type": data.file.type,
    },
  });

  dispatch(
    updateEvent({
      user: data.user,
      event: data.event,
      values: {
        name: data.event.name,
        imgUrl: upload.url,
        description: data.event.description,
      },
    })
  );
};

export const uploadEventDocument = (data) => async (dispatch) => {
  console.log("upload data: ", data.file);
  console.log("eventId: ", data.eventId);
  console.log("event: ", data.event);

  const body = {
    fileName: data.file.name,
    fileType: data.file.type,
  };
  const uploadResponse = await client.post(
    `events/${data.event.id}/banner-image`,
    body,
    {
      headers: { authorization: data.user.accessToken },
    }
  );
  const { upload } = uploadResponse;
  console.log("upload ", upload);

  if (!upload || !upload.uploadURL) {
    //dispatch error reducer here
  }

  const fileResponse = await fetch(upload.uploadURL, {
    method: "PUT",
    body: data.file,
    params: {
      Key: data.file.name,
      ContentType: data.file.type,
    },
    headers: {
      "Content-Type": data.file.type,
    },
  });

  data.documentType === "propertyMapUrl" &&
    dispatch(
      updateEvent({
        user: data.user,
        event: data.event,
        values: {
          name: data.event.name,
          description: data.event.description,
          propertyMapUrl: upload.url,
        },
      })
    );
  data.documentType === "crossCountryMapUrl" &&
    dispatch(
      updateEvent({
        user: data.user,
        event: data.event,
        values: {
          name: data.event.name,
          description: data.event.description,
          crossCountryMapUrl: upload.url,
        },
      })
    );
  data.documentType === "showJumpingMapUrl" &&
    dispatch(
      updateEvent({
        user: data.user,
        event: data.event,
        values: {
          name: data.event.name,
          description: data.event.description,
          showJumpingMapUrl: upload.url,
        },
      })
    );
};

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    resetEvents: (state) => initialState,
  },
  extraReducers: {
    [createEvent.pending]: (state, action) => {
      state.createStatus = "loading";
      state.error = action.payload;
    },
    [createEvent.fulfilled]: (state, action) => {
      state.createStatus = "succeeded";
      state.event = action.payload;
    },
    [createEvent.rejected]: (state, action) => {
      state.createStatus = "failed";
      state.error = action.error.message;
    },
    [updateEvent.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [updateEvent.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.event = action.payload;
    },
    [updateEvent.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.error.message;
    },
    [fetchEvents.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchEvents.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.events = action.payload;
    },
    [fetchEvents.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
    },
    [fetchEvent.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchEvent.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.event = action.payload;
    },
    [fetchEvent.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
    },
    [fetchMgrEvents.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchMgrEvents.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.events = action.payload;
    },
    [fetchMgrEvents.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
    },
    [fetchEventStalls.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchEventStalls.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.stalls = action.payload;
    },
    [fetchEventStalls.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
    },
    [fetchEventRVs.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchEventRVs.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.RVs = action.payload;
    },
    [fetchEventRVs.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
    },
  },
});
export const { resetEvents } = eventsSlice.actions;
export default eventsSlice.reducer;
