import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Grid, Image, Header } from "semantic-ui-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Redirect } from "react-router-dom";

import { Formik } from "formik";

import { createStall } from "../../../features/stalls/stallsSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Dimmer, Loader } from "semantic-ui-react";

const EditDayStalls = ({ event, setDayShow, setPriceShow }) => {
  const [isLoading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [numStalls, setNumStalls] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);

  return (
    <section>
      {isLoading && (
        <Dimmer active page>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <br />
      <Formik
        initialValues={{
          eventId: event.id,
          date: null,
          isOvernight: false,
          barnName: ""
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          let enumerateDaysBetweenDates = function (startDate, endDate) {
            let dates = [];
            dates.push(startDate);
            let currDate = moment(startDate).startOf("day");
            let lastDate = moment(endDate).startOf("day");

            while (currDate.add(1, "days").diff(lastDate) < 0) {
              dates.push(currDate.clone().toDate());
            }
            dates.push(endDate);

            return dates;
          };
          enumerateDaysBetweenDates(startDate, endDate).map((day) => {
            if (numStalls === 0) setLoading(false);
            if (startDate === null) setLoading(false);
            if (endDate === null) setLoading(false);
            for (let i = 0; i < numStalls; i++) {
              values.date = moment(day).format().substr(0, 10);

              dispatch(createStall({ user, values }));
              if (i === numStalls - 1) {
                setLoading(false);
              }
            }
          });
          setPriceShow(true);
          setDayShow(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h3">Step 2: Day Stalls</Header>
            <br/>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Number of Day Stalls Per Day
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              onChange={(e) => setNumStalls(e.target.value)}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>{" "}
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Start Date
            </Header>
            <DatePicker
              selected={startDate}
              style={{ borderRadius: 10 }}
              dateFormat="MMMM d, yyyy"
              name="date"
              onChange={(date) => setStartDate(date)}
            />
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              End Date
            </Header>
            <DatePicker
              selected={endDate}
              style={{ borderRadius: 10 }}
              dateFormat="MMMM d, yyyy"
              name="date"
              onChange={(date) => setEndDate(date)}
            />
            <br />
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,
                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Create Stalls or Skip to Step 3
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EditDayStalls;
