import React, { useState, useEffect } from "react";
import { Card, Container, Modal, Button } from "semantic-ui-react";
import "../../../App.css";
import CompetitorsTable from "./CompetitorsTable";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchHorses } from "../../../features/horses/horsesSlice";
import EventRegMenu from "./EventRegMenu";
import moment from "moment";
// import MultiStep from "./MultiStep";

const EntryStartCard = ({ event }) => {
  const [currentTime, setCurrentTime] = useState();
  const [open, setOpen] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [signUp, setSignUp] = useState(false);
  const user = useSelector((state) => state.users.activeUser);
  const userHorses = useSelector((state) => state.horses.userHorses);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHorses(user));
  }, []);
  
  const closed = event.registrationClose
    ? event.registrationClose.substring(0, 19)
    : "2050-03-17";

  const handleClick = () => {
    setSignUp(true);
  };

  return (
    <>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="small"
      >
        <Modal.Content>
          <p>
            Oops! It looks like you need to add your <strong>horse</strong>{" "}
            before you can enter {event.name} {event.shortDescription}.
            <br />
            <br />
            You can do this in the{" "}
            <Link
              to="/profile"
              style={{ textDecoration: "underline", color: "black" }}
            >
              My Horses
            </Link>{" "}
            section of your profile.
          </p>
          <br />
          <em>
            Don't forget to add: <br />
            <br />
            {event.requiredRiderDocuments &&
              event.requiredRiderDocuments.map((el) =>
                el !== "Medical & Liability Release Waiver" ? (
                  <>
                    {el}
                    <br />
                  </>
                ) : (
                  ""
                )
              )}
          </em>
        </Modal.Content>
        <Modal.Actions>
          <button
            className="event-signup-btn"
            type="button"
            onClick={() => setOpen(false)}
          >
            Sign up later
          </button>
          <button
            className="event-signup-btn"
            type="button"
            onClick={() => setOpen(false)}
          >
            <Link
              to="/profile"
              style={{ textDecoration: "underline", color: "black" }}
            >
              Go To My Profile
            </Link>{" "}
          </button>
        </Modal.Actions>
      </Modal>

      <Modal
        onClose={() => setOpenUser(false)}
        onOpen={() => setOpenUser(true)}
        open={openUser}
        size="small"
      >
        <Modal.Content>
          <p>
            Oops! It looks like you need to add your <strong>full name </strong>
            and <strong>age group </strong>
            before you can enter {event.name} {event.shortDescription}.
            <br />
            <br />
            You can do this in the{" "}
            <Link
              to="/profile"
              style={{ textDecoration: "underline", color: "black" }}
            >
              About Me
            </Link>{" "}
            section of your profile.
          </p>
          <br />
        </Modal.Content>
        <Modal.Actions>
          <button
            className="event-signup-btn"
            type="button"
            onClick={() => setOpenUser(false)}
          >
            Enter later
          </button>
          <button
            className="event-signup-btn"
            type="button"
            onClick={() => setOpenUser(false)}
          >
            <Link
              to="/profile"
              style={{ textDecoration: "underline", color: "black" }}
            >
              Go To My Profile
            </Link>{" "}
          </button>
        </Modal.Actions>
      </Modal>

      {signUp && <EventRegMenu event={event} />}
      {!signUp && (
        <div>
          <Card fluid className="entry-card">
            <Container fluid className="profile-container">
              <Card.Content style={{ textAlign: "center" }}>
                <br />
                <br />
                <div className="peal-walkthough-image">
                  <img
                    className="pearl-icon-large"
                    src="./illustratorPEARLB.png"
                    alt=""
                  />
                </div>
                {moment(closed).isBefore() ? (
                  <>
                    {console.log("regClosed is in the past")}
                    <div className="entry-button-text-large">
                      {" "}
                      Show is closed.
                    </div>
                    <p>Email Pearl@pearlsoffice.com for waitlist</p>

                    <a
                      href={
                        event.liveUpdates
                          ? event.liveUpdates
                          : "https://www.pearlsevent.com/sspc/event-page"
                      }
                      className="event-signup-btn"
                      style={{
                        height: "433px",
                        minWidth: "192px",
                        position: "centered",
                        color: "black",
                      }}
                    >
                      Live Updates
                    </a>
                  </>
                ) : (
                  <>
                    <div className="entry-button-text-large"> Enter Now!</div>
                    <p>Enter {event.name} now!</p>

                    <em
                      style={{
                        textAlign: "left",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Please be prepared to enter your horse's information.
                      <br />
                      <br /> You can add your horses and their related documents
                      in the{" "}
                      <Link
                        to="/profile"
                        style={{
                          textDecoration: "underline",
                          color: "black",
                        }}
                      >
                        My Horses
                      </Link>{" "}
                      section of your profile.
                      <br />
                    </em>
                    <br />
                    <br />
                    <br />
                    {userHorses.length > 0 &&
                    (user.firstName !== null ||
                      user.lastName !== null ||
                      user.ageGroup !== null) ? (
                      <>
                        <button
                          className="event-signup-btn"
                          style={{ width: "192px", position: "centered" }}
                          onClick={handleClick}
                        >
                          Enter Event
                        </button>
                      </>
                    ) : (
                      <>
                        {user.firstName !== null ||
                        user.lastName !== null ||
                        user.ageGroup !== null ? (
                          <button
                            className="event-signup-btn"
                            style={{ width: "192px", position: "centered" }}
                            onClick={() => setOpen(true)}
                          >
                            Enter Event
                          </button>
                        ) : (
                          <button
                            className="event-signup-btn"
                            style={{ width: "192px", position: "centered" }}
                            onClick={() => setOpenUser(true)}
                          >
                            Enter Event
                          </button>
                        )}
                      </>
                    )}

                    <button
                      style={{
                        width: "192px",
                        position: "centered",
                        color: "black",
                        textDecorationStyle: "none",
                      }}
                      className="event-signup-btn"
                    >
                      <a
                        href={
                          event.liveUpdates
                            ? event.liveUpdates
                            : "https://www.pearlsevent.com/sspc/event-page"
                        }
                        className="event-signup-btn"
                        style={{
                          height: "433px",
                          minWidth: "192px",
                          position: "centered",
                          color: "black",
                        }}
                      >
                        Live Updates
                      </a>
                    </button>
                    <br />
                    <br />
                  </>
                )}

                <br />
                <br />
              </Card.Content>
            </Container>
          </Card>
          <CompetitorsTable event={event} />
        </div>
      )}
    </>
  );
};

export default EntryStartCard;
