import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Icon } from "semantic-ui-react";
import { uploadEventImage } from "../../features/events/eventsSlice";
import { fetchEvent } from "../../features/events/eventsSlice";
import EditModal from "./EventEditForms/EditModal";


const MgrOrganizer = ({ eventId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const event = useSelector((state) => state.events.event);

  let card = "Organizer";

  return (
    <div>
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1
            style={{
              textAlign: "left",
              fontFamily: "Lato",
              fontSize: "1.90rem",
              fontWeight: "900px",
            }}
          >
            <strong>
              Organizer
              <EditModal fluid event={event} card={card} />
            </strong>
            {event.eventOrganizerEmail === null ||
            event.eventOrganizerName === null ||
            event.eventOrganizerPhone === null ? (
              <strong style={{ color: "red", marginLeft: "1rem" }}>
                *Required
              </strong>
            ) : (
              <Icon
                name="check circle"
                style={{ color: "green", marginLeft: "1rem" }}
              />
            )}
          </h1>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Name</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.eventOrganizerName
                ? event.eventOrganizerName
                : "*Please enter your name with Edit"}
            </p>
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Email</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.eventOrganizerEmail
                ? event.eventOrganizerEmail
                : "*Please enter your email with Edit"}
            </p>
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Phone</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.eventOrganizerPhone
                ? event.eventOrganizerPhone
                : "*Please enter your phone number with Edit"}
            </p>
          </Card.Content>
        </Container>
      </Card>
    </div>
  );
};

export default MgrOrganizer;
