import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRiders } from "../../../features/eventRiders/eventRidersSlice";
import { Table, Button, Icon, Dimmer, Loader } from "semantic-ui-react";
import "../../../App.css";

const CompetitorsTable = ({ event }) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const ridersComp = useSelector((state) => state.riders.riders);

  useEffect(() => {
    dispatch(fetchRiders({ event, page }));
  }, [page]);

  // if (!ridersComp) {
  //   return (
  //     <div>
  //       <Dimmer active page>
  //         <Loader size="massive">Loading</Loader>
  //       </Dimmer>
  //     </div>
  //   );
  // }

  return (
    <div>
      <div className="event-subview-title-area">
        <h2>Registered Competitors</h2>
      </div>
      <Table
        fixed
        unstackable
        style={{ borderRadius: "10px", height: "326px", marginTop: 0 }}
        celled
      >
        <Table.Body style={{ textAlign: "center" }}>
          <Table.Row>
            <Table.Cell>
              <h4>
                <strong>Rider</strong>
              </h4>
            </Table.Cell>
            <Table.Cell>
              <h4>
                <strong>Horse</strong>
              </h4>
            </Table.Cell>
            <Table.Cell>
              <h4>
                <strong>Height</strong>
              </h4>
            </Table.Cell>
            <Table.Cell>
              <h4>
                <strong>Division</strong>
              </h4>
            </Table.Cell>
          </Table.Row>
          {ridersComp &&
            ridersComp.map((rider, idx) => {
              return (
                rider.paymentStatus !== null &&
                rider.Horse !== null && (
                  <Table.Row key={idx}>
                    <Table.Cell>
                      {rider.User.firstName} {rider.User.lastName}
                    </Table.Cell>
                    <Table.Cell>{rider.Horse.registeredName}</Table.Cell>
                    <Table.Cell>{rider.height}</Table.Cell>
                    <Table.Cell>{rider.User.ageGroup}</Table.Cell>
                  </Table.Row>
                )
              );
            })}
        </Table.Body>
      </Table>
      <div fluid className="event-nav">
        <button
          style={{ border: "none" }}
          className="event-nav-button"
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          <Icon name="chevron left"></Icon>
        </button>
        <button
          style={{ border: "none" }}
          disabled={ridersComp && !ridersComp.length}
          className="event-nav-button"
          onClick={() => setPage(page + 1)}
        >
          <Icon name="chevron right"></Icon>
        </button>
      </div>
    </div>
  );
};

export default CompetitorsTable;
