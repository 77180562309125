import React from 'react'
import {Card, Grid, Header} from "semantic-ui-react";
import { useSelector } from "react-redux";

const SchoolingEntries = () => {
    const event = useSelector((state) => state.events.event);
    const paidRiders = event.EventRiders.filter((rider)=> rider.paymentStatus === "succeeded")
    const schoolingTypes = event.EventTypes.filter((type)=> type.isCompetitive === false);


    let hackingTotal = 0;
    let xcSchoolingTotal = 0;
    let stadiumSchoolingTotal = 0;

    console.log(paidRiders)

    paidRiders && paidRiders.forEach((rider) => {

      if (rider.events.schooling.includes("Hacking"))
        hackingTotal++;

      if (rider.events.schooling.includes("XC Schooling"))
        xcSchoolingTotal++;

      if (rider.events.schooling.includes("Stadium Schooling"))
        stadiumSchoolingTotal++;
  
          
    })

    return (
      <Grid fluid columns={2} style={{ marginBottom: ".75rem" }}>    
            {schoolingTypes.map((type, idx)=> 
            <Grid.Column
              width={8}
              style={{ padding: ".2rem", marginBottom: "-1.5rem" }}
              key={idx}
             >
                  <Card fluid >
                    <Card.Content>
                    <Header as="h5" floated="left" style={{ marginBottom: 2 }}>
                        {type.name}
                      </Header>

                      <Header
                        as="h5" 
                        floated="right"
                        style={{ marginBottom: 2, marginRight: 0 }}
                        >
                        {type.name === "Hacking" && hackingTotal}  
                        {type.name === "XC Schooling" && xcSchoolingTotal}  
                        {type.name === "Stadium Schooling" && stadiumSchoolingTotal} 
                      </Header>
                    </Card.Content>
                  </Card>
               </Grid.Column>
                )}
           
          </Grid>

    )
}

export default SchoolingEntries
