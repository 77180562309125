import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Header, Icon } from "semantic-ui-react";
import { Formik } from "formik";
import { updateEvent } from "../../../features/events/eventsSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const EditScheduleForm = ({ event, handleClose }) => {
  const [count, setCount] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [combinedEvents, setCombinedEvents] = useState([
    ...event.combinedEvents,
  ]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const types = event.combinedEvents;

  return (
    <section>
      <Formik
        initialValues={{}}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log("values", values);
          values.name = event.name;
          values.facilityName = event.facilityName;
          values.description = event.description;
          values.combinedEvents = [...combinedEvents];
          dispatch(updateEvent({ user, event, values }));
          setSubmitting(false);
          handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <div>
              <Header
                as="h4"
                floated="left"
                style={{ marginBottom: 2 }}
              ></Header>

              <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                Date For Cross Country
              </Header>
              <DatePicker
                style={{ borderRadius: 10 }}
                selected={values.crossCountry}
                dateFormat="MMMM d, yyyy"
                name="crossCountry"
                onChange={(date) => {
                  setFieldValue("crossCountry", date);
                  combinedEvents.some((el) => el.name === "Cross Country") ===
                  true
                    ? setCombinedEvents([
                        ...combinedEvents.filter(
                          (el) => el.name !== "Cross Country"
                        ),
                        { name: "Cross Country", date: date },
                      ])
                    : setCombinedEvents([
                        ...combinedEvents,
                        { name: "Cross Country", date: date },
                      ]);
                }}
              />
              <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                Date For Show Jumping
              </Header>
              <DatePicker
                style={{ borderRadius: 10 }}
                selected={values.showJumping}
                dateFormat="MMMM d, yyyy"
                name="showJumping"
                onChange={(date) => {
                  setFieldValue("showJumping", date);
                  combinedEvents.some((el) => el.name === "Show Jumping") ===
                  true
                    ? setCombinedEvents([
                        ...combinedEvents.filter(
                          (el) => el.name !== "Show Jumping"
                        ),
                        { name: "Show Jumping", date: date },
                      ])
                    : setCombinedEvents([
                        ...combinedEvents,
                        { name: "Show Jumping", date: date },
                      ]);
                }}
              />
              <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                Date For Dressage
              </Header>
              <DatePicker
                style={{ borderRadius: 10 }}
                selected={values.dressage}
                dateFormat="MMMM d, yyyy"
                name="dressage"
                onChange={(date) => {
                  setFieldValue("dressage", date);
                  combinedEvents.some((el) => el.name === "Dressage") === true
                    ? setCombinedEvents([
                        ...combinedEvents.filter(
                          (el) => el.name !== "Dressage"
                        ),
                        { name: "Dressage", date: date },
                      ])
                    : setCombinedEvents([
                        ...combinedEvents,
                        { name: "Dressage", date: date },
                      ]);
                }}
              />
              <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                Date For Hacking
              </Header>
              <DatePicker
                style={{ borderRadius: 10 }}
                selected={values.hacking}
                dateFormat="MMMM d, yyyy"
                name="hacking"
                onChange={(date) => {
                  setFieldValue("hacking", date);
                  setCombinedEvents([
                    ...combinedEvents,
                    { name: "Hacking", date: date },
                  ]);
                }}
              />
              <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                Date For XC Schooling
              </Header>
              <DatePicker
                style={{ borderRadius: 10 }}
                selected={values.XCSchooling}
                dateFormat="MMMM d, yyyy"
                name="XCSchooling"
                onChange={(date) => {
                  setFieldValue("XCSchooling", date);
                  setCombinedEvents([
                    ...combinedEvents,
                    { name: "XC Schooling", date: date },
                  ]);
                }}
              />
              <Button
                style={{
                  backgroundColor: "#ffdea2",
                  height: 50,
                  borderRadius: 10,
                }}
                fluid
                type="submit"
              >
                Save & Close
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EditScheduleForm;
