import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Dimmer, Loader } from "semantic-ui-react";
import { fetchHorses } from "../../features/horses/horsesSlice";
import AddHorseModal from "../UserProfileContent/AddHorseModal";
import EditHorseModal from "./EditHorseModal";
import { uploadHorseDocument } from "../../features/horses/horsesSlice";
import "../../App.css";

const Horses = () => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const userHorses = useSelector((state) => state.horses.userHorses);
  const fetchStatus = useSelector((state) => state.horses.fetchStatus);

  useEffect(() => {
    dispatch(fetchHorses(user));
  }, []);

  //how to determine which horse in userHorses?
  const onFileChangeHandler = (event, horseId, documentType) => {
    setLoading(true);
    const file = event.target.files[0];

    dispatch(
      uploadHorseDocument({ user, horseId, documentType, file })
    ).then(() => setLoading(false));
  };

  if (!userHorses) {
    return (
      <div>
        <Dimmer active page>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      </div>
    );
  }

  return (
    <div style={{ height: "100%" }}>
      {/* {fetchStatus === "loading" && (
        <Dimmer active page>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )} */}
      {isLoading && (
        <Dimmer active page>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      {userHorses.length > 0 ? (
        <div className="event-subview-title-area">
          <h2>My Horses</h2>
        </div>
      ) : (
        <div className="event-subview-title-area">
          <h2>You have no horses yet!</h2>
        </div>
      )}

      {userHorses.map((horse) => {
        if (horse) {
          return (
            <>
              <Card key={horse.id} fluid style={{ borderRadius: 20 }}>
                <Container fluid className="profile-container">
                  <h1
                    style={{
                      textAlign: "left",
                      fontFamily: "Lato",
                      fontSize: "30px",
                      fontWeight: "900px",
                    }}
                  >
                    <strong>{horse.registeredName}</strong>
                    <EditHorseModal fluid data={horse} />
                  </h1>

                  <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    <strong>Registered Name</strong>
                  </h3>
                  <Card.Content style={{ textAlign: "left" }}>
                    <p>{horse.registeredName}</p>
                  </Card.Content>

                  <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    <strong>Nickname</strong>
                  </h3>
                  <Card.Content style={{ textAlign: "left" }}>
                    <p>{horse.nickname}</p>
                  </Card.Content>

                  <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    <strong>Year Of Birth</strong>
                  </h3>
                  <Card.Content style={{ textAlign: "left" }}>
                    <p>{horse.yearOfBirth}</p>
                  </Card.Content>

                  <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    <strong>Own or Lease</strong>
                  </h3>
                  <Card.Content style={{ textAlign: "left" }}>
                    <p>{horse.own === true ? "Own" : "Lease"}</p>
                  </Card.Content>

                  <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    <strong>Coggins Document</strong>
                  </h3>
                  <Card.Content style={{ textAlign: "left" }}>
                    <p>
                      {horse.cogginsDocumentUrl
                        ? horse.cogginsDocumentUrl.replace(
                            `https://pearls-office-production.s3.us-east-2.amazonaws.com/pearls-office-horse-${horse.id}/`,
                            ""
                          )
                        : ""}
                    </p>
                  </Card.Content>
                  <br />

                  <label className="custom-file-upload">
                    <input
                      type="file"
                      name="file"
                      accept="image/png,image/jpeg,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      style={{ float: "left", marginBottom: 5, size: 20 }}
                      onChange={(event) =>
                        onFileChangeHandler(
                          event,
                          horse.id,
                          "cogginsDocumentUrl"
                        )
                      }
                    />
                    Upload Coggins Document
                  </label>
                  <br />

                  <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    <strong>Vaccination Document</strong>
                  </h3>
                  <Card.Content style={{ textAlign: "left" }}>
                    <p>
                      {horse.vaccinationDocumentUrl
                        ? horse.vaccinationDocumentUrl.replace(
                            `https://pearls-office-production.s3.us-east-2.amazonaws.com/pearls-office-horse-${horse.id}/`,
                            ""
                          )
                        : ""}
                    </p>
                  </Card.Content>
                  <br />
                  <label className="custom-file-upload">
                    <input
                      type="file"
                      name="file"
                      accept="image/png,image/jpeg,.doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      style={{ float: "left", marginBottom: 10 }}
                      onChange={(event) =>
                        onFileChangeHandler(
                          event,
                          horse.id,
                          "vaccinationDocumentUrl"
                        )
                      }
                    />
                    Upload Vaccination Document
                  </label>
                  <br />

                  <h3 style={{ textAlign: "left", marginBottom: 2 }}>
                    <strong>Health Certificate Document</strong>
                  </h3>
                  <Card.Content style={{ textAlign: "left" }}>
                    <p>
                      {horse.healthCertificateUrl
                        ? horse.healthCertificateUrl.replace(
                            `https://pearls-office-production.s3.us-east-2.amazonaws.com/pearls-office-horse-${horse.id}/`,
                            ""
                          )
                        : ""}
                    </p>
                  </Card.Content>
                  <br />
                  <label className="custom-file-upload">
                    <input
                      type="file"
                      name="file"
                      accept="image/png,image/jpeg,.doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      style={{ float: "left" }}
                      onChange={(event) =>
                        onFileChangeHandler(
                          event,
                          horse.id,
                          "healthCertificateUrl"
                        )
                      }
                    />
                    Upload Health Certificate
                  </label>

                  {/* <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            Horse Memberships
          </h3>
          <Card.Content style={{ textAlign: "left" }}>N/A</Card.Content> */}
                </Container>
              </Card>
              <br />
            </>
          );
        }
      })}
      <Card fluid style={{ borderRadius: 20 }}>
        <Container fluid className="profile-container">
          <h1 style={{ textAlign: "left" }}>
            Add Horse
            <AddHorseModal />
          </h1>
        </Container>
      </Card>
      <br />
    </div>
  );
};

export default Horses;
