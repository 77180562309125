import React, { useState } from "react";
import { Card, Container } from "semantic-ui-react";
import SelectForm from "./SelectForm";
import StallForm from "./StallForm";
import RvForm from "./RvForm";
import HorseForm from "./HorseForm";
import WaiverForm from "./WaiverForm";
import StripeForm from "./StripeForm";
import EntryComplete from "./EntryComplete";

const EventRegMenu = ({ event }) => {
  const [stallIds, setStallIds] = useState([]);
  const [rvIds, setRvIds] = useState([]);

  const [activeItems, setActiveItems] = useState([
    { key: "select", active: true, name: "1. Select Events" },
    { key: "stall", active: false, name: "2. Stall Booking" },
    { key: "rv", active: false, name: "3. RV Booking" },
    { key: "horse", active: false, name: "4. Horse Info" },
    { key: "payment", active: false, name: "5. Payment" },
    { key: "waiver", active: false, name: "6. Sign Waiver" },
    { key: "complete", active: false },
  ]);

  const nextStep = (index) => {
    setActiveItems((prevItems) =>
      prevItems.map((e, i) => ({ ...e, active: i === index }))
    );
  };

  const menu = activeItems.map((item, index) => {
    return (
      index < 6 && (
        <>
          <span
            href
            key={index}
            disabled
            className={
              item.active ? "event-nav-button-active" : "event-nav-button"
            }
          >
            <div className="step-box">{item.name}</div>
          </span>
        </>
      )
    );
  });

  // const nextStep = (index) => {
  //   // handleClick(index);
  // };

  return (
    <div>
      {activeItems.map((item, index) => {
        return item.key === "complete" && item.active === true ? (
          <EntryComplete event={event} nextStep={nextStep} menu={menu} />
        ) : item.key === "select" && item.active === true ? (
          <SelectForm event={event} nextStep={nextStep} menu={menu} />
        ) : item.key === "stall" && item.active === true ? (
          <StallForm event={event} nextStep={nextStep} menu={menu} />
        ) : item.key === "rv" && item.active === true ? (
          <RvForm event={event} nextStep={nextStep} menu={menu} />
        ) : item.key === "horse" && item.active === true ? (
          <HorseForm event={event} nextStep={nextStep} menu={menu} />
        ) : item.key === "payment" && item.active === true ? (
          <StripeForm nextStep={nextStep} menu={menu} event={event} />
        ) : item.key === "waiver" && item.active === true ? (
          <WaiverForm event={event} nextStep={nextStep} menu={menu} />
        ) : (
          ""
        );
      })}
    </div>
  );
};

export default EventRegMenu;
