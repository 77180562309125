import React, { useState } from "react";
import { Button, Modal, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import CreateEventForm from "./CreateEventForm";
function MgrModal() {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      size="mini"
      style={{ borderRadius: 10 }}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={
        <button
          className="card-button"
          style={{ border: "none", fontFamily: "Lato", maxWidth: "120px" }}
        >
          Create Event
        </button>
      }
    >
      <div style={{ margin: 25 }}>
        <h1 floated="left">
          New Event
          <Button
            floated="right"
            style={{ borderRadius: 25, color: "black", padding: 10 }}
            onClick={handleClose}
          >
            <Icon name="cancel" />
            Cancel
          </Button>
        </h1>
        <CreateEventForm handleClose={handleClose} />
      </div>
    </Modal>
  );
}

export default MgrModal;
