import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  Container,
  Icon,
  Image,
  Button,
  Dimmer,
  Loader,
  Grid,
} from "semantic-ui-react";
import EventSetup from "../Components/EventManagerProfile/EventSetup";
import EventEntries from "../Components/EventManagerProfile/EventEntries";
import {
  fetchEvent,
  fetchEventRVs,
  fetchEventStalls,
} from "../features/events/eventsSlice";
import { fetchRVs } from "../features/RVs/RVsSlice";
import { fetchStalls } from "../features/stalls/stallsSlice";
import { fetchTypes } from "../features/eventTypes/eventTypesSlice";
import moment from "moment";
import { updateEvent } from "../features/events/eventsSlice";
import "../App.css";

const Child = () => {
  let data = useLocation();
  return data.state; //state would be in data.state//
};

const ManagerEdit = () => {
  const event = Child();

  let eventId = event.id;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchEvent(eventId))
      .then(() => dispatch(fetchRVs(event)))
      .then(() => dispatch(fetchStalls(event)));
    window.scrollTo(0, 0);
  }, []);
  const eventNew = useSelector((state) => state.events.event);
  const campings = useSelector((state) => state.RVs.RVs);
  const fetchStatus = useSelector((state) => state.events.fetchStatus);
  const user = useSelector((state) => state.users.activeUser);
  const [activeItems, setActiveItems] = useState([
    { key: "setup", active: true, name: "Event Setup", icon: "/Info v1.svg" },
    {
      key: "entries",
      active: false,
      name: "Entries",
      icon: "/Event Entry v1.svg",
    },
    // {
    //   key: "stalls&rvs",
    //   active: false,
    //   name: "Stalls & RVs",
    //   icon: "/Event Entry v1.svg",
    // },
    // { key: "times", active: false, name: "Times", icon: "/Clock v1.svg" },
    // { key: "scores", active: false, name: "Scores", icon: "/Trophy v1.svg" },
  ]);

  const handleClick = (index) => {
    setActiveItems((prevItems) =>
      prevItems.map((e, i) => ({ ...e, active: i === index }))
    );
  };

  const menu = activeItems.map((item, index) => {
    return (
      <>
        <a
          href
          key={index}
          onClick={() => handleClick(index)}
          className={
            item.active ? "event-nav-button-active" : "event-nav-button"
          }
        >
          <img key={index} className="event-nav-icon" src={item.icon} alt="" />
          <div style={{ height: "18px" }}>{item.name}</div>
        </a>
      </>
    );
  });
  return (
    <>
      {fetchStatus === "loading" && (
        <Dimmer active page>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <div className="main-content-cards">
        <Container fluid>
          <Image fluid src={eventNew.imgUrl} alt=""></Image>
          <div className="event-title-area">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 style={{ maxWidth: "8rem", paddingTop: ".5rem" }}>
                {event.location}
              </h3>{" "}
              {/* <button
                className="card-button"
                style={{ maxWidth: "8rem", maxHeight: "4rem", border: "none" }}
                onClick={(e) => {
                  let values = {
                    name: event.name,
                    description: event.description,
                    isPublished: !eventNew.isPublished,
                  };
                  dispatch(updateEvent({ user, event, values })).then(() =>
                    dispatch(fetchEvent(eventId))
                  );
                }}
              >
                {eventNew.isPublished === true
                  ? "Undo Publishing"
                  : "Publish Event"}
              </button> */}
              <Link
                className="card-button"
                style={{ maxWidth: "8rem", maxHeight: "4rem" }}
                to="/profile"
              >
                <strong>Back to profile</strong>
              </Link>
            </div>
            <h1 className="event-view-event-name">{event.name}</h1>
            <div className="dates">
              {event.endDate ? (
                <>
                  {moment(event.endDate).isSameOrBefore(event.startDate) ? (
                    <>{moment(event.startDate).format("MMMM D, YYYY")}</>
                  ) : (
                    <>
                      {moment.utc(event.startDate).format("MMMM D, YYYY")}-{" "}
                      {moment.utc(event.endDate).format("MMMM D, YYYY")}
                    </>
                  )}
                </>
              ) : (
                <>{moment(event.startDate).format("MMMM D, YYYY")}</>
              )}
            </div>
          </div>
          <div className="event-nav">{menu}</div>
          {activeItems.map((item, index) => {
            return item.key === "setup" && item.active === true ? (
              <>
                <EventSetup eventId={event.id} />
              </>
            ) : item.key === "entries" && item.active === true ? (
              <EventEntries eventId={event.id} />
            ) : (
              ""
            );
          })}

          {/* <EventSetup eventId={event.id} /> */}
        </Container>
      </div>
    </>
  );
};

export default ManagerEdit;
