import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Header } from "semantic-ui-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Redirect } from "react-router-dom";

import { Formik } from "formik";

import { createHorse } from "../../features/horses/horsesSlice";
import { updateHorse } from "../../features/horses/horsesSlice";

const horseOptions = [
  {
    key: "Own",
    text: "Own",
    value: true,
  },
  {
    key: "Lease",
    text: "Lease",
    value: false,
  },
];

const AddHorseForm = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  console.log("id for form", props);
  const fetchStatus = useSelector((state) => state.users.fetchStatus);
  const horseId = _.get(props, "horse.id", null);

  return (
    <section>
      <br />
      <Formik
        //memberships should be array. push values as needed
        initialValues={{
          registeredName: _.get(props, "horse.registeredName", ""),
          nickname: _.get(props, "horse.nickname", ""),
          //horse.nickName ???

          yearOfBirth: _.get(props, "horse.yearOfBirth", ""),
          own: _.get(props, "horse.own", ""),
          cogginDocumentUrl: _.get(props, "horse.cogginDocumentUrl", ""),
          vaccinationDocumentUrl: _.get(
            props,
            "horse.vaccinationDocumentUrl",
            ""
          ),
          healthCertificateUrl: _.get(props, "horse.healthCertificateUrl", ""),
          // memberships: [
          //   {
          //     association: "",
          //     memberNumber: null,
          //   },
          // ],
          userId: user.id,
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values) => {
          props.horse && horseId
            ? dispatch(updateHorse({ user, horseId, values }))
            : dispatch(createHorse({ user, values }));

          props.handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Registered Name
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="registeredName"
              type="registeredName"
              onChange={handleChange}
              placeholder="Enter Horse Name"
              id="registeredName"
              value={values.registeredName}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Nickname
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="nickname"
              type="nickname"
              onChange={handleChange}
              placeholder="Enter Nickname"
              id="nickname"
              value={values.nickname}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Year of Birth
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              placeholder="####"
              type="yearOfBirth"
              name="yearOfBirth"
              onChange={handleChange}
              id="yearOfBirth"
              value={values.yearOfBirth}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Own or Lease
            </Header>
            {props.horse && horseId ? (
              <Form.Select
                fluid
                style={{ height: 50, borderRadius: 10, lineHeight: 2 }}
                text={values.own ? "Own" : "Lease"}
                id="own"
                name="own"
                onChange={(event, field) => {
                  setFieldValue("own", field.value);
                }}
                options={horseOptions}
              />
            ) : (
              <Form.Select
                fluid
                style={{ height: 50, borderRadius: 10, lineHeight: 2 }}
                placeholder="Own or Lease"
                id="own"
                name="own"
                onChange={(event, field) => {
                  setFieldValue("own", field.value);
                }}
                options={horseOptions}
              />
            )}

            {/* <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Upload Coggins Document
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              placeholder="Select File From Computer"
              type="cogginDocumentUrl"
              name="cogginDocumentUrl"
              onChange={handleChange}
              id="cogginDocumentUrl"
              value={values.cogginDocumentUrl}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Upload Vaccination Document
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              placeholder="Select File From Computer"
              type="vaccinationDocumentUrl"
              name="vaccinationDocumentUrl"
              onChange={handleChange}
              id="vaccinationDocumentUrl"
              value={values.vaccinationDocumentUrl}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Upload Health Certificate
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              placeholder="Select File From Computer"
              type="healthCert"
              name="healthCert"
              onChange={handleChange}
              id="healthCert"
              value={values.healthCert}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input> */}

            {/* <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Memberships
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              placeholder="Association"
              type="memberships"
              name="memberships"
              onChange={handleChange}
              id="memberships"
              value={values.memberships.association}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              placeholder="#00000"
              type="memberships"
              name="memberships"
              onChange={handleChange}
              id="memberships"
              value={values.memberships.memberNumber}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input> */}

            <br />
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Save & Close
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default AddHorseForm;
