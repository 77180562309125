import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Icon } from "semantic-ui-react";
import moment from "moment";
import { fetchEvent, fetchEventRVs } from "../../features/events/eventsSlice";
import { fetchRVs } from "../../features/RVs/RVsSlice";
import EditModal from "./EventEditForms/EditModal";

const MgrCampingRVs = ({ eventId }) => {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchEventRVs({ event }));
  // }, []);
  const user = useSelector((state) => state.users.activeUser);
  const event = useSelector((state) => state.events.event);
  const campings = useSelector((state) => state.RVs.RVs);
  const minRVDate = useSelector((state) => state.RVs.minDate);
  const maxRVDate = useSelector((state) => state.RVs.maxDate);
  const rvMin = moment.utc(minRVDate).format("MMMM D, YYYY");
  const rvMax = moment.utc(maxRVDate).format("MMMM D, YYYY");

  let card = "Camping";
  return (
    <div>
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1
            style={{
              textAlign: "left",
              fontFamily: "Lato",
              fontSize: "1.90rem",
              fontWeight: "900px",
            }}
          >
            <strong>RV Information</strong>
            <EditModal fluid event={event} card={card} />
            {!campings || campings.length === 0 ? (
              <strong style={{ color: "red", marginLeft: "1rem" }}>
                *Optional
              </strong>
            ) : (
              <Icon
                name="check circle"
                style={{ color: "green", marginLeft: "1rem" }}
              />
            )}
          </h1>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Dates Available</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {minRVDate !== 0 && maxRVDate !== 0 ? (
              <p>{`${rvMin}-${rvMax}`}</p>
            ) : (
              <p>None available</p>
            )}
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Cost</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              VIP RV Hookup: ${event.rvVipOvernightFee} / night
              <br />
              RV Hookup: ${event.rvOvernightFee} / night
            </p>
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Additional Camping Information</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            {event.rvAdditionalInfo ? (
              <p>{event.rvAdditionalInfo}</p>
            ) : (
              <p>No Camping Information Given</p>
            )}
          </Card.Content>
        </Container>
      </Card>
    </div>
  );
};

export default MgrCampingRVs;
