import React from "react";
import "./NavBar.scss";
import { Link, NavLink } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";

import { logoutUser, resetUsers } from "../../features/users/userSlice";
import { resetHorses } from "../../features/horses/horsesSlice";
import "../../App.css";

const Navbar = ({ open }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const userLoggedIn = user && user.accessToken;

  const handleLogout = () => {
    dispatch(logoutUser(user.accessToken));
    dispatch(resetUsers());
    dispatch(resetHorses());
  };
  const checkActive = (match, location) => {
    //some additional logic to verify you are in the home URI
    if (!location) return false;
    const { pathname } = location;
    console.log(pathname);
    return pathname === "/";
  };
  return (
    <div className="nav-bar">
      <Link
        className="logo-link-block"
        style={{ textDecoration: "none" }}
        to="/"
      >
        <img
          className="pearl-nav-icon"
          src="./illustratorPEARLBnav.png"
          alt=""
        />
        <div className="text-block">Pearl's Office</div>
      </Link>

      <div className="user-nav-bar">
        {userLoggedIn ? (
          <>
            <NavLink
              className="user-nav"
              activeClassName="user-nav-selected"
              to="/"
              exact={true}
            >
              <img
                className="main-nav-icon"
                loading="lazy"
                Nav
                src="./Events v1.svg"
                alt=""
              />
              <div>Events</div>
            </NavLink>
            <NavLink
              className="user-nav"
              activeClassName="user-nav-selected"
              style={{ textDecoration: "none" }}
              to="/profile"
            >
              <img
                className="main-nav-icon"
                loading="lazy"
                src="./Profile v1.svg"
                alt=""
              />
              <div>My Profile</div>
            </NavLink>
          </>
        ) : (
          <>
            <NavLink
              className="user-nav"
              activeClassName="user-nav-selected"
              to="/"
              exact={true}
            >
              <img
                className="main-nav-icon"
                loading="lazy"
                Nav
                src="./Events v1.svg"
                alt=""
              />
              <div>Events</div>
            </NavLink>
            <NavLink
              activeClassName="user-nav-selected"
              className="user-nav"
              style={{ textDecoration: "none" }}
              to="/login"
            >
              <img
                className="main-nav-icon"
                loading="lazy"
                src="./Profile v1.svg"
                alt=""
              />
              <div>My Profile</div>
            </NavLink>
          </>
        )}
      </div>
      <div className="nav-right">
        {userLoggedIn && (
          <Link to="/">
            <button className="nav-btn-2" onClick={handleLogout}>
              Log Out
            </button>
          </Link>
        )}
        {!userLoggedIn && (
          <>
            <Link to="/create-account">
              <button className="nav-btn-1">Create Account</button>
            </Link>
            <Link className="link-style" to="/login">
              <button className="nav-btn-2">Log In</button>
            </Link>
          </>
        )}
        <div className="side-nav-icon">
          <Icon size="large" name="bars" onClick={open} />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
