import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Grid, Image, Header } from "semantic-ui-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Redirect } from "react-router-dom";
import EditRVs from "./EditRVs";
import EditVIPs from "./EditVIPs";
import { Formik } from "formik";
import { updateEvent } from "../../../features/events/eventsSlice";
import { fetchRVs } from "../../../features/RVs/RVsSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const EditCampingForm = ({ event, handleClose }) => {
  const [rvShow, setRVShow] = useState(true);
  const [vipShow, setVIPShow] = useState(false);
  const [priceShow, setPriceShow] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);

  return (
    <section>
      <br />
      {rvShow === true && (
        <EditRVs event={event} setRVShow={setRVShow} setVIPShow={setVIPShow} />
      )}
      {vipShow === true && (
        <EditVIPs
          event={event}
          setVIPShow={setVIPShow}
          setPriceShow={setPriceShow}
        />
      )}

      {priceShow && (
        <>
          <br />
          <Formik
            initialValues={{
              name: event.name,
              id: event.id,
              description: event.description,
              rvOvernightFee: event.rvOvernightFee,
              rvVipOvernightFee: event.rvVipOvernightFee,
              rvAdditionalInfo: event.rvAdditionalInfo
                ? event.rvAdditionalInfo
                : "",
            }}
            validate={(values) => {
              const errors = {};

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              dispatch(updateEvent({ user, event, values })).then(()=>dispatch(fetchRVs(event)));
              setSubmitting(false);
              handleClose();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <Header as="h3">Step 3: Camping Details</Header>
                  <br/>
                <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                  RV Cost per Night
                </Header>
                <Form.Input
                  style={{ height: 50, borderRadius: 10 }}
                  name="rvOvernightFee"
                  type="rvOvernightFee"
                  onChange={handleChange}
                  placeholder="$25"
                  id="rvOvernightFee"
                  value={values.rvOvernightFee}
                >
                  <input style={{ borderRadius: 10 }} />
                </Form.Input>
                <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                  VIP Cost per Night
                </Header>
                <Form.Input
                  style={{ height: 50, borderRadius: 10 }}
                  name="rvVipOvernightFee"
                  type="rvVipOvernightFee"
                  onChange={handleChange}
                  placeholder="$25"
                  id="rvVipOvernightFee"
                  value={values.rvVipOvernightFee}
                >
                  <input style={{ borderRadius: 10 }} />
                </Form.Input>

                <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
                  Additional Camping Information
                </Header>
                <Form.TextArea
                  style={{ height: 50, borderRadius: 10 }}
                  name="rvAdditionalInfo"
                  type="rvAdditionalInfo"
                  onChange={handleChange}
                  placeholder="Add additional information about stalls here..."
                  id="rvAdditionalInfo"
                  value={values.rvAdditionalInfo}
                >
                  <input style={{ borderRadius: 10 }} />
                </Form.TextArea>
                <br />
                <Button
                  style={{
                    backgroundColor: "#ffdea2",
                    height: 50,

                    borderRadius: 10,
                  }}
                  fluid
                  type="submit"
                >
                  Save & Close
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </section>
  );
};

export default EditCampingForm;
