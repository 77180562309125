import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import NavBar from "./Components/NavBar/Navbar";
import Footer from "./Components/Footer/Footer";
import LogIn from "./Pages/LogIn";
import Profile from "./Pages/Profile";
import CreateAccount from "./Pages/CreateAccount";
import ForgotPassword from "./Pages/ForgotPassword";
import Error from "./Pages/Error";
import UpdatePassword from "./Pages/UpdatePassword";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SideNavBar from "./Components/NavBar/SideNavBar";
import Backdrop from "./Components/NavBar/Backdrop";
import Events from "./Components/EventPreviewCards/Events";
import Event from "./Pages/EventView";
import { Container } from "semantic-ui-react";
import ManagerEdit from "./Pages/ManagerEdit";
import "./App.css";
import IdleTimerContainer from "./Components/IdleTimerContainer";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
function App() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <div className="App">
          <NavBar open={handleOpen} />
          {open ? (
            <div>
              <Backdrop close={handleClose} />
            </div>
          ) : null}
          <SideNavBar close={handleClose} open={open} />
          <IdleTimerContainer></IdleTimerContainer>

          <ErrorBoundary>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/event" component={Event} />
              <Route path="/profile" component={Profile} />
              <Route path="/create-account" component={CreateAccount} />
              <Route path="/login" component={LogIn} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/update-password" component={UpdatePassword} />
              <Route path="/manager-edit" component={ManagerEdit} />
              <Route path="/error" component={Error} />
            </Switch>
          </ErrorBoundary>

          <Footer />
        </div>
      </Router>
    </Elements>
  );
}

const Home = () => {
  return (
    <div>
      <div className="intro-banner">
        <div className="banner-text-area">
          <h1 className="heading-2">
            Events Done
            <span>
              <em className="text-color-white"> Right!</em>
            </span>
          </h1>
          <p className="paragraph-2">
            Pearl's Office is designed to help event managers and riders have a
            great experience from start to finish.
          </p>
          <Link to="/create-account">
            <button className="nav-btn-2">Create Account</button>
          </Link>
        </div>
      </div>
      <div className="main-content-outer">
        <div className="main-content-cards">
          <Container>
            <Events />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default App;
