import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Form, Header, Icon } from "semantic-ui-react";
import { Formik } from "formik";
import { updateEvent } from "../../../features/events/eventsSlice";

const EditDocsWaiversForm = ({ event, handleClose }) => {
  const [count, setCount] = useState(1);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);

  const handleField = (values) => {};
  return (
    <section>
      <br />
      <Formik
        initialValues={{
          id: event.id,
          name: event.name,
          description: event.description,
          waiverUrl: event.waiverUrl ? event.waiverUrl : "",
          requiredRiderDocuments: [],
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(updateEvent({ user, event, values }));
          setSubmitting(false);
          handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Rider Waiver URL
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="waiverUrl"
              type="waiverUrl"
              onChange={handleChange}
              placeholder="Enter Waiver URL"
              id="waiverUrl"
              value={values.waiverUrl}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Horse Documents
            </Header>

            {[...Array(count)].map((e, index) => (
              <div key={index}>
                <Form.Input
                  style={{ height: 50, borderRadius: 10, marginBottom: 8 }}
                  name={`requiredRiderDocuments[${index}]`}
                  type={`requiredRiderDocuments[${index}]`}
                  onChange={handleChange}
                  placeholder="i.e. Coggins"
                  id={`requiredRiderDocuments[${index}]`}
                  value={values.requiredRiderDocuments[index]}
                >
                  <input style={{ borderRadius: 10 }} />
                </Form.Input>
              </div>
            ))}
            <a
              href
              style={{ borderRadius: "none" }}
              onClick={() => setCount(count + 1)}
            >
              <Header as="h4" floated="left" style={{ marginBottom: 8 }}>
                <Icon name="plus" />
                Enter Another
              </Header>
            </a>
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Save & Close
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EditDocsWaiversForm;
