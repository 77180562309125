import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Grid, Image, Header } from "semantic-ui-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Redirect } from "react-router-dom";

import { Formik } from "formik";

import { updateUser } from "../../features/users/userSlice";

const ageOptions = [
  {
    key: "Junior/Young Rider",
    text: "Junior/Young Rider",
    value: "Junior/Young Rider",
  },
  {
    key: "Adult Amateur",
    text: "Adult Amateur",
    value: "Adult Amateur",
  },
  {
    key: "Open/Professional",
    text: "Open/Professional",
    value: "Open/Professional",
  },
];
const AboutMeForm = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  console.log(user.email, user.firstName);
  return (
    <section>
      <br />
      <Formik
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          address: user.address,
          profileImgUrl: user.profileImgUrl,
          ageGroup: user.ageGroup,
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(updateUser({ user, values }));
          setSubmitting(false);
          props.handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              First Name
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="firstName"
              type="firstName"
              onChange={handleChange}
              placeholder="First Name"
              id="firstName"
              value={values.firstName}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Last Name
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="lastName"
              type="lastName"
              onChange={handleChange}
              placeholder="Last Name"
              id="lastName"
              value={values.lastName}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Cell Phone
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              placeholder="888-888-8888"
              type="phone"
              name="phone"
              onChange={handleChange}
              id="phone"
              value={values.phone}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Home Address
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              placeholder="1234 Main Street, City, State"
              type="address"
              name="address"
              onChange={handleChange}
              id="address"
              value={values.address}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            {/* <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Profile Image
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              placeholder="Select File From Computer"
              type="profileImgUrl"
              name="profileImgUrl"
              onChange={handleChange}
              id="profileImgUrl"
              value={values.profileImgUrl}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input> */}
            {/* 
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Age Group
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              placeholder="Select Age Group"
              type="ageGroup"
              name="ageGroup"
              onChange={handleChange}
              id="ageGroup"
              value={values.ageGroup}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input> */}
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Age Group
            </Header>
            {values.ageGroup ? (
              <Form.Select
                fluid
                style={{ height: 50, borderRadius: 10, lineHeight: 2 }}
                text={values.ageGroup}
                id="ageGroup"
                name="ageGroup"
                onChange={(event, field) => {
                  console.log("SELECT VALUE", field.value);
                  setFieldValue("ageGroup", field.value);
                }}
                options={ageOptions}
              />
            ) : (
              <Form.Select
                fluid
                style={{ height: 50, borderRadius: 10, lineHeight: 2 }}
                placeholder="Age Group"
                id="ageGroup"
                name="ageGroup"
                onChange={(event, field) => {
                  console.log("SELECT VALUE", field.value);
                  setFieldValue("ageGroup", field.value);
                }}
                options={ageOptions}
              />
            )}

            <br />
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Save & Close
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default AboutMeForm;
