import React, { useState, useEffect } from "react";
import "../../../App.css";
import Button from "./Button";
import { Form, Card, Container } from "semantic-ui-react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { updateRV } from "../../../features/RVs/RVsSlice";
import { fetchEventRVs } from "../../../features/events/eventsSlice";
import { updateRider } from "../../../features/eventRiders/eventRidersSlice";
import moment from "moment";

//map through dates

const RvForm = ({ nextStep, event, menu }) => {
  const [complete, setComplete] = useState(false);
  const [rvIds, setRvIds] = useState([]);
  const [selectNoRV, setSelectNoRV] = useState(false);
  const dispatch = useDispatch();
  const campings = useSelector((state) => state.events.RVs);
  const user = useSelector((state) => state.users.activeUser);
  const rider = useSelector((state) => state.riders.rider);
  // const returningRider = event.EventRiders.find((eventRider) => {
  //   return eventRider.userId === user.id;
  // });
  const [riderTotal, setRiderTotal] = useState();

  useEffect(() => {
    dispatch(fetchEventRVs({ event }));
  }, []);


  const riderId = rider.id;

  const handleRV = (e, { values }, rv) => {
    const found = rvIds.find((id) => id === rv.id);
    if (found) {
      const foundFee = {
        name: rv.name || "RV Camping",
        price: event.rvOvernightFee,
      };
      let index;
      for (var i = 0; i < values.fees.length; i++) {
        const { name, price } = values.fees[i];
        if (name === foundFee.name && price === foundFee.price) {
          index = i;
        }
      }
      values.fees.splice(index, 1);
      setRvIds([...rvIds.filter((id) => id !== found)]);

      setComplete(true);
      return;
    } else {
      values.fees = [
        ...values.fees,
        { name: rv.name || "RV Camping", price: event.rvOvernightFee },
      ];
      values.noRv = false;
      setRvIds([...rvIds, rv.id]);

      setComplete(true);
      return;
    }
  };

  const handleVIP = (e, { values }, rv) => {
    const found = rvIds.find((id) => id === rv.id);

    if (found) {
      const foundFee = {
        name: rv.name || "VIP RV Camping",
        price: event.rvVipOvernightFee,
      };
      let index;
      for (var i = 0; i < values.fees.length; i++) {
        const { name, price } = values.fees[i];
        if (name === foundFee.name && price === foundFee.price) {
          index = i;
        }
      }
      values.fees.splice(index, 1);
      setRvIds([...rvIds.filter((id) => id !== found)]);

      setComplete(true);
      return;
    } else {
      values.fees = [
        ...values.fees,
        { name: rv.name || "VIP RV Camping", price: event.rvVipOvernightFee },
      ];

      setRvIds([...rvIds, rv.id]);
      values.noRv = false;
      setComplete(true);
      return;
    }
  };

  return (
    <Card fluid className="entry-card">
      {console.log("rider info:", rider)}
      <Container fluid className="profile-container">
        <div className="main-content-cards">
          <h1 className="pearl-text big regular">Event Registration</h1>
          <div className="event-nav">{menu}</div>
          <div>
            <div className="registration-step-area">
              <div className="walkthrough-max-width">
                <Formik
                  initialValues={{
                    userId: user.id,
                    eventId: event.id,
                    eventRiderId: rider.id,
                    fees: [...rider.fees],
                    noRv: false,
                  }}
                  onSubmit={(values) => {
                    console.log("rvIds================>", rvIds);
                    dispatch(updateRider({ user, riderId, values }))
                      .then((res) => {
                        rvIds.length &&
                          rvIds.forEach((id) => {
                            console.log(id);
                            dispatch(
                              updateRV({ user, riderId, id, event, values })
                            );
                          });
                      })
                      .then((res) => {
                        nextStep(3);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="field-label big">VIP RV Hookup</div>
                      {campings &&
                        campings.map((rv, index) => {
                          return rv.vip === true && !rv.eventRiderId ? (
                            <>
                              <Button
                                name={rv.name}
                                value={rv.name}
                                onClick={(e) => {
                                  handleVIP(e, { values }, rv);
                                  setSelectNoRV(false);
                                }}
                              >
                                <div>
                                  {moment.utc(rv.date).format("dddd, MMMM DD")}
                                </div>
                                <div>${event.rvVipOvernightFee}</div>
                              </Button>
                            </>
                          ) : (
                            ""
                          );
                        })}
                      {!campings && (
                        <button className="option-field-disabled" disabled>
                          None Available
                        </button>
                      )}

                      <br />
                      <div className="field-label big">RV Hookup</div>
                      {campings &&
                        campings.map((rv, index) => {
                          return rv.vip !== true && !rv.eventRiderId ? (
                            <>
                              <Button
                                name={rv.name}
                                value={rv.name}
                                onClick={(e) => {
                                  handleRV(e, { values }, rv);
                                  setSelectNoRV(false);
                                }}
                              >
                                <div>
                                  {moment.utc(rv.date).format("dddd, MMMM DD")}
                                </div>
                                <div>${event.rvOvernightFee}</div>
                              </Button>
                            </>
                          ) : (
                            ""
                          );
                        })}
                      {!campings && (
                        <button className="option-field-disabled" disabled>
                          None Available
                        </button>
                      )}
                      <br />
                      <button
                        className={`option-field${
                          selectNoRV ? "-selected" : "-deselected"
                        }`}
                        type="button"
                        onClick={(e) => {
                          values.fees = [...rider.fees];
                          setRvIds([]);
                          values.noRv = true;
                          setComplete(true);
                          setSelectNoRV(true);
                        }}
                      >
                        <div>No RV</div>
                      </button>
                      <br />
                      {complete ? (
                        <button className="submit-button" type="submit">
                          Continue
                        </button>
                      ) : (
                        <button
                          className="submit-button"
                          type="button"
                          style={{ backgroundColor: "#D3D3D3" }}
                          disabled
                        >
                          Please Complete Form
                        </button>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Card>
  );
};

export default RvForm;
