import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Header, Icon } from "semantic-ui-react";
import { unwrapResult } from "@reduxjs/toolkit";

import { Formik } from "formik";

import { updateUser } from "../../features/users/userSlice";

const AssociationsForm = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  return (
    <section>
      <br />
      <Formik
        initialValues={{
          associations: user.associations.association,
          memberNumber: user.associations.memberNum,
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(updateUser(values));
          //check state for "status == false"
          setSubmitting(false);
          props.handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          //Dropdown with available associations?

          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              My Associations
            </Header>

            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="associations"
              type="associations"
              onChange={handleChange}
              placeholder="Association"
              id="associations"
              value={values.memberNumber}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Member Number
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="memberNumber"
              type="memberNumber"
              onChange={handleChange}
              placeholder="#000000"
              id="memberNumber"
              value={values.memberNumber}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <br />
            <Button
              style={{
                backgroundColor: "#FFA2E3",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              <Icon className="add" />
            </Button>
            <br />
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="close"
            >
              Save & Close
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default AssociationsForm;
