import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Header, Card, Container, Loader, Dimmer } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import "../../../App.css";
import { client } from "../../../api/client";
import { deleteRider } from "../../../features/eventRiders/eventRidersSlice";

import {
  updateStall,
  fetchRiderStalls,
} from "../../../features/stalls/stallsSlice";
import { updateRV, fetchRiderRVs } from "../../../features/RVs/RVsSlice";

const CARD_OPTIONS = {
  style: {
    base: {
      fontSize: "16px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "red",
    },
  },
};

const StripeForm = ({ nextStep, event, menu }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const rider = useSelector((state) => state.riders.rider);
  const stalls = useSelector((state) => state.stalls.stalls);
  const campings = useSelector((state) => state.RVs.RVs);
  const riderId = rider.id;
  const event_id = event.id;

  useEffect(() => {
    dispatch(fetchRiderStalls({ event, rider }));
    dispatch(fetchRiderRVs({ event, rider }));
  }, []);

  console.log("rider =================>", rider);
  console.log("stalls ==============>", stalls);
  console.log("campings ==============>", campings);
  // const rider = event.EventRiders.find((eventRider) => {
  //   return eventRider.userId === user.id;
  //   // && eventRider.waiverSigned === true;------------------------------------------------------- WHEN WE CAN VERIFY
  // });
  let riderTotal = 0;
  rider.fees.map((fee) =>
    fee.name === "Total" ? (riderTotal = fee.price) : ""
  );
  console.log("riderTotal ==============>", riderTotal);
  const [step1, setStep1] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  let total = parseInt(riderTotal * 100);
  console.log("total for stripe ========>", total);
  const amount = {
    amount: total,
  };

  const stripe = useStripe();
  const elements = useElements();
  if (!rider) {
    return <>{nextStep(4)}</>;
  }

  if (step1) {
    return <>{nextStep(0)}</>;
  }

  const handleDelete = (e) => {
    let values = {
      eventId: event.id,
      eventRiderId: null,
      paid: false,
      barnName: null,
    };
    const rvVals = {
      eventRiderId: null,
    };
    const stallVals = {
      eventRiderId: null,
    };
    stalls.length &&
      stalls.forEach((stall) => {
        let id = stall.id;
        dispatch(updateStall({ user, riderId, id, event, values }));
      });

    campings.length &&
      campings.forEach((spot) => {
        let id = spot.id;
        dispatch(updateRV({ user, riderId, id, event, values }));
      });
    dispatch(deleteRider({ user, riderId, rider }));
    nextStep(0);
  };
  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setDisabled(true);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const response = await client.post(
      `event-riders/${rider.id}/payment`,
      amount,
      {
        headers: { authorization: user.accessToken },
      }
    );

    console.log("secret", response.data);
    const result = await stripe.confirmCardPayment(response.data, {
      payment_method: {
        type: "card",
        card: elements.getElement(CardNumberElement),
      },
    });
    console.log(result);
    if (result.error) {
      setError(true);
      // Show error to your customer (e.g., insufficient funds)
      setMessage(result.error.message);
      console.log(result.error.message);
      setDisabled(false);
    } else {
      // The payment has been processed!

      await client.put(`event-riders/${rider.id}/payment`, rider, {
        headers: { authorization: user.accessToken },
      });
      if (result.paymentIntent.status === "succeeded") {
        let values = {
          paid: true,
          eventId: event_id,
        };
        setMessage("Success!");
        setSuccess(true);
        setDisabled(true);
        stalls.length &&
          stalls.forEach((stall) => {
            let id = stall.id;
            dispatch(updateStall({ user, riderId, id, event, values }));
          });

        campings.length &&
          campings.forEach((spot) => {
            let id = spot.id;
            dispatch(updateRV({ user, riderId, id, event, values }));
          });
        nextStep(5);
      }

      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.
    }

    // console.log(finish);
    // if (finish.error) {
    //   console.log(finish.error.message);
    // } else {
    //   console.log("updated");
    // }
  };

  return (
    <Card fluid className="entry-card">
      <Container fluid className="profile-container">
        <div className="main-content-cards">
          <h1 className="pearl-text big regular">Event Registration</h1>
          <div className="event-nav">{menu}</div>
          <div className="registration-step-area">
            <div className="walkthrough-max-width">
              <div className="field-label big">Summary</div>

              {rider.fees.map((fee, index) => {
                return fee.name === "Total" ? (
                  <div key={index} className="line-item-container total">
                    <div>Total</div>

                    <div>${riderTotal}</div>
                  </div>
                ) : (
                  <div key={index} className="line-item-container">
                    <div>{fee.name}</div>
                    <div>${fee.price}</div>
                  </div>
                );
              })}

              <br />

              {/* --------------------------------------------------------------------------------Delete Rider??? Need to UN-book their RVS and Stalls */}
              {success === false && (
                <>
                  <Header floated="left" style={{ marginBottom: 4 }}>
                    If incorrect, please start over below.
                  </Header>
                  <button
                    className="submit-button"
                    type="button"
                    onClick={(e) => handleDelete({ user, riderId })}
                  >
                    Start Over
                  </button>
                </>
              )}

              <br />
              <div className="field-label big">Payment Info</div>
              <br />

              <form onSubmit={handleSubmit}>
                {error === true && (
                  <Header
                    color="red"
                    floated="left"
                    style={{ marginBottom: 4 }}
                  >
                    {message}
                  </Header>
                )}

                <Header floated="left" style={{ marginBottom: 4 }}>
                  Credit Card Number
                </Header>

                <CardNumberElement
                  id="card-element"
                  options={CARD_OPTIONS}
                  onChange={() => setError(false)}
                />
                <br />

                <Header floated="left" style={{ marginBottom: 4 }}>
                  Expiration Date
                </Header>

                <CardExpiryElement options={CARD_OPTIONS} />
                <br />

                <Header floated="left" style={{ marginBottom: 4 }}>
                  CVC
                </Header>

                <CardCvcElement options={CARD_OPTIONS} />
                <br />
                {success === true ? (
                  <Header className="pearl-text big regular" color="red">
                    Thank you, your payment has been successful!
                  </Header>
                ) : disabled === true ? (
                  <>
                    <Dimmer active page>
                      <Loader size="massive">Loading</Loader>
                    </Dimmer>
                  </>
                ) : (
                  <>
                    <button
                      className="submit-button"
                      // type="submit"
                      // onClick={() => nextStep(6)}
                      disabled={!stripe}
                    >
                      Pay
                    </button>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </Container>
    </Card>
  );
};
export default StripeForm;
