import React, { useEffect, useState } from "react";
import "../../../App.css";
import Button from "./Button";
import { Form, Card, Container } from "semantic-ui-react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchHorses } from "../../../features/horses/horsesSlice";
import { updateRider } from "../../../features/eventRiders/eventRidersSlice";

//Map through userHorses

const HorseForm = ({ nextStep, event, menu }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const userHorses = useSelector((state) => state.horses.userHorses);
  const fetchStatus = useSelector((state) => state.horses.fetchStatus);
  const rider = useSelector((state) => state.riders.rider);
  // const returningRider = event.EventRiders.find((eventRider) => {
  //   return eventRider.userId === user.id;
  // });
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    dispatch(fetchHorses(user));
  }, []);
  // console.log(userHorses);

  if (!rider) {
    return <>{nextStep(2)}</>;
  }
  const riderId = rider.id;

  const handleHorse = ({ values }, horse) => {
    console.log("horse values: ", values);
    console.log("horse info: ", horse);
    values.horseId = horse.id;
    values.Horse = [{ horse }];
    setComplete(true);
  };

  return (
    <Card fluid className="entry-card">
      <Container fluid className="profile-container">
        <div className="main-content-cards">
          <h1 className="pearl-text big regular">Event Registration</h1>
          <div className="event-nav">{menu}</div>

          <div className="registration-step-area">
            <div className="walkthrough-max-width">
              <Formik
                initialValues={{
                  horseId: "",
                  Horse: [{}],
                  fees: [
                    ...rider.fees,
                    {
                      name: "Total",
                      price: rider.fees.reduce(
                        (total, fee) => total + parseInt(fee.price),
                        0
                      ),
                    },
                  ],
                }}
                onSubmit={(values) => {
                  console.log("values", values);
                  dispatch(updateRider({ user, riderId, values }))
                    .then((res) => {
                      nextStep(4);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="field-label big">Select Your Horse</div>
                    <p style={{ textAlign: "left", marginBottom: "10px" }}>
                      You may only select a horse with valid Coggins uploaded in{" "}
                      <Link
                        to="/profile"
                        style={{ textDecoration: "underline", color: "black" }}
                      >
                        My Horses
                      </Link>{" "}
                      section of your profile.
                    </p>
                    <p style={{ textAlign: "left", marginBottom: "10px" }}>
                      <strong>
                        Failure to have valid Coggins report uploaded in "My
                        Horses" may result in incomplete entry.
                      </strong>
                    </p>
                    {userHorses.map((horse, idx) => {
                      return horse.cogginsDocumentUrl ? (
                        <>
                          <button
                            className="option-field"
                            name="horse"
                            value="eventHorse"
                            type="button"
                            onClick={() => handleHorse({ values }, horse)}
                          >
                            <div>{horse.registeredName}</div>
                          </button>
                        </>
                      ) : (
                        <>
                          <button className="option-field-disabled" disabled>
                            <div>{horse.registeredName}</div>
                            <div>Documents Required</div>
                          </button>
                        </>
                      );
                    })}
                    {complete ? (
                      <button className="submit-button" type="submit">
                        Continue
                      </button>
                    ) : (
                      <button
                        className="submit-button"
                        type="button"
                        style={{ backgroundColor: "#D3D3D3" }}
                        disabled
                      >
                        Please Complete Form
                      </button>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </Card>
  );
};

export default HorseForm;
