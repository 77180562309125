import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../api/client";

const initialState = {
  status: "",
  fetchStatus: "",
  RVs: [{}],
  minDate: "",
  maxDate: "",
};

export const fetchRVs = createAsyncThunk("RVs/fetchRVs", async (data) => {
  const response = await client.get(
    `rv-camping?filter={"eventId":${data.id}}&limit=30`
  );
  return response;
});
export const fetchRiderRVs = createAsyncThunk(
  "RVs/fetchRiderRVs",
  async (data) => {
    const response = await client.get(
      `rv-camping?filter={"eventId":${data.event.id},"paid":"false","eventRiderId":${data.rider.id}}`
    );
    return response;
  }
);

export const createRV = createAsyncThunk("RVs/createRV", async (data) => {
  const response = await client.post("rv-camping", data.values, {
    headers: { authorization: data.user.accessToken },
  });
  return response;
});

export const updateRV = createAsyncThunk("RVs/updateRV", async (data) => {
  const response = await client.put(`rv-camping/${data.id}`, data.values, {
    headers: { authorization: data.user.accessToken },
  });
  return response;
});

const RVsSlice = createSlice({
  name: "RVs",
  initialState,
  reducers: {},
  extraReducers: {
    [createRV.pending]: (state, action) => {
      state.fetchStatus = "loading";
      state.error = action.payload;
    },
    [createRV.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
    },
    [createRV.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.error.message;
    },
    [updateRV.pending]: (state, action) => {
      state.fetchStatus = "loading";
      state.error = action.payload;
    },
    [updateRV.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
    },
    [updateRV.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.error.message;
    },
    [fetchRVs.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchRVs.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.RVs = action.payload.rvCamping;
      state.minDate = action.payload.minDate;
      state.maxDate = action.payload.maxDate;
    },
    [fetchRVs.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
    },
    [fetchRiderRVs.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchRiderRVs.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.RVs = action.payload.rvCamping;
    },
    [fetchRiderRVs.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
    },
  },
});

export default RVsSlice.reducer;
