import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../api/client";

const initialState = {
  status: "",
  fetchStatus: "",
  stalls: [{}],
  minDate: "",
  maxDate: "",
  AMminDate: "",
  AMmaxDate: "",
  PMminDate: "",
  PMmaxDate: "",
};

export const fetchStalls = createAsyncThunk(
  "stalls/fetchStalls",
  async (data) => {
    const response = await client.get(
      `stalls?filter={"eventId":${data.id},"paid":"false","eventRiderId":null}&limit=30`
    );

    return response;
  }
);
export const fetchPMStalls = createAsyncThunk(
  "stalls/fetchPMStalls",
  async (data) => {
    const response = await client.get(
      `stalls?filter={"eventId":${data.id},"isOvernight":"true", "eventRiderId":null}&limit=30`
    );

    return response;
  }
);
export const fetchAMStalls = createAsyncThunk(
  "stalls/fetchAMStalls",
  async (data) => {
    const response = await client.get(
      `stalls?filter={"eventId":${data.id},"isOvernight":"false", "eventRiderId":null}&limit=30`
    );

    return response;
  }
);
export const fetchRiderStalls = createAsyncThunk(
  "stalls/fetchRiderStalls",
  async (data) => {
    const response = await client.get(
      `stalls?filter={"eventId":${data.event.id},"paid":"false","eventRiderId":${data.rider.id}}`
    );

    return response;
  }
);

export const createStall = createAsyncThunk(
  "stalls/createStall",
  async (data) => {
    const response = await client.post(`stalls`, data.values, {
      headers: { authorization: data.user.accessToken },
    });
    return response;
  }
);
export const updateStall = createAsyncThunk(
  "stalls/updateStall",
  async (data) => {
    const response = await client.put(`stalls/${data.id}`, data.values, {
      headers: { authorization: data.user.accessToken },
    });
    return response;
  }
);

const stallsSlice = createSlice({
  name: "stalls",
  initialState,
  reducers: {},
  extraReducers: {
    [createStall.pending]: (state, action) => {
      state.fetchStatus = "loading";
      state.error = action.payload;
    },
    [createStall.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.eventTypes = action.payload;
    },
    [createStall.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.error.message;
    },
    [updateStall.pending]: (state, action) => {
      state.fetchStatus = "loading";
      state.error = action.payload;
    },
    [updateStall.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.stall = action.payload;
    },
    [updateStall.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.error.message;
    },
    [fetchStalls.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchStalls.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.stalls = action.payload.stalls;
      state.minDate = action.payload.minDate;
      state.maxDate = action.payload.maxDate;
    },
    [fetchStalls.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      console.log(action.payload);
      state.error = action.payload;
    },

    [fetchPMStalls.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchPMStalls.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.PMstalls = action.payload.stalls;
      state.PMminDate = action.payload.minDate;
      state.PMmaxDate = action.payload.maxDate;
    },
    [fetchPMStalls.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      console.log(action.payload);
      state.error = action.payload;
    },

    [fetchAMStalls.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchAMStalls.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.AMstalls = action.payload.stalls;
      state.AMminDate = action.payload.minDate;
      state.AMmaxDate = action.payload.maxDate;
    },
    [fetchAMStalls.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      console.log(action.payload);
      state.error = action.payload;
    },

    [fetchRiderStalls.pending]: (state, action) => {
      state.fetchStatus = "loading";
    },
    [fetchRiderStalls.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.stalls = action.payload.stalls;
    },
    [fetchRiderStalls.rejected]: (state, action) => {
      state.fetchStatus = "failed";

      state.error = action.payload;
    },
    // [fetchStallsPage.pending]: (state, action) => {
    //   state.fetchStatus = "loading";
    // },
    // [fetchStallsPage.fulfilled]: (state, action) => {
    //   state.fetchStatus = "succeeded";
    //   state.stalls = action.payload.stalls;
    //   state.minDate = action.payload.minDate;
    //   state.maxDate = action.payload.maxDate;
    // },
    // [fetchStallsPage.rejected]: (state, action) => {
    //   console.log("action payload", action.payload);
    //   state.fetchStatus = "failed";
    //   console.log(action.payload);
    //   state.error = action.payload;
    // },
  },
});

export default stallsSlice.reducer;
