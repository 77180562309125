import React from "react";
import { Link } from "react-router-dom";
import { Card, Container } from "semantic-ui-react";
import CompetitorsTable from "./CompetitorsTable";
import "../../../App.css";

const LogInCard = ({ event }) => {
  return (
    <div>
      <Card fluid className="entry-card">
        <Container fluid className="profile-container">
          <Card.Content style={{ textAlign: "center" }}>
            <br />
            <br />
            <div className="peal-walkthough-image">
              <img
                className="pearl-icon-large"
                src="./illustratorPEARLB.png"
                alt=""
              />
            </div>
            <div className="entry-button-text-large">
              {" "}
              Log In or Create an Account to Enter{" "}
            </div>
            <p>
              Create your free Pearl's Office rider profile and register for
              events in minutes!
            </p>
            <br />
            <Link to="/login">
              <button className="event-signup-btn">Log In</button>
            </Link>

            <Link to="/create-account">
              <button className="event-signup-btn">Create Account</button>
            </Link>
            <br />
            <br />
            <br />
          </Card.Content>
        </Container>
      </Card>
      <CompetitorsTable event={event} />
    </div>
  );
};

export default LogInCard;
