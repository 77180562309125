import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, resetUsers } from "../features/users/userSlice";
import IdleTimer from "react-idle-timer";
import { Modal } from "semantic-ui-react";
import { Redirect, useHistory } from "react-router-dom";

function IdleTimerContainer() {
  const [isActive, setIsActive] = useState(true);
  const user = useSelector((state) => state.users.activeUser);
  const history = useHistory();
  const dispatch = useDispatch();
  const userLoggedIn = user && user.accessToken;
  const idleTimerRef = useRef(null);

  const onIdle = () => {
    if (window.location.href !== "/"){
      history.push("/");
    }
    if (userLoggedIn) {
      dispatch(logoutUser(user.accessToken))
        .then(() => dispatch(resetUsers()))  
    }
  };

  return (
    <div>
      <IdleTimer ref={idleTimerRef} timeout={600 * 1000} onIdle={onIdle} />
    </div>
  );
}

export default IdleTimerContainer;
