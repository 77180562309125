import React from "react";
import { Card, Container, Grid, Header } from "semantic-ui-react";
import { useSelector } from "react-redux";
import _ from "lodash";

const DressageTestEntries = () => {
  const event = useSelector((state) => state.events.event);
  const riders = event.EventRiders;
  const types = event.EventTypes;
  const paidRiders = event.EventRiders.filter((rider)=> rider.paymentStatus === "succeeded")

  let allTests = [];

  console.log("Dressage Tests...", paidRiders)

  types.forEach((type) => {
    if (type.isCompetitive === true && type.isDressage !== true) {
      type.height.forEach((el) => {
        allTests.push(el.test);
      });
    }
    if (type.name === "Dressage Tests") {
      type.tests.forEach((el) => {
        allTests.push(el);
      });
    }
  });

  let uniqueTests = _.uniq(allTests);



  return (
    <>
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h3
            style={{
              textAlign: "left",
              fontFamily: "Lato",
              fontSize: "1.25rem",
              fontWeight: "900px",
              marginLeft: ".2rem",
            }}
          >
            <strong>Dressage Test Totals</strong>
          </h3>
          <br />
          <Grid fluid columns={2} style={{ marginBottom: ".75rem" }}>
            {uniqueTests.map((el, idx) => {
              let count = 0;
              paidRiders && paidRiders.forEach((rider)=> rider.events.tests.includes(el) ? count++ : null)
              return (
            <>      
              <Grid.Column
                width={8}
                style={{ padding: ".2rem", marginBottom: "-1.5rem" }}
                key={idx}
              >
                <Card fluid>
                  <Card.Content>
                  <Header as="h5" floated="left" style={{ marginBottom: 2 }}>
                      {el.substr(0, 23)}
                    </Header>

                    <Header
                      as="h5"
                      floated="right"
                      style={{ marginBottom: 2, marginRight: 0 }}
                    >
                      {count}
                    </Header>
                  </Card.Content>
                </Card>
              </Grid.Column>
              </>)
          })}
          </Grid>
        </Container>
      </Card>
    </>
  );
};

export default DressageTestEntries;
