import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Header } from "semantic-ui-react";
import { Formik } from "formik";
import { updateEvent, fetchEvent } from "../../../features/events/eventsSlice";

const EditOfficialsForm = ({ event, handleClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const eventId = event.id;

  return (
    <section>
      <br />
      <Formik
        initialValues={{
          id: event.id,
          name: event.name,
          description: event.description,

          officials: [
            {
              title: "Dressage Judge",
              name: event.officials[0] ? event.officials[0].name : "",
            },
            {
              title: "Show Jumping Official",
              name: event.officials[1] ? event.officials[1].name : "",
            },
            {
              title: "Technical Delegate",
              name: event.officials[2] ? event.officials[2].name : "",
            },
          ],
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(updateEvent({ user, event, values })).then(() =>
            dispatch(fetchEvent(eventId))
          );
          setSubmitting(false);
          handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Dressage Official
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="officials[0].name"
              type="officials[0].name"
              onChange={handleChange}
              placeholder="Enter Name"
              id="officials[0].name"
              value={values.officials[0].name}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Show Jumping Official
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="officials[1].name"
              type="officials[1].name"
              onChange={handleChange}
              placeholder="Enter Name"
              id="officials[1].name"
              value={values.officials[1].name}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Technical Delegate
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="officials[2].name"
              type="officials[2].name"
              onChange={handleChange}
              placeholder="Enter Name"
              id="officials[2].name"
              value={values.officials[2].name}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <br />
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Save & Close
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EditOfficialsForm;
