import {
  configureStore,
  getDefaultMiddleware,
  combineReducers,
} from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import usersReducer from "../features/users/userSlice";
import horsesReducer from "../features/horses/horsesSlice";
import eventsReducer from "../features/events/eventsSlice";
import ridersReducer from "../features/eventRiders/eventRidersSlice";
import eventTypesReducer from "../features/eventTypes/eventTypesSlice";
import stallsReducer from "../features/stalls/stallsSlice";
import RVsReducer from "../features/RVs/RVsSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  users: usersReducer,
  horses: horsesReducer,
  events: eventsReducer,
  riders: ridersReducer,
  eventTypes: eventTypesReducer,
  stalls: stallsReducer,
  RVs: RVsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
