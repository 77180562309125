import React from "react";
import Overview from "../EventEntries/Overview";
const EventEntries = ({ eventId }) => {
  return (
    <>
      <div className="event-subview-title-area">
        <h2>Entry Overview</h2>
      </div>
      <Overview eventId={eventId} />
    </>
  );
};

export default EventEntries;
