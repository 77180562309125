import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Grid, Image, Header } from "semantic-ui-react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Redirect } from "react-router-dom";

import { Formik } from "formik";

import { updateEvent, fetchEvent } from "../../../features/events/eventsSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const EditEntryForm = ({ event, handleClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const eventId = event.id;
  return (
    <section>
      <br />
      <Formik
        initialValues={{
          name: event.name,
          id: event.id,
          registrationOpen: "",
          registrationClose: "",
          fees: [
            {
              name: "Office",
              price: event.fees ? event.fees[0].price : 0,
            },
            {
              name: "Tech",
              price: event.fees ? event.fees[1].price : 0,
            },
            {
              name: "Late",
              price: event.fees ? event.fees[2].price : 0,
            },
          ],
          description: event.description,
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log("values", values);
          dispatch(updateEvent({ user, event, values })).then(() =>
            dispatch(fetchEvent(eventId))
          );
          setSubmitting(false);
          handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Entry Open Date
            </Header>

            <DatePicker
              selected={values.registrationOpen}
              style={{ borderRadius: 10 }}
              dateFormat="MMMM d, yyyy"
              name="registrationOpen"
              onChange={(date) => setFieldValue("registrationOpen", date)}
            />
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Entry Closed Date
            </Header>
            <DatePicker
              selected={values.registrationClose}
              style={{ borderRadius: 10 }}
              dateFormat="MMMM d, yyyy"
              name="registrationClose"
              onChange={(date) => setFieldValue("registrationClose", date)}
            />

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Office Fee
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="fees[0].price"
              type="fees[0].price"
              onChange={handleChange}
              placeholder="$10"
              id="fees[0].price"
              value={values.fees[0].price}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Tech Fee
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="fees[1].price"
              type="fees[1].price"
              onChange={handleChange}
              placeholder="$10"
              id="fees[1].price"
              value={values.fees[1].price}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Late Fee
            </Header>
            <Form.Input
              style={{ height: 50, borderRadius: 10 }}
              name="fees[2].price"
              type="fees[2].price"
              onChange={handleChange}
              placeholder="$10"
              id="fees[2].price"
              value={values.fees[2].price}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.Input>

            <br />
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Save & Close
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EditEntryForm;
