import React from "react";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import ProfileCard2 from "../Components/UserProfileContent/ProfileCard2";
import MgrProfile from "../Components/UserProfileContent/MgrProfile";
import TrainerProfile from "../Components/UserProfileContent/TrainerProfile";

const Profile = () => {
  const user = useSelector((state) => state.users.activeUser);
  console.log("ROLE========>", user.role);
  return (
    <div className="main-content-cards">
      {user.role === "Event Manager" && <MgrProfile />}
      {user.role === "Rider/Participant" && <ProfileCard2 />}
      {user.role === "Trainer" && <TrainerProfile />}
    </div>
  );
};

export default Profile;
