import React from "react";
import { Grid, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";

import LogInForm from "../LogInForm/LogInForm";
import PearlDialogueLogin from "./PearlDialogueLogin";

const LogInCard = () => {
  return (
    <div>
      <br />
      <br />
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 375 }}>
          <img
            className="pearl-icon-large"
            src="./illustratorPEARLB.png"
            alt=""
          />

          <br />
          <PearlDialogueLogin />
          <br />

          <LogInForm />

          <Header as="h4">
            Forgot Password?
            <div>
              <Link to="./forgot-password">Click here</Link>
            </div>
          </Header>
          <Header as="h4">
            New to us?
            <div>
              <Link to="./create-account">Sign Up</Link>
            </div>
          </Header>
        </Grid.Column>
      </Grid>
      <br />
    </div>
  );
};

export default LogInCard;
