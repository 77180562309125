import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Dimmer, Loader, Icon } from "semantic-ui-react";
import {
  uploadEventImage,
  uploadEventDocument,
} from "../../features/events/eventsSlice";
import { fetchEvent } from "../../features/events/eventsSlice";
import EditModal from "./EventEditForms/EditModal";

const MgrLocation = ({ eventId }) => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const event = useSelector((state) => state.events.event);

  let card = "Location";

  const onFileChangeHandler = (evt, eventId, user, documentType) => {
    setLoading(true);
    const file = evt.target.files[0];
    dispatch(
      uploadEventDocument({ user, eventId, documentType, file, event })
    ).then(() => setLoading(false));
  };

  return (
    <div>
      {isLoading && (
        <Dimmer active page>
          <Loader size="massive">Loading</Loader>
        </Dimmer>
      )}
      <Card fluid className="card">
        <Container fluid className="profile-container">
          <h1
            style={{
              textAlign: "left",
              fontFamily: "Lato",
              fontSize: "1.90rem",
              fontWeight: "900px",
            }}
          >
            <strong>
              Location, Parking & Maps
              <EditModal fluid event={event} card={card} />
            </strong>
            {event.address === null || event.parkingDescription === null ? (
              <strong style={{ color: "red", marginLeft: "1rem" }}>
                *Required
              </strong>
            ) : (
              <Icon
                name="check circle"
                style={{ color: "green", marginLeft: "1rem" }}
              />
            )}
          </h1>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Address</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.address
                ? event.address
                : "*Please enter your address with Edit"}
            </p>
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Parking</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.parkingDescription
                ? event.parkingDescription
                : "*Please enter your parking description with Edit"}
            </p>
          </Card.Content>

          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Property Map Upload (PDF)</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.propertyMapUrl
                ? event.propertyMapUrl
                : "*Please upload your map"}
            </p>
            <label className="custom-file-upload">
              <input
                type="file"
                name="file"
                accept="image/png,image/jpeg,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                style={{ float: "left", marginBottom: 5, size: 20 }}
                onChange={(evt) =>
                  onFileChangeHandler(evt, eventId, user, "propertyMapUrl")
                }
              />
              Upload Property Map
            </label>
            <br />
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Cross Country Map Upload (PDF)</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.crossCountryMapUrl
                ? event.crossCountryMapUrl
                : "*Please upload your map"}
            </p>{" "}
            <label className="custom-file-upload">
              <input
                type="file"
                name="file"
                accept="image/png,image/jpeg,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                style={{ float: "left", marginBottom: 5, size: 20 }}
                onChange={(evt, eventId) =>
                  onFileChangeHandler(evt, eventId, user, "crossCountryMapUrl")
                }
              />
              Upload Cross Country Map
            </label>
            <br />
          </Card.Content>
          <h3 style={{ textAlign: "left", marginBottom: 2 }}>
            <strong>Show Jumping Map Upload (PDF)</strong>
          </h3>
          <Card.Content style={{ textAlign: "left" }}>
            <p>
              {event.showJumpingMapUrl
                ? event.showJumpingMapUrl
                : "*Please upload your map"}
            </p>
            <label className="custom-file-upload">
              <input
                type="file"
                name="file"
                accept="image/png,image/jpeg,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                style={{ float: "left", marginBottom: 5, size: 20 }}
                onChange={(evt) =>
                  onFileChangeHandler(evt, eventId, user, "showJumpingMapUrl")
                }
              />
              Upload Show Jumping Map
            </label>
            <br />
          </Card.Content>
        </Container>
      </Card>
    </div>
  );
};

export default MgrLocation;
