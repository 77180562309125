import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Grid, Image, Header, Icon } from "semantic-ui-react";
import { createEventType } from "../../../features/eventTypes/eventTypesSlice";
import MultiSelectBtn from "./MultiSelectBtn.js";
import { Formik } from "formik";

import { updateEvent, fetchEvent } from "../../../features/events/eventsSlice";
import { fetchTypes } from "../../../features/eventTypes/eventTypesSlice";

const EditManyTypesForm = ({ event, handleClose }) => {
  const [eventsNew, setEventsNew] = useState([]);

  // const [schooling, setSchooling] = useState([]);
  // const [competitive, setCompetitive] = useState([]);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);
  const eventId = event.id;
  const createStatus = useSelector((state) => state.eventTypes.createStatus);

  const handleHorseTrial = () => {
    return eventsNew &&
      eventsNew.some((el) => el.name === "Horse Trial") === true
      ? setEventsNew([...eventsNew.filter((el) => el.name !== "Horse Trial")])
      : setEventsNew([
          ...eventsNew,
          {
            name: "Horse Trial",
            isCompetitive: true,
            isDressage: false,
          },
        ]);
  };
  const handleCombinedTest = () => {
    return eventsNew &&
      eventsNew.some((el) => el.name === "Combined Test") === true
      ? setEventsNew([...eventsNew.filter((el) => el.name !== "Combined Test")])
      : setEventsNew([
          ...eventsNew,
          {
            name: "Combined Test",
            isCompetitive: true,
            isDressage: false,
          },
        ]);
  };
  const handleDerby = () => {
    return eventsNew && eventsNew.some((el) => el.name === "Derby") === true
      ? setEventsNew([...eventsNew.filter((el) => el.name !== "Derby")])
      : setEventsNew([
          ...eventsNew,
          {
            name: "Derby",
            isCompetitive: true,
            isDressage: false,
          },
        ]);
  };
  const handleDressageOnly = () => {
    return eventsNew &&
      eventsNew.some((el) => el.name === "Dressage Only") === true
      ? setEventsNew([...eventsNew.filter((el) => el.name !== "Dressage Only")])
      : setEventsNew([
          ...eventsNew,
          {
            name: "Dressage Only",
            isCompetitive: true,
            isDressage: true,
          },
        ]);
  };

  const handleCrossCountrySchooling = () => {
    return (
      eventsNew &&
      setEventsNew([
        ...eventsNew,
        {
          name: "XC Schooling",
          isCompetitive: false,
          isDressage: false,
        },
      ])
    );
  };
  const handleStadiumSchooling = () => {
    return (
      eventsNew &&
      setEventsNew([
        ...eventsNew,
        {
          name: "Stadium Schooling",
          isCompetitive: false,
          isDressage: false,
        },
      ])
    );
  };
  const handleHacking = () => {
    return (
      eventsNew &&
      setEventsNew([
        ...eventsNew,
        {
          name: "Hacking",
          isCompetitive: false,
          isDressage: false,
        },
      ])
    );
  };
  const handleDressageTest = () => {
    return (
      eventsNew &&
      setEventsNew([
        ...eventsNew,
        {
          name: "Dressage Tests",
          isCompetitive: false,
          isDressage: true,
        },
      ])
    );
  };

  return (
    <section>
      <br />
      <Formik
        initialValues={{
          name: "",
          eventId: event.id,
          isCompetitive: null,
          isDressage: null,
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log("values", values);
          console.log(eventsNew);

          // competitive
          //   .forEach((el) => {
          //     values.name = el.name;
          //     values.isCompetitive = el.isCompetitive;
          //     values.isDressage = el.isDressage;
          //     dispatch(createEventType({ user, values }));
          //     console.log(el.name);
          //   })
          // .then(() =>
          eventsNew.forEach((el) => {
            values.name = el.name;
            values.isCompetitive = el.isCompetitive;
            values.isDressage = el.isDressage;
            dispatch(createEventType({ user, values }))
              .then(() => dispatch(fetchEvent(eventId)))
              .then(() => dispatch(fetchTypes(eventId)));
            console.log(el.name);
          });
          setSubmitting(createStatus === "loading");
          handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Competitive Events
            </Header>

            <MultiSelectBtn
              className="option-field"
              type="button"
              onClick={(e) => handleHorseTrial({ values, user })}
            >
              <div>Horse Trial</div>
            </MultiSelectBtn>

            <MultiSelectBtn
              className="option-field"
              type="button"
              onClick={(e) => handleCombinedTest({ values, user })}
            >
              <div>Combined Test</div>
            </MultiSelectBtn>

            <MultiSelectBtn
              className="option-field"
              type="button"
              onClick={(e) => handleDerby({ values, user })}
            >
              <div>Derby</div>
            </MultiSelectBtn>

            <MultiSelectBtn
              className="option-field"
              type="button"
              onClick={(e) => handleDressageOnly({ values, user })}
            >
              <div>Dressage Only</div>
            </MultiSelectBtn>

            <br />

            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Schooling Events
            </Header>

            <MultiSelectBtn
              className="option-field"
              type="button"
              onClick={(e) => handleCrossCountrySchooling()}
            >
              <div>Cross Country Schooling</div>
            </MultiSelectBtn>

            <MultiSelectBtn
              className="option-field"
              type="button"
              onClick={(e) => handleDressageTest()}
            >
              <div>Dressage Tests</div>
            </MultiSelectBtn>

            <MultiSelectBtn
              className="option-field"
              type="button"
              onClick={(e) => handleStadiumSchooling()}
            >
              <div>Stadium Schooling</div>
            </MultiSelectBtn>

            <MultiSelectBtn
              className="option-field"
              type="button"
              onClick={(e) => handleHacking()}
            >
              <div>Hacking</div>
            </MultiSelectBtn>

            <br />
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Save & Close
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EditManyTypesForm;
