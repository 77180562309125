import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Header } from "semantic-ui-react";
import { Formik } from "formik";
import { updateEvent } from "../../../features/events/eventsSlice";

const EditCancellationForm = ({ event, handleClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.activeUser);

  return (
    <section>
      <br />
      <Formik
        initialValues={{
          id: event.id,
          name: event.name,
          description: event.description,
          riderCancellationPolicy:
            event.riderCancellationPolicy !== null
              ? event.riderCancellationPolicy
              : "",
          eventCancellationPolicy: event.eventCancellationPolicy
            ? event.eventCancellationPolicy
            : "",
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(updateEvent({ user, event, values }));
          setSubmitting(false);
          handleClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Rider Cancellation Policy
            </Header>
            <Form.TextArea
              style={{ height: "10rem", borderRadius: 10 }}
              placeholder="Explain your rider cancellation policy here..."
              type="riderCancellationPolicy"
              name="riderCancellationPolicy"
              onChange={handleChange}
              id="riderCancellationPolicy"
              value={values.riderCancellationPolicy}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.TextArea>
            <Header as="h4" floated="left" style={{ marginBottom: 2 }}>
              Event Cancellation Policy
            </Header>
            <Form.TextArea
              style={{ height: "10rem", borderRadius: 10 }}
              placeholder="Explain your policy if the entire event is cancelled here..."
              type="eventCancellationPolicy"
              name="eventCancellationPolicy"
              onChange={handleChange}
              id="eventCancellationPolicy"
              value={values.eventCancellationPolicy}
            >
              <input style={{ borderRadius: 10 }} />
            </Form.TextArea>

            <br />
            <Button
              style={{
                backgroundColor: "#ffdea2",
                height: 50,

                borderRadius: 10,
              }}
              fluid
              type="submit"
            >
              Save & Close
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EditCancellationForm;
